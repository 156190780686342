(function ($) {
  "use strict";

  $.jdm.extend({
    export2pdf: function (fileName, callback) {
      return this.each(function () {
        if (this.grid) {
          const $printCont = $("#gboxcont_" + this.p.id + ' > .dm-document');
          makeHTML2PDF($printCont[0], fileName, callback);
        }
      });
    }
  });
})(jQuery);

/**------------------------------------------------------------------------------------
 * Making HTML for PDF converter
 **-----------------------------------------------------------------------------------*/
var makeHTML2PDF = async function (content, fname, callback) {
  const pdf = new jsPDF('p', 'pt', 'a4');
  pdf.setFont('helvetica');
  pdf.setFontSize(8);
  let pdfPage = 1;

  const prepareContent = function (printCont) {
    // fix svg
    const svgElements = $('svg');
    svgElements.each((ind, item) => {
      item.setAttribute('width', item.getBoundingClientRect().width);
      item.setAttribute('height', item.getBoundingClientRect().height);
      item.style.width = null;
      item.style.height = null;
    });

    if (!pz.mode && !pz.iddoc) {
      // remove "disabled" attribute only when template preview
      $(".dm-field", printCont).each(function () {
        const $inp = $(this), id = $inp.attr("p");
        if (id !== undefined) {
          let tagNm = $inp.get(0).tagName;
          tagNm = tagNm.toLowerCase();
          if (tagNm === "input" || tagNm === "textarea" || tagNm === "select") {
            $inp.removeAttr("disabled");
          } else if (tagNm === "div" && $inp.hasClass("customelement")) {
            if ($("input", $inp).length > 0) {
              $("input", $inp).removeAttr("disabled");
            }
          }
        }
      });
      $("input[type='checkbox']", printCont).each(function () {
        $(this).removeAttr("disabled");
      });
    }

    const isMobileDevice = $.jdm.isMobileDevice();
    if (isMobileDevice) {
      $('div.docelement-grid[class*="grid-cmp-"]', printCont).each((_, parEl) => {
        const newEl = $('<div></div>');
        const header = $('<div class="row"><div class="col-4"></div></div>');
        $('div.docelement-grid-row:first > div.field-group > div.field-label', parEl).each((_, el) => {
          header.append('<div class="col">' + $(el).html() + '</div>');
        });
        newEl.append(header);
        $('div.docelement-grid-row', parEl).each((_, rowEl) => {
          const row = $('<div class="row"></div>');
          row.append('<div class="col-4">' + $('div.grid-caption-header-mob.field-label', rowEl).html() + '</div>')
          $('div.grid-value', rowEl).each((_, el) => {
            row.append('<div class="col">' + $(el).html() + '</div>');
          });
          newEl.append(row);
        });
        $(parEl).replaceWith(newEl);
      });
    }
    // inputs
    $('.dm-field', printCont).each(function () {
      const $inp = $(this), id = $inp.attr('id');
      if (id !== undefined) {
        const tagNm = $inp.get(0).tagName.toLowerCase();
        if (tagNm === 'input' || tagNm === 'textarea' || tagNm === 'select') {
          $inp.removeAttr('placeholder')
            .attr('disabled', 'disabled');
          $inp.val($('#' + id, content).val());
        } else if (tagNm === 'div' && $inp.hasClass('customelement')) {
          if ($('input.inp-dct', $inp).length > 0) {
            const elem = doc.createElement('input');
            $(elem).addClass('dm-field form-control form-control-sm');
            $(elem).val($('input', $('#' + id, content)).val());
            $(elem).attr('disabled', 'disabled');

            $inp.html('').append($(elem));
          }
        }
      }
    });
    $('input[type="checkbox"]', printCont).each(function () {
      var $inp = $(this), id = $inp.attr('id');
      if (id !== undefined) {
        $inp.removeAttr('placeholder');
      }
    });
    $('table.document-group thead th:first', printCont).remove();
    $('table.document-group .row-template-actions', printCont).remove();

    $('div.dm-wizard-tab', printCont).remove();
    $('div.dm-wizard-table', printCont).remove();
    $('div.sw-toolbar', printCont).remove();

    const offsetTop = printCont[0].getBoundingClientRect().top;
    // page-breaks
    $('.field-group-page-break', printCont).each((ind, el) => {
      const clientRect = el.getBoundingClientRect();
      $('<div class="page-break"/>')
        .height(pageHeight - ((clientRect.bottom - offsetTop) % pageHeight) + 40)
        .width(pageWidth).insertAfter(el);
    });
    $('img', printCont).each((ind, el) => {
      if ($(el).width() > pageWidth) {
        $(el).width(pageWidth);
      }
    });
  };

  const printCanvas = function (canvas) {
    const contHeight = $(canvas).outerHeight();
    const npage = Math.ceil(contHeight / pageHeight);
    window.onePageCanvas = document.createElement('canvas');
    onePageCanvas.setAttribute('width', pageWidth + 'px');
    onePageCanvas.setAttribute('height', pageHeight + 'px');
    const ctx = onePageCanvas.getContext('2d');
    for (let i = 0; i < npage; i++) {
      if (i !== 0) {
        pdf.addPage();
        pdfPage++;
        pdf.setPage(pdfPage);
      }
      const srcImg = canvas, sX = 0, sY = pageHeight * i,
        imgHeight = (sY + pageHeight) > contHeight ? (contHeight - sY) : pageHeight;
      ctx.clearRect(0, 0, pageWidth, pageHeight);
      ctx.drawImage(srcImg, sX, sY, pageWidth, imgHeight, 0, 0, pageWidth, imgHeight);
      const canvasDataURL = onePageCanvas.toDataURL('image/png', 1);
      pdf.addImage(canvasDataURL, 'PNG', 20, 20, pageWidth * 0.58 - 40, pageHeight * 0.58 - 40, '', 'FAST');
      // header
      pdf.text('Page ' + pdfPage, pageWidth * 0.58 / 2, 15, {
        align: 'center'
      });
      // footer
      pdf.text('Page ' + pdfPage, pageWidth * 0.58 / 2, pageHeight * 0.58 - 10, {
        align: 'center'
      });
    }
  }

  const pageWidth = 1024;
  const pageHeight = pageWidth * 1.414;
  pz.charts.forEach(v => v.resize(pageWidth, pageWidth / 2));

  // check is survey
  if (pz?.sprDoc?.mode === 1 || pz?.sprDoc?.mode === 2) {
    const wizard = $('.dm-wizard', content);
    if (wizard.length > 0) {
      const hiddenSteps = [];
      $('.dm-wizard-tab > li.nav-item', content).each((ind, el) => {
        if ($(el).hasClass('hidden')) {
          hiddenSteps.push(ind);
        }
      });
      const wizardTabs = $('.step-content', wizard);
      for (let tabInd = 0; tabInd < wizardTabs.length; tabInd++) {
        if (hiddenSteps.indexOf(tabInd) >= 0) {
          continue;
        }
        const tab = wizardTabs.get(tabInd);

        if (tabInd !== 0) {
          pdf.addPage();
          pdfPage++;
          pdf.setPage(pdfPage);
        }
        try {
          const canvas = await html2canvas(tab, {
            windowWidth: 1920,
            width: 1920,
            logging: false,
            scale: 1,
            proxy: '/api/doc/public/image',
            onclone: function (doc) {
              $('.dm-document', doc).addClass('dm-document-preview');
              const idCont = $(content).attr('id'),
                printCont = $('#gboxcont_' + idCont, doc);

              $('.step-content', doc).each((ind, el) => {
                if (ind !== tabInd) {
                  $(el).remove();
                } else {
                  $(el).show();
                }
              });

              prepareContent(printCont);
            }
          });
          printCanvas(canvas);
        } catch (error) {
          console.error(error);
          pz.charts.forEach(v => v.resize());
          if ($.isFunction(callback)) callback(false);
          return;
        }
      }
    }
  } else {
    try {
      const canvas = await html2canvas(content, {
        windowWidth: 1280,
        width: 1280,
        logging: false,
        scale: 1,
        proxy: '/api/doc/public/image',
        onclone: function (doc) {
          $('.dm-document', doc).addClass('dm-document-preview');
          const idCont = $(content).attr('id'),
            printCont = $('#gboxcont_' + idCont, doc);
          prepareContent(printCont);
        }
      });
      printCanvas(canvas);
    } catch (error) {
      console.error(error);
      pz.charts.forEach(v => v.resize());
      if ($.isFunction(callback)) callback(false);
      return;
    }
  }
  pz.charts.forEach(v => v.resize());
  pdf.save(fname);
  if ($.isFunction(callback)) callback(true);
};

var setDefaultVars = function ($c, pz, isMobileDevice) {
  var inputs = pz.inputs || [], // List of inputs
    rules = pz.rules || [], // List of rules
    colModel = [], // Column Model
    dynaFields = [],
    wizardInit = false, // Wizard Init
    wizardTabCnt = 0, // Tabs count
    tableCnt = 0, // Table's count
    currentTableNum = 0, // Current table number
    //  Var for the grid of components
    gridVar = null,
    panelVar = null,
    // These vars for every input
    _dataType,
    _editable,
    _htmlList,
    _edittype,
    _formatter,
    _decimalPlaces,
    _decimalSeparator,
    _visible,
    _tabNum,
    _hint = null,
    _inputMask = null,
    _isGenerate, _groupFld, _isGroup, _isSummary, _isKoef,
    _userOpts,
    _edrules = {}, _edopts = {}, _formatopts = {},
    _cssLinePlace,
    _isSurvey = pz.sprDoc && pz.sprDoc.project && pz.sprDoc.project.grType && pz.sprDoc.project.grType == 2;
  const DYN_TAB_FORM_ATTRIBUTES = ['primary', 'secondary', 'tab'];
  const INPUT_MASK_ATTRIBUTES = ['pattern', 'placeHolder'];
  const CHART_TYPES = ['bar', 'line', 'radar'];
  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };
  (inputs || []).filter(v => v.tabn !== 0).forEach(inp => {
    if (!inp.dynTabForm || typeof inp.dynTabForm !== 'string') {
      inp.dynTabForm = {};
    } else {
      const params = inp.dynTabForm.split(';');
      let dynTabForm = {};
      params.forEach(p => {
        const arr = p.split(':');
        if (arr.length === 2 && DYN_TAB_FORM_ATTRIBUTES.indexOf(arr[0]) >= 0) {
          const val = parseInt(arr[1], 10);
          if (isNaN(val)) {
            return;
          }
          dynTabForm[arr[0]] = val;
        }
      });
      inp.dynTabForm = dynTabForm;
    }
  });
  var _initVars = function () {
      $(inputs).each(function () {
        var inp = this;
        if (pz.sprDoc.mode !== 0) {
          if (inp.grpType > wizardTabCnt && (inp.tabn || 0) === 0) {
            wizardTabCnt = inp.grpType;
          }
        }
        if (inp.tabNum > tableCnt) {
          tableCnt = inp.tabNum;
        }
      });
    },
    _addWizard = function () {
      if (!wizardInit) {
        wizardInit = true;
        $c.append(
          "<div class='col-12 btn-toolbar sw-toolbar sw-toolbar-top justify-content-end'>" +
          "<div class=\"btn-group mr-2 sw-bnn-group\" role=\"group\">" +
          "<button class=\"p-button p-component p-button-rounded  btn-prev p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fa fa-chevron-left\"></span>" +
          "<span class='p-button-label'>Go Previous</span>" +
          "</button>" +
          "<button class=\"p-button p-component p-button-rounded  btn-next p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fa fa-chevron-right\"></span>" +
          "<span class='p-button-label'>Go Next</span>" +
          "</button>" +
          "<button class=\"p-button p-component p-button-rounded  btn-finish p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fas fa-share-square\"></span>" +
          "<span class='p-button-label'>Submit</span>" +
          "</button>" +
          "<button class=\"p-button p-component p-button-rounded  btn-save p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fas fa-save\"></span>" +
          "<span class='p-button-label'>Save</span>" +
          "</button>" +
          "</div>" +
          "</div>" +
          "<div class='col-12 dm-wizard'>" +
          "<ul class='dm-wizard-tab'></ul>" +
          "<div class='dm-wizard-body'></div>" +
          "</div>" +
          "<div class='col-12 btn-toolbar sw-toolbar sw-toolbar-top justify-content-end'>" +
          "<div class=\"btn-group mr-2 sw-bnn-group\" role=\"group\">" +
          "<button class=\"p-button p-component p-button-rounded  btn-prev p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fa fa-chevron-left\"></span>" +
          "<span class='p-button-label'>Go Previous</span>" +
          "</button>" +
          "<button class=\"p-button p-component p-button-rounded btn-next p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fa fa-chevron-right\"></span>" +
          "<span class='p-button-label'>Go Next</span" +
          "</button>" +
          "<button class=\"p-button p-component p-button-rounded  btn-finish p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fas fa-share-square\"></span>" +
          "<span class='p-button-label'>Submit</span>" +
          "</button>" +
          "<button class=\"p-button p-component p-button-rounded  btn-save p-button-raised\" type=\"button\">" +
          "<span aria-hidden=\"true\" class=\"p-button-icon-left fas fa-save\"></span>" +
          "<span class='p-button-label'>Save</span>" +
          "</button>" +
          "</div>" +
          "</div>");
        for (var i = 1; i <= wizardTabCnt; i++) {
          var _grpName = "", _grpTabNm = "Step #" + i, _groups = pz.groups, _rdata;
          let hidden = false;
          if (!pz.inputs?.find(v => v.grpType === i)) {
            hidden = true;
          }
          for (var k in _groups) {
            _rdata = _groups[k];
            if (_rdata.tabn !== 0) {
              continue;
            }
            if (!_rdata) {
              continue;
            }
            if (_rdata.grpType == i) {
              if (_rdata.grpName != "*") {
                _grpName = _rdata.grpName;
              }
              if (_rdata.grpShortname && _rdata.grpShortname.length > 0) {
                _grpTabNm = _rdata.grpShortname;
              }
              break;
            }
          }
          _addWizardTab("step" + i, _grpTabNm, _grpName, hidden);
        }
        // Init SmartWizard
        $(".dm-wizard", $c).smartWizard({
          selected: 0,
          transitionEffect: 'fade',
          transitionSpeed: '400',
          keyNavigation: false,
          contentCache: false,
          showStepURLhash: false,
          toolbarSettings: {
            toolbarPosition: 'none'
          },
          anchorSettings: {
            enableAllAnchors: pz.sprDoc.wizardType === 1
          }
        });

        if (pz.mode === false) $(".nav-item", $(".dm-wizard", $c)).addClass("done");
        $(".btn-finish", $c).hide();
        $(".btn-save", $c).hide();
        if (pz.mode && (pz?.access?.accessWrite === undefined || pz?.access?.accessWrite)) {
          $(".btn-inline-save", $c).show();
        } else {
          $(".btn-inline-save", $c).hide();
        }
        if (pz.mode && (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit)) {
          $(".btn-inline-submit", $c).show();
        } else {
          $(".btn-inline-submit", $c).hide();
        }
        if (wizardTabCnt === 1 || $(".nav-item:not(.hidden)", $(".dm-wizard", $c)).length === 1) {
          $(".btn-prev", $c).hide();
          $(".btn-next", $c).hide();
          if (pz.mode && (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit === true)) {
            $(".btn-finish", $c).show();
          } else if (pz.mode && (pz?.access?.accessWrite === undefined || pz?.access?.accessWrite)) {
            $(".btn-save", $c).show();
          }
        } else {
          $(".btn-prev", $c).hide();
          $(".btn-finish", $c).hide();
          $(".btn-save", $c).hide();
        }
        // Step show event
        $(".dm-wizard", $c).on("showStep", function (e, anchorObject, stepNumber, stepDirection, stepPosition) {
          var getNextVisibleTabs = function (stepNumber) {
            var cnt = 0;
            const navItems = $(".nav-item", $(".dm-wizard", $c));
            for (let i = 0; i < navItems.length; i++) {
              if (!$(navItems[i]).hasClass("hidden") && stepNumber < i) {
                cnt++;
              }
            }
            return cnt;
          };
          if ($('app-dialog-doceditor').length > 0 || $('app-dialog-template-preview').length > 0) {
            $('.dialog-content').scrollTop(0);
          } else {
            $(document).scrollTop(0);
          }
          if (pz.mode && (pz?.access?.accessWrite === undefined || pz?.access?.accessWrite)) {
            $(".btn-inline-save", $c).show();
          } else {
            $(".btn-inline-save", $c).hide();
          }
          if (pz.mode && (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit)) {
            $(".btn-inline-submit", $c).show();
          } else {
            $(".btn-inline-submit", $c).hide();
          }
          var $inst = pz.$inst, editable = $inst.jqDm("getDocStatus");
          if (stepPosition === 'first') {
            const cnt = getNextVisibleTabs(stepNumber);
            if (cnt === 0) {
              $(".btn-next", $c).hide();
              if (editable && (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit === true)) {
                $(".btn-finish", $c).show();
              } else if (editable && pz?.access?.accessWrite) {
                $(".btn-save", $c).show();
              }
            } else {
              $(".btn-prev", $c).hide();
              $(".btn-next", $c).show();
              $(".btn-finish", $c).hide();
              $(".btn-save", $c).hide();
            }
          } else if (stepPosition === 'final') {
            if (wizardTabCnt > 1) {
              $(".btn-prev", $c).show();
            }
            $(".btn-next", $c).hide();
            if (editable && (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit === true)) {
              $(".btn-finish", $c).show();
            } else if (editable && pz?.access?.accessWrite) {
              $(".btn-save", $c).show();
            }
          } else {
            var _cnt = getNextVisibleTabs(stepNumber);
            $(".btn-prev", $c).show();
            if (_cnt === 0) {
              $(".btn-next", $c).hide();
              if (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit === true) {
                $(".btn-finish", $c).show();
              } else if (pz?.access?.accessWrite) {
                $(".btn-save", $c).show();
              }
            } else {
              $(".btn-next", $c).show();
              $(".btn-finish", $c).hide();
              $(".btn-save", $c).hide();
            }
          }
          if (editable) {
            $.jdm.save();
            let steps = [];
            $(".dm-wizard li.nav-item.danger a.nav-link, .dm-wizard li.nav-item.done a.nav-link")
              .each(function () {
                const v = this;
                const href = v.href || '';
                steps.push(+href.substring(href.indexOf('#step') + 5));
              });
            $inst[0].p.tabsViewed = steps;
            if (steps.length) {
              // ig added to clear check errors
              $inst.jqDm("resetCellsErr");
              const res = $inst.jqDm("check");
              _callbackParseLog($inst, res.rows);
              const errorsSteps = Array.from(new Set(res.rows.filter(v => v?.grpType > 0 && steps.indexOf(v.grpType) >= 0)
                .map(v => v.grpType - 1)));
              $inst.jqDm("wizardHighlight", errorsSteps);
            }
          }
        });
        /**<< External Button Events **/
        // Navigate previous
        $(".btn-prev", $c).on("click", function () {
          $(".dm-wizard", $c).smartWizard("prev");
          return true;
        });
        // Navigate next
        $(".btn-next", $c).on("click", function () {
          var $inst = pz.$inst, cellEdit = $inst.jqDm("getDocStatus");
          if (cellEdit) {
            ($inst).jqDm("commit");
            var _navItemActive = $(".nav-item.active", $(".dm-wizard", $c)),
              _itemActive = $("a.nav-link", _navItemActive),
              _numStep = _itemActive.attr("href").substr(5);
            // add current element for check
            if (!($inst[0].p.tabsViewed.some(v => v === (+_numStep)))) {
              $inst[0].p.tabsViewed.push(+_numStep)
            }
            if (_itemActive.hasClass("done")) {
              $(".dm-wizard", $c).smartWizard("next");
            }
            var res = ($inst.jqDm("check", _numStep));
            if (res.valid || pz.sprDoc.wizardType === 1) {
              // go to next step
              $(".dm-wizard", $c).smartWizard("next");
            } else {
              // parse log
              _callbackParseLog($inst, res.rows);
              $.jdm.info_dialog("Warning", "This step contains an error. You must correct your input before continuing.", "alert");
            }
          } else {
            $(".dm-wizard", $c).smartWizard("next");
          }
          return true;
        });
        // Submit
        $(".btn-finish", $c).on("click", function () {
          var $inst = pz.$inst, cellEdit = $inst.jqDm("getDocStatus");
          if (cellEdit) {
            $.jdm.submit();
          } else {
            $.jdm.info_dialog("Warning", "This document has already submitted.", "alert");
          }
          return true;
        });
      }
    },
    _callbackParseLog = function ($inst, logs) {
      if (logs.length > 0) {
        const errFieldNames = logs.map(v => v.field);
        const tableCols = $inst[0].p.colModel
          .filter(v => v.tabn !== 0 && !v.rownum && !v.hidden && v?.useropts?.dynTabForm?.secondary !== 0 &&
            errFieldNames.indexOf(v.index) >= 0);
        $inst.jqDm("resetTableEditStatus");
        logs.filter(v => tableCols.find(t => t.index === v.field))
          .map(v => ({rn: v.pos, tabn: tableCols.find(t => t.index === v.field).tabn}))
          .forEach(v => $inst.jqDm("setTableEditStatus", v.tabn, v.rn, "error"));
        var r, rnum, tp, type, chk;
        for (var i in logs) {
          r = logs[i];
          rnum = r.pos;
          tp = r.level;
          type = "";
          switch (tp) {
            case 1:
              type = "Integrity check";
              break;
            case 2:
              type = "Inspection check";
              break;
            case 3:
              type = "XSD check";
              break;
          }
          //ig added error type to params
          chk = pz.$inst.jqDm("setCellStatus", rnum, r.field, "error", "<b>" + type + "</b>: " + r.msg, r?.errorType, r?.grpType);
          if (chk === -2) {
            const cm = $inst[0].p.colModel;
            cm.forEach((fld) => {
              var _parent = fld.useropts && fld.useropts.parent ? fld.useropts.parent : null;
              if (_parent) {
                if (_parent.name === r.field) {
                  chk = 0;
                  //ig added error type to params
                  pz.$inst.jqDm("setCellStatus", rnum, fld.index, "error", "<b>" + type + "</b>: " + r.msg, r?.errorType, r?.grpType);
                  return true;
                }
              }
            });
          }
        }
      }
    },
    _addWizardTab = function (id, tabNm, groupNm, hidden) {
      $(".dm-wizard-tab", $c).append("<li " + (hidden ? "class='hidden'" : "") + "><a href='#" + id + "'>" + tabNm + "</a></li>");
      $(".dm-wizard-body", $c).append("<div id='" + id + "' style='margin:2px;'>" +
        "<div class='row dm-wizard-table' style='margin:5px;'></div>" +
        "</div>");
    },
    _getTablePainter = function (inp) {
      if (wizardTabCnt > 0) {
        _addWizard();
        return $("#step" + inp.grpType, $c);
      }
      return $c;
    },
    _getPaintPoint = function (inp) {
      var tabn = inp.tabNum || 0;
      if (tabn === 0) {
        if (wizardTabCnt > 0) {
          _addWizard();
          return $("#step" + inp.grpType, $c);
        }
      } else {
        return $("div.fc[p=" + inp.field + "]", $c);
      }
      return $c;
    },
    _isNumberType = function (dt) {
      return (dt === 'NUMBER' || dt === 'INTEGER');
    },
    _genDynamicGroupAfterSaveFunc = function (nm, grpNm) {
      var ret = " if(name === '" + nm + "') { ";
      ret += " var res = $(this).getCellValue(env.nrow, '" + grpNm + "'); if (res != '') { res = JSON.parse(res); } else { res = {}; }";
      ret += " res['" + nm + "'] = val;";
      ret += " $(this).setCellValue(env.nrow, '" + grpNm + "', JSON.stringify(res));";
      ret += " env.autoFillParams(App, '" + nm + "'); }";
      return ret;
    },
    _rangeAfterSaveFunc = function (nm) {
      const ret = " if(name === '" + nm + "') { "
        + " const val = $(this).getCellValue(env.nrow, '" + nm + "'); if (val !== '') { "
        + " document.getElementById('" + nm + "').nextElementSibling.value = val;"
        + "}}";
      return ret;
    },
    _doElementOutput = function (inp, ind) {
      function _isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
          if (c[i].nodeType == 1) return true;
        }

        return false;
      }

      var $ptr = _getPaintPoint(inp, ind),
        message = (typeof (inp.label) !== 'undefined' ? (inp.label.length !== "" ? inp.label : nodeName) : nodeName),
        _cssPanel = "",
        _style = _visible === false ? " style='display: none;'" : "";
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          _cssPanel = " field-panel panel-" + panelVar.id;
          panelVar.cnt++;
        }
        if (panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }
      if (_isHTML(message)) {
        $ptr.append("<div class='col-12 form-group field-label docelement-output" + _cssPanel + _cssLinePlace + "' field='" + inp.field + "'" + _style + ">" + message + "</div>");
      } else {
        let color = null;
        if (inp.htmlList) {
          const arr = inp.htmlList.split(',');
          arr.forEach(v => {
            const attrs = v.split(':');
            if (attrs.length === 2 && attrs[0] && attrs[1] && (attrs[0] + '').toLowerCase() === 'color') {
              color = attrs[1];
            }
          });
        }
        $ptr.append("<div class='form-row form-group field-label" + _cssPanel + "' field='" + inp.field + "'" + _style + ">" +
          "<span class='docelement-output' " + (color ? "style='color:" + color + "'" : '') + ">" + message + "</span>" +
          "</div>");
      }
    },
    _doElementNote = function (inp, ind) {
      var $ptr = _getPaintPoint(inp, ind), _cssPanel = "", _style = _visible === false ? " style='display: none;'" : "";
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          _cssPanel = " field-panel panel-" + panelVar.id;
          panelVar.cnt++;
        }
        if (panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }
      $ptr.append("<div class='form-row form-group field-label" + _cssPanel + _cssLinePlace + "' field='" + inp.field + "'" + _style + ">" +
        "<span class='docelement-note'>" + (typeof (inp.label) !== 'undefined' ? (inp.label.length !== "" ? inp.label : nodeName) : nodeName) + "</span>" +
        "</div>");
    },
    _doElementSubtitle = function (inp, ind) {
      var $ptr = _getPaintPoint(inp, ind), _cssPanel = "", _style = _visible === false ? " style='display: none;'" : "";
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          _cssPanel = " field-panel panel-" + panelVar.id;
          panelVar.cnt++;
        }
        if (panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }
      let backgroundColor = null;
      let color = null;
      if (inp.htmlList) {
        const arr = inp.htmlList.split(';');
        arr.forEach(v => {
          const attrs = v.split(':');
          if (attrs.length === 2 && attrs[0] && attrs[1]) {
            const name = attrs[0].trim().toLowerCase();
            const val = attrs[1].trim().toLowerCase();
            if (name === 'background-color') {
              backgroundColor = val;
            } else if (name === 'color') {
              color = val;
            }
          }
        });
      }
      $ptr.append(
        "<div class='form-row form-group field-label" + _cssPanel + _cssLinePlace + "' field='" + inp.field + "'" + _style + ">" +
        "<div class='freebirdMaterialHeaderbannerLabelContainer'>" +
        "<div class='freebirdMaterialHeaderbannerLabelTextContainer freebirdSolidBackground' "
        + (color || backgroundColor ? "style='" + (backgroundColor ? "background-color:" + backgroundColor + ';' : '') + (color ? "color:" + color : '') + "'" : '') + ">" +
        "<div class='freebirdMaterialHeaderbannerSectionText'>" + (typeof (inp.label) !== 'undefined' ? (inp.label.length !== "" ? inp.label : nodeName) : nodeName) + "</div>" +
        "</div>" +
        "<div class='freebirdMaterialHeaderbannerSectionTriangleContainer'>" +
        "<svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 10 10' preserveAspectRatio='none' class='freebirdMaterialHeaderbannerSectionTriangle'>" +
        "<polygon class='freebirdSolidFill' points='0,0 10,0 0,10'" + (backgroundColor ? "style='fill:" + backgroundColor + ";stroke:" + backgroundColor + "'" : '') + "></polygon>" +
        "</svg>" +
        "</div>" +
        "</div>" +
        "</div>");
    },
    _doElementHelpWindow = function (inp, ind) {
      const $ptr = _getPaintPoint(inp, ind);
      let _cssPanel = "";
      let _style = _visible === false ? " style='display: none;'" : "";
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          _cssPanel = " field-panel panel-" + panelVar.id;
          panelVar.cnt++;
        }
        if (panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }
      $ptr.append("<div class='form-row form-group field-label" + _cssPanel + _cssLinePlace + "' field='" + inp.field + "'" + _style + ">" +
        "<a href='#' class='document-help-link'>" + (typeof (inp.label) !== 'undefined' ? (inp.label.length !== "" ? inp.label : '') : '') + "</a>" +
        (inp.hint ? "<i class='jdm-fldhint far fa-question-circle' onmouseover=\"$.jdm.buildPromptHint(this, '" + inp.hint + "')\")'></i>" : "") +
        "</div>");
      $('div[field="' + inp.field + '"] > .document-help-link', $ptr).on('click', (event) => {
        event.preventDefault();
        ShowHelpWindow(inp.field);
      });
    },
    _doElementChart = function (inp, ind) {
      var $ptr = _getPaintPoint(inp, ind), _cssPanel = "", _style = _visible === false ? " style='display: none;'" : "";
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          _cssPanel = " field-panel panel-" + panelVar.id;
          panelVar.cnt++;
        }
        if (panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }
      $ptr.append("<div class='form-row form-group field-label field-chart" + _cssPanel + _cssLinePlace + "' field='" + inp.field + "'" + _style + ">" +
        "<span class='docelement-note'>" + (typeof (inp.label) !== 'undefined' ? (inp.label.length !== "" ? inp.label : nodeName) : nodeName) + "</span>" +
        "<div class='col-12 field-value position-relative'>" +
        "<div class='fc' p='" + inp.field + "' style='width:99%!important;'></div>" +
        "</div>" +
        "</div>");
    },
    _doElementDynamicSummary = function (inp, ind) {
    },
    _doElementDynamicChoice = function (inp, ind) {
      _htmlList = "";
      for (var i = 0; i < inp.__values.length; i++) {
        _htmlList += (_htmlList != "" ? ";" : "") + inp.__values[i];
      }
      _htmlList += "::4";
      _doElementGroup(inp, ind);
    },
    _doElementDynamicGroupKeys = function (inp, ind) {
      var $ptr = _getPaintPoint(inp, ind), cnt = 0;

      var _getObjectsLvl = function (lvl, objects) {
          for (var k in objects) {
            var obj = objects[k];
            if (obj.lvl > lvl) lvl = obj.lvl;
            if (obj.children) {
              lvl = _getObjectsLvl(lvl, obj.children);
            }
          }
          return lvl;
        },
        _getObjectsLength = function (ln, objects) {
          ln += objects.length;
          for (var k in objects) {
            var obj = objects[k];
            if (obj.children) {
              ln = _getObjectsLength(ln, obj.children);
            }
          }
          return ln;
        },
        _getTableRows = function ($tbl, lvl, objLvl, objects, valuesArr) {
          if (lvl === 0) {
            $tbl += "<tr class='docelement-grid-row form-group field-group docelement-grid-row-summary'>";
            $tbl += "<td class='grid-value'>" +
              "<div class='field-grid-value" + (++cnt) + "' style='float:none !important;display: inline-block;'>&nbsp;</div>" +
              "</td>" +
              "<td></td>";
            $tbl += "<td class='field-label'" + (objLvl > 1 ? (" colspan='" + objLvl + "'") : "") + ">&nbsp;</td>";
            $tbl += "</tr>";
          }
          for (var k in objects) {
            var obj = objects[k];

            $tbl += "<tr class='docelement-grid-row form-group field-group docelement-grid-row-level-" + lvl + (obj.children ? " docelement-grid-row-summary" : "") + "'>";
            for (var j = 0; j < valuesArr.length - 1; j++) {
              $tbl += "<td class='grid-value'>" +
                "<div class='field-grid-value" + (++cnt) + "' style='float:none!important; display:inline-block;'>&nbsp;</div>" +
                "</td>";
            }
            if (lvl === 0) {
              $tbl += "<td class='grid-value'>" +
                "<div class='field-grid-value" + (++cnt) + "' style='float:none!important; display:inline-block;'>&nbsp;</div>" +
                "</td>";
            } else {
              for (var j = 0; j < objLvl; j++) {
                $tbl += "<td class='grid-value'>" +
                  (j === lvl ?
                    "<div class='field-grid-value" + (++cnt) + "' style='float:none!important; display:inline-block;'>&nbsp;</div>" : "") +
                  "</td>";
              }
            }
            $tbl += "<td class='field-label'" + /*_labelStyle +*/ (objLvl > 1 ? (" colspan='" + objLvl + "'") : "") + ">" + obj.name + "</td>";
            $tbl += "</tr>";

            if (obj.children) {
              $tbl = _getTableRows($tbl, obj.lvl, objLvl, obj.children, _valuesArr);
            }
          }
          return $tbl;
        };
      if (inp.__values) {
        var objects = JSON.parse(inp.htmlList), objLen = _getObjectsLength(0, objects),
          objLvl = _getObjectsLvl(1, objects), _valuesArr = inp.__values;

        gridVar = {
          $inst: null,
          len: objLen * _valuesArr.length + 1,
          cnt: 0
        };
        var $tbl = "<div class='col-12 docelement-grid grid-cmp-" + ind + "'>" +
          "<table width='100%' class='docelement-grid-header form-group field-group'>" +
          "<colgroup>";
        for (var i = 0; i < _valuesArr.length - 1; i++) {
          $tbl += "<col style='width:50px;'>";
        }
        for (var i = 0; i < objLvl; i++) {
          $tbl += "<col style='width:300px;'>";
        }
        $tbl += "<col>" +
          "</colgroup>" +
          "<thead>" +
          "<tr class='docelement-grid-header form-group field-group docelement-grid-row-header'>";
        for (var i = 0; i < _valuesArr.length; i++) {
          $tbl += "<th>" +
            "<div class='grid-caption-header'>" +
            "<span class='grid-caption-header__inner'>" + _valuesArr[i] + "</span>" +
            "</div>" +
            "</th>";
        }
        $tbl += "<th class='field-label' colspan='" + objLvl + "'>&nbsp;</th>" +
          "</tr>" +
          "</thead>" +
          "<tbody>";
        $tbl = _getTableRows($tbl, 0, objLvl, objects, _valuesArr);
        $tbl += "</tbody>" +
          "</table>" +
          "</div>";

        $ptr.append($tbl);
        gridVar.$inst = $(".grid-cmp-" + ind, $ptr);
      }
    },
    _doElementDynamicGroup = function (inp, ind) {
      var $ptr = _getPaintPoint(inp, ind), cnt = 0;

      var _getObjectsLvl = function (lvl, objects) {
          for (var k in objects) {
            var obj = objects[k];
            if (obj.lvl > lvl) lvl = obj.lvl;
            if (obj.children) {
              lvl = _getObjectsLvl(lvl, obj.children);
            }
          }
          return lvl;
        },
        _getObjectsLength = function (ln, objects) {
          ln += objects.length;
          for (var k in objects) {
            var obj = objects[k];
            if (obj.children) {
              ln = _getObjectsLength(ln, obj.children);
            }
          }
          return ln;
        },
        _getTableRows = function ($tbl, lvl, objLvl, objects, valuesArr) {
          if (lvl === 0) {
            $tbl += "<tr class='docelement-grid-row form-group field-group docelement-grid-row-summary'>";
            $tbl += "<td class='field-label'>&nbsp;</td>";

            for (var j = 0; j < valuesArr.length; j++) {
              if (j === 0) { // KOEF
                for (var jjj = 0; jjj < objLvl; jjj++) {
                  $tbl += jjj === 0 ?
                    "<td class='grid-value'>" +
                    "<div class='field-grid-value" + (++cnt) + "' style='float:none !important;display: inline-block;'>&nbsp;</div>" +
                    "</td>" :
                    "<td class='grid-value'>&nbsp;</td>";
                }
              } else if (j == 1 || j == (valuesArr.length - 1)) { // RATIONALE FOR PERCENTAGE && RATIONALE FOR SCORE
                $tbl += "<td class='grid-value'>&nbsp;</td>";
              } else {
                $tbl += "<td class='grid-value'>" +
                  "<div class='field-grid-value" + (++cnt) + "' style='float:none !important;display: inline-block;'>&nbsp;</div>" +
                  "</td>";
              }
            }
            $tbl += "</tr>";
          }
          for (var k in objects) {
            var obj = objects[k];

            $tbl += "<tr class='docelement-grid-row form-group field-group docelement-grid-row-level-" + lvl + (obj.children ? " docelement-grid-row-summary" : "") + "'>";
            $tbl += "<td class='field-label'>" + obj.name + "</td>";
            for (var j = 0; j < valuesArr.length; j++) {
              if (j === 0) { // KOEF
                if (lvl === 0) {
                  for (var jjj = 0; jjj < objLvl; jjj++) {
                    $tbl += jjj === 0 ?
                      "<td class='grid-value'>" +
                      "<div class='field-grid-value" + (++cnt) + "' style='float:none !important;display: inline-block;'>&nbsp;</div>" +
                      "</td>" :
                      "<td class='grid-value'>&nbsp;</td>";
                  }
                } else {
                  for (var jjj = 0; jjj < objLvl; jjj++) {
                    $tbl += jjj === (objLvl - 1) ?
                      "<td class='grid-value'>" +
                      "<div class='field-grid-value" + (++cnt) + "' style='float:none !important;display: inline-block;'>&nbsp;</div>" +
                      "</td>" :
                      "<td class='grid-value'>&nbsp;</td>";
                  }
                }
              } else {
                $tbl += "<td class='grid-value'>" +
                  "<div class='field-grid-value" + (++cnt) + "' style='float:none!important; display:inline-block;'>&nbsp;</div>" +
                  "</td>";
              }
            }
            $tbl += "</tr>";

            if (obj.children) {
              $tbl = _getTableRows($tbl, obj.lvl, objLvl, obj.children, _valuesArr);
            }
          }
          return $tbl;
        };
      if (inp.__values) {
        var objects = JSON.parse(inp.htmlList), objLen = _getObjectsLength(0, objects),
          objLvl = _getObjectsLvl(1, objects), _valuesArr = inp.__values;

        gridVar = {
          $inst: null,
          len: ((objLen) * _valuesArr.length) + (_valuesArr.length - 2),
          cnt: 0
        };
        var $tbl = "<div class='col-12 docelement-grid grid-cmp-" + ind + "'>" +
          "<table width='100%' class='docelement-grid-header form-group field-group'>" +
          "<colgroup>" +
          "<col>";
        for (var i = 0; i < objLvl; i++) {
          $tbl += "<col style='width:50px;'>";
        }
        for (var i = 1; i < _valuesArr.length; i++) {
          $tbl += (i == 1 || i == (_valuesArr.length - 1)) ? "<col style='width:280px;'>" : "<col style='width:60px;'>";
        }
        $tbl += "</colgroup>" +
          "<thead>" +
          "<tr class='docelement-grid-header form-group field-group docelement-grid-row-header'>" +
          "<th class='field-label'>" +
          "<div class='grid-caption-header'>" +
          "<span class='grid-caption-header__inner'>Key topics</span>" +
          "</div>" +
          "</th>";
        for (var i = 0; i < _valuesArr.length; i++) {
          $tbl += "<th " + ((objLvl > 1 && i === 0) ? (" colspan='" + objLvl + "'") : "") + ">" +
            "<div class='grid-caption-header'>" +
            "<span class='grid-caption-header__inner'>" + _valuesArr[i] + "</span>" +
            "</div>" +
            "</th>";
        }
        $tbl += "</tr>" +
          "</thead>" +
          "<tbody>";
        $tbl = _getTableRows($tbl, 0, objLvl, objects, _valuesArr);
        $tbl += "</tbody>" +
          "</table>" +
          "</div>";

        $ptr.append($tbl);
        gridVar.$inst = $(".grid-cmp-" + ind, $ptr);
      }
    },
    _doElementPanel = function (inp, ind) {
      panelVar = {
        id: inp.field.toLowerCase(),
        len: parseInt(_htmlList || '0', 10),
        cnt: 0
      };
    },
    _doElementTable = function (inp, ind) {
      var $ptr = _getTablePainter(inp, ind),
        $tbldata = "", $tblheader = "", $tblcol = "", tabPrefix = "TAB" + _tabNum;
      $(inputs).each(function () {
        var fld = this, tabn = parseInt(fld.tabNum || 0);
        if (tabn === _tabNum && fld?.dynTabForm?.primary !== 0) {
          $tblheader += "<th class='a-c'>" + (fld.label || fld.field) + "</th>";
          $tbldata += "<td>" +
            "<div class=\"fc\" p=\"" + fld.field + "\" tabno=\"" + _tabNum + "\"></div></td>" +
            "</td>";
          $tblcol += "<col>";
        }
      });

      $ptr.append(
        "<div class='col-12 form-group table-group'>" +
        "<table class=\"document-group\" cellpadding=\"0\" cellspacing=\"0\" border=\"1\">" +
        "<colgroup>" +
        "<col style=\"width: 7rem;\">" +
        "<col style=\"width: 5rem;\">" +
        $tblcol +
        "</colgroup>" +
        "<thead>" +
        "<tr class=\"document-group-header\">" +
        "<th class=\"a-c\"> </th>" +
        "<th class=\"a-c\">#</th>" +
        $tblheader +
        "</tr>" +
        "</thead>" +
        "<tbody class=\"table-template\" p=\"template:" + tabPrefix + "\">" +
        "<tr class=\"table-template-row\" p=\"template:" + tabPrefix + "\">" +
        "<td class=\"a-c row-template-actions\"></td>" +
        "<td >" +
        "<div class=\"fc field-rownum a-c\" p=\"" + tabPrefix + "(?).rn_" + _tabNum + "\" tabno=\"" + _tabNum + "\" style=\"width:99%!important; text-align: center!important;\"></div>" +
        "</td>" +
        $tbldata +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>");
    },
    // grid component htmlList should be row;row2;row3:col1;col2[:lenCol1;lenCol2]
    _doElementGroup = function (inp, ind) {
      var $ptr = _getPaintPoint(inp, ind);
      if (_htmlList.indexOf(":") !== -1) {
        var _els = _htmlList.split(":"),
          _cssPanel = "",
          _rowLabels = _els[0], _colLabels = _els[1], _colSizesArr = [], _sizeAdd = 0,
          _rowLabelsArr = _rowLabels.split(";"), _colLabelsArr = _colLabels.split(";");
        if (_els.length >= 3) {
          _colSizesArr = _els[2].split(";");
          if (_colSizesArr.length > _colLabelsArr.length) {
            var _len = _colSizesArr.length - _colLabelsArr.length;
            for (var i = 0; i < _len; i++) {
              delete _colSizesArr[_colSizesArr.length - 1];
            }
          }
          if (_colSizesArr.length < _colLabelsArr.length) {
            var _len = _colLabelsArr.length - _colSizesArr.length;
            for (var i = 0; i < _len; i++) {
              _colSizesArr.push(2);
            }
          }
        } else {
          for (var i = 0; i < _colLabelsArr.length; i++) {
            _colSizesArr[i] = 2;
          }
        }
        for (var i = 0; i < _colSizesArr.length; i++) {
          _sizeAdd += parseInt(_colSizesArr[i], 10);
        }
        _sizeAdd = 12 - _sizeAdd;

        gridVar = {
          $inst: null,
          len: _rowLabelsArr.length * _colLabelsArr.length,
          cnt: 0
        };
        if (panelVar) {
          if ((1 + panelVar.cnt) <= panelVar.len) {
            _cssPanel = " field-panel panel-" + panelVar.id;
            panelVar.cnt++;
          }
          if (panelVar.cnt >= panelVar.len) {
            delete panelVar;
          }
        }
        var $tbl = "<div class='row no-gutters docelement-grid grid-cmp-" + ind + _cssPanel + "'>", cnt = 0;
        if (!isMobileDevice) { // Desktop devices
          // if (false) { // Desktop devices
          $tbl += "<div class='col-12 docelement-grid-header form-group field-group'>" +
            "<div class='row no-gutters'>" +
            "<div class='col-4 field-label'>&nbsp;</div>" +
            "<div class='col-8'>" +
            "<div class='row'>";
          for (var i = 0; i < _colLabelsArr.length; i++) {
            $tbl += "<div class='col-" + _colSizesArr[i] + "'>" +
              "<div class='grid-caption-header'>" +
              "<span class='grid-caption-header__inner'>" + _colLabelsArr[i] + "</span>" +
              "</div>" +
              "</div>";
          }
          if (_sizeAdd > 0) {
            $tbl += "<div class='col-" + _sizeAdd + "'>&nbsp;</div>";
          }
          $tbl += "</div>" + //col-8
            "</div>" + // row
            "</div>" + // row
            "</div>"; // col-12

          for (var i = 0; i < _rowLabelsArr.length; i++) {
            $tbl += "<div class='col-12 docelement-grid-row form-group field-group'>" +
              "<div class='row no-gutters'>" +
              "<div class='col-4 field-label'>" + _rowLabelsArr[i] + "</div>" +
              "<div class='col-8'>" +
              "<div class='row no-gutters'>";
            for (var j = 0; j < _colLabelsArr.length; j++) {
              $tbl += "<div class='col-" + _colSizesArr[j] + " grid-value'>" +
                "<div class='field-grid-value" + (++cnt) + "' style='float:none !important;display: inline-block;text-align:center;'>&nbsp;</div>" +
                "</div>";
            }
            if (_sizeAdd > 0) {
              $tbl += "<div class='col-" + _sizeAdd + "'>&nbsp;</div>";
            }
            $tbl += "</div>" + //col-8
              "</div>" + // row
              "</div>" + // row
              "</div>"; // col-12
          }
        } else { // is Mobile device
          for (var i = 0; i < _rowLabelsArr.length; i++) {
            $tbl += "<div class='col-12 docelement-grid-row form-group'>" +
              "<div class='col-12 grid-caption-header-mob field-label'>" + _rowLabelsArr[i] + "</div>";
            for (var j = 0; j < _colLabelsArr.length; j++) {
              $tbl += "<div class='col-12 field-group'>" +
                "<div class='col-4 field-label'>&nbsp;&nbsp;&nbsp;" + _colLabelsArr[j] + "</div>" +
                "<div class='col-8 grid-value field-grid-value" + (++cnt) + "'>&nbsp;</div>" +
                "</div>";
            }
            $tbl += "</div>";
          }
        }
        $tbl += "</div>";
        $ptr.append($tbl);

        gridVar.$inst = $(".grid-cmp-" + ind, $ptr);
      }
    },
    _paintRadiogroup = function (inp, ind, currCM) {
      let isDict = false;

      var _arr = ("" + _htmlList).split(";"), $ptr = _getPaintPoint(inp, ind), isGrid = false, cssPanel = "";
      if (inp.editType && inp.editType.indexOf('shuffle') >= 0) {
        shuffleArray(_arr);
      }
      if (gridVar) {
        if ((_arr.length + gridVar.cnt) <= gridVar.len) {
          isGrid = true;
        }
      }
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          cssPanel = " field-panel panel-" + panelVar.id;
          if (isGrid === false) panelVar.cnt++;
        }
        if (isGrid === false && panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }

      var _formatter;
      // for labeled chkb in grid
      // if (_tabNum === 0) {
      //   _formatter = isGrid ?
      //     _edittype.indexOf("radiogroup") !== -1 ? "rdDMGroup" : "chkDMGroup" :
      //     _edittype.indexOf("radiogroup") !== -1 ? "rdDMGroupWithLabel" : "chkDMGroupWithLabel";
      // } else {
      //   _formatter = _edittype.indexOf("radiogroup") !== -1 ? "rdDMGroupWithLabel" : "chkDMGroupWithLabel";
      // }
      var _ismeta = inp?.metadataField === 'WithLabel';
      _formatter = _edittype.indexOf("radiogroup") !== -1 ? "rdDMGroupWithLabel" : "chkDMGroupWithLabel";
      if ((_tabNum === 0) && isGrid && !_ismeta) {
        _formatter = _edittype.indexOf("radiogroup") !== -1 ? "rdDMGroup" : "chkDMGroup";
      }
      var _res = "", _r, $tbl = "", _newNm, _arrValues = [];

      if (_htmlList.startsWith('DICT')) {
        isDict = true;
        const list = _htmlList.split(':');
        if (list.length < 2) {
          return;
        }
        dynaFields.push(currCM.index);
        const valueField = list.length >= 3 ? list[2] : '';
        const parentDictName = list.length >= 4 ? list[3] : '';
        const parentValueField = list.length >= 5 ? list[4] : '';
        _userOpts['dctName'] = list[1];
        _userOpts['dctValueField'] = valueField;
        _userOpts['dctParentName'] = parentDictName;
        _userOpts['dctParentValueField'] = parentValueField;
        _htmlList = ' : ';
      }

      for (var i in _arr) {
        _r = _arr[i];
        var _recItems = ("" + _r).split(":"),
          _vl = _recItems[0], _lbl = _recItems.length > 1 ? _recItems[1] : _vl;
        _res += (_res.length > 0 ? ";" : "") + (_vl + ":" + _lbl);
        _arrValues.push(_vl);
      }

      if (!isDict) {
        for (var i in _arr) {
          _newNm = currCM.index + "_" + i;

          _r = _arr[i];
          var _recItems = ("" + _r).split(":"),
            _vl = _recItems[0], _lbl = _recItems.length > 1 ? _recItems[1] : _vl;
          colModel.push({
            index: _newNm,
            name: _newNm,
            label: _lbl,
            editable: _editable,
            edittype: "checkbox",
            formatter: _formatter,
            tabn: _tabNum,
            // tabn: 0,
            __is_editors: true,
            editoptions: {
              value: "1:0"
            },
            useropts: {
              parent: {
                name: currCM.index,
                value: _arrValues[i]
              },
              afterSave: $.jdm.radiogroup__generateAfterSaveFunc((_formatter === "rdDMGroupWithLabel" || _formatter === "rdDMGroup") ? "radiogroup" : "checkgroup", currCM.index, i, _arrValues)
            }
          });
          if (isGrid) {
            gridVar.cnt++;
            if (_visible) {
              var $gridInp = $(".field-grid-value" + (gridVar.cnt), gridVar.$inst);
              $gridInp
                .attr("field", currCM.index)
                .html("<div class='fc' p='" + (currCM.index + "_" + i) + "'></div>");
            }
          } else {
            $tbl += "<li>" +
              "<div class='fc' p='" + (currCM.index + "_" + i) + "'></div>" +
              "</li>";
          }
        }
      }

      if (isGrid) {
        if (gridVar.cnt >= gridVar.len) {
          delete gridVar;
        }
      } else {
        var _cssGroupAnswers = "field-group-answers";
        if (_edittype.indexOf(":vertical") !== -1) _cssGroupAnswers = "field-group-answers-vertical";
        if (_edittype.indexOf(":horizontal") !== -1) _cssGroupAnswers = "field-group-answers-horizontal";
        var __styleCols = "";
        if (_edittype.indexOf(":vertical") !== -1) {
          var pos_col = _edittype.indexOf(":cols")
          if (pos_col !== -1) {
            var cols = parseInt(_edittype.substr(pos_col + 5, 1), 10);
            if (!isNaN(cols) && (cols > 1) && (cols <= 12)) {
              __styleCols = ' style="columns:' + cols + ';" ';
            }
          }
        }
        if (_tabNum === 0) {
          var _cssLabelNo = "", _style = "display: " + (_visible === true ? "flex;" : "none;");
          if (ind < inputs.length) {
            if (inp.lvl > 1) {
              _cssLabelNo = " field-label-" + (inp.lvl >= 1 ? inp.lvl : "");
            }
          }
          // $tbl = "<ul class='" + _cssGroupAnswers + "'>" + $tbl + "</ul>";
          $tbl = "<ul class='" + _cssGroupAnswers + "'" + __styleCols + ">" + $tbl + "</ul>";
          $ptr.append("<div class='form-row form-group field-group" + _cssLinePlace + cssPanel + "'  field='" + currCM.index + "' style='" + _style + "'>" +
            "<div class='col-12 col-sm-4 field-label" + _cssLabelNo + "'>" +
            "<span class='jdm-fldlabel jdm-fld-" + (currCM.index) + (_edrules && _edrules.required ? ' required' : '') + "'>" +
            (currCM.index === currCM.label ? "" : ("&nbsp;" + currCM.label)) +
            "</span>" +
            (currCM.hint ? "<i class='jdm-fldhint far fa-question-circle' onmouseover=\"$.jdm.buildPromptHint(this, '" + currCM.hint + "')\")'></i>" : "") +
            "</div>" +
            "<div class='col-12 col-sm-8' style='text-align: left!important;'>" + $tbl + "</div>" +
            "</div>");
        } else {
          if (_cssGroupAnswers === "field-group-answers") _cssGroupAnswers = "field-group-answers-horizontal";
          // $tbl = "<ul class='" + _cssGroupAnswers + "'>" + $tbl + "</ul>";
          $tbl = "<ul class='" + _cssGroupAnswers + "'" + __styleCols + ">" + $tbl + "</ul>";
          $ptr.replaceWith($tbl);
        }
      }
    },
    _paintInput = function (inp, ind, currCM) {
      const isButton = currCM.edittype === 'button';
      if (_visible === false && (gridVar || currCM.tabn !== 0)) {
        currCM.__is_editors = false;
        currCM.hidden = true;
        return currCM;
      }
      currCM.__is_editors = true;
      currCM.hidden = false;
      var $ptr = _getPaintPoint(inp, ind), isGrid = false, cssPanel = "";
      if (gridVar) {
        if ((1 + gridVar.cnt) <= gridVar.len) {
          isGrid = true;
        }
      }
      if (panelVar) {
        if ((1 + panelVar.cnt) <= panelVar.len) {
          cssPanel = " field-panel panel-" + panelVar.id;
          if (isGrid === false) panelVar.cnt++;
        }
        if (isGrid === false && panelVar.cnt >= panelVar.len) {
          delete panelVar;
        }
      }
      if (isGrid) {
        gridVar.cnt++;
        var $gridInp = $(".field-grid-value" + (gridVar.cnt), gridVar.$inst);
        $gridInp
          .attr("field", currCM.index)
          .html("<span class='jdm-fldlabel jdm-fldlabel-inner jdm-fld-" + (currCM.index) + (currCM.editrules && currCM.editrules.required ? ' required' : '') + " style='display: left!important;'></span>" +
            "<div class='fc' p='" + currCM.index + "'></div>");
        if (currCM.edittype !== "checkbox" && !isMobileDevice) {
          $gridInp.addClass("grid-value-full");
        }
        if (gridVar.cnt >= gridVar.len) {
          delete gridVar;
        }
      } else {
        if (currCM.tabn === 0) { // tabn == 0
          if (_visible === false) currCM.hidden = true;
          var _cssLabelNo = "", _style = "display: " + (_visible === true ? "flex;" : "none;");
          if (ind < inputs.length) {
            if (inp.lvl > 1) {
              _cssLabelNo = " field-label-" + (inp.lvl >= 1 ? inp.lvl : "");
            }
          }
          $ptr.append("<div class='form-row form-group field-group" + _cssLinePlace + cssPanel + "' field='" + currCM.index + "' style='" + _style + "'>" +
            "<div class='col-12 col-md-4 field-label" + _cssLabelNo + "'>" +
            (isButton ? '' :
                ("<span class='jdm-fldlabel jdm-fld-" + (currCM.index) + (currCM.editrules && currCM.editrules.required ? ' required' : '') + "'>" + (currCM.index === currCM.label ? "" : ("&nbsp;" + currCM.label)) + "</span>" +
                  (currCM.hint ? "<i class='jdm-fldhint far fa-question-circle' onmouseover=\"$.jdm.buildPromptHint(this, '" + currCM.hint + "')\")'></i>" : ""))
            ) +
            "</div>" +
            "<div class='col-12 col-md-8 field-value'>" +
            "<div class='fc' p='" + currCM.index + "' style='width:99%!important;'></div>" +
            // (currCM.edittype === "range" ? "<output class='custom-range-value'></output>" : "") +
            "</div>" +
            "</div>");
        } else { // tabn != 0
          $ptr
            .attr("p", "TAB" + currCM.tabn + "(?)." + currCM.index)
            .attr("tabno", currCM.tabn)
            .attr("style", "width:99%!important;");
        }
      }
      return currCM;
    },
    _setEditorSTATUS = function (inp, ind) {
      _editable = false;
      _edittype = "text";
    },
    _setEditorDATE = function (inp, ind) {
      _edrules["date"] = true;
      _edittype = "text";
    },
    _setEditorDICT = function (inp, ind) {
      var _pos = _htmlList.indexOf(":"), _pos1 = _htmlList.indexOf(":", _pos + 1);
      _userOpts['dctName'] = _htmlList.substring(0, _pos);
      _userOpts['dctField'] = _pos1 !== -1 ? _htmlList.substring(_pos + 1, _pos1) : _htmlList.substring(_pos + 1);
      _userOpts['dctGroup'] = (_pos1 !== -1) ? _htmlList.substring(_pos1 + 1) : "*";
    },
    _setEditorSELECTFromDict = function (inp, ind, multiselect) {
      _edopts['value'] = '';
      const list = _htmlList.split(':');
      if (list.length < 2) {
        return;
      }
      const valueField = list.length >= 3 ? list[2] : '';
      const parentDictName = list.length >= 4 ? list[3] : '';
      const parentValueField = list.length >= 5 ? list[4] : '';
      _userOpts['dctName'] = list[1];
      _userOpts['dctValueField'] = valueField;
      _userOpts['dctParentName'] = parentDictName;
      _userOpts['dctParentValueField'] = parentValueField;
      $.jdm.getDictData(list[1], valueField, parentDictName, parentValueField, null, function (data) {
        const cc = $("#" + inp.field);
        if (data.length > 0) {
          for (const v of data) {
            const ov = document.createElement("option");
            ov.setAttribute("role", "option");
            ov.value = v.value;
            ov.innerHTML = v.label;
            cc.append($(ov));
          }
          if (inp.editType === 'combobox') {
            $(cc).selectpicker('refresh');
            $(cc).on('show.bs.select', () => $(cc).trigger('focus'));
          }
        }
      });
    },
    _setEditorORDERLISTFromDict = function (inp) {
      _edopts['value'] = '';
      const list = _htmlList.split(':');
      if (list.length < 2) {
        return;
      }
      const valueField = list.length >= 3 ? list[2] : '';
      const parentDictName = list.length >= 4 ? list[3] : '';
      const parentValueField = list.length >= 5 ? list[4] : '';
      _userOpts['dctName'] = list[1];
      _userOpts['dctValueField'] = valueField;
      _userOpts['dctParentName'] = parentDictName;
      _userOpts['dctParentValueField'] = parentValueField;
      $.jdm.getDictData(list[1], valueField, parentDictName, parentValueField, null, function (data) {
        let rs = "";
        if (data.length > 0) {
          for (const v of data) {
            rs += (rs !== "" ? ";" : "") + v.value + ":" + v.label;
          }
        }
        const cm = pz.$inst[0].p.colModel.find(v => v.index === inp.field);
        if (cm?.editoptions) {
          cm.editoptions.value = rs;
        }
      });
    },
    _setEditorSELECT = function (inp, ind, multiselect) {
      multiselect = typeof (multiselect) === "undefined" ? false : multiselect;
      if (multiselect) _edittype = "select";
      _formatter = 'select';
      if (_htmlList.startsWith('DICT')) {
        _setEditorSELECTFromDict(inp, ind, multiselect);
        return;
      }
      var _res = "", _listItems = ("" + _htmlList).split(";"), _r;
      var _resFull = "", _cascade = false;
      for (var i in _listItems) {
        _r = _listItems[i];
        var _recItems = ("" + _r).split(":"),
          _vl = _recItems[0], _lbl = _recItems.length > 1 ? _recItems[1] : _vl
        _res += (_res.length > 0 ? ";" : "") + (_vl + ":" + _lbl);
        if (!_cascade && _recItems.length === 4) {
          _cascade = true;
        }
        if (_cascade) {
          _resFull += (_resFull.length > 0 ? ";" : "") + _vl + ":" + _lbl + ":" + _recItems[2] + ":" + _recItems[3];
        }
      }
      _edopts['value'] = _res;
      if (_cascade) {
        _edopts['valueFull'] = _resFull;
        _edopts['cascade'] = _cascade;
      }
      if (multiselect) {
        _edopts['multiple'] = true;
        _edopts['size'] = _listItems.length <= 10 ? _listItems.length : 10;
      }
    },
    _setEditorCOMBOBOX = function (inp, ind, multiselect) {
      _edittype = "select";
      _formatter = 'select';
      _userOpts["select_search"] = true;
      if (_htmlList.startsWith('DICT')) {
        _setEditorSELECTFromDict(inp, ind, multiselect);
        return;
      }
      var _res = "", _listItems = ("" + _htmlList).split(";"), _r;
      var _resFull = "", _cascade = false;
      for (var i in _listItems) {
        _r = _listItems[i];
        var _recItems = ("" + _r).split(":"),
          _vl = _recItems[0], _lbl = _recItems.length > 1 ? _recItems[1] : _vl
        _res += (_res.length > 0 ? ";" : "") + (_vl + ":" + _lbl);
        if (!_cascade && _recItems.length === 4) {
          _cascade = true;
        }
        if (_cascade) {
          _resFull += (_resFull.length > 0 ? ";" : "") + _vl + ":" + _lbl + ":" + _recItems[2] + ":" + _recItems[3];
        }
      }
      _edopts['value'] = _res;
      if (_cascade) {
        _edopts['valueFull'] = _resFull;
        _edopts['cascade'] = _cascade;
      }
      if (multiselect) {
        _edopts['multiple'] = true;
        _edopts['size'] = _listItems.length <= 10 ? _listItems.length : 10;
      }
    },
    _setEditorRADIOBOX = function (inp, ind) {
      _edittype = "checkbox";
      _edopts["value"] = _htmlList; // 1:0
      _formatter = "rdDMGroup";
    },
    _setEditorCHECKBOX = function (inp, ind) {
      _edittype = "checkbox";
      _edopts["value"] = _htmlList; // 1:0
      _formatter = "chkDMGroup";
    },
    _setEditorFILE = function (inp, ind) {
      _edittype = "file";
      _formatter = "file";
    },
    _setEditorBUTTON = function (inp, ind) {
      _edittype = "button";
      const list = (_htmlList || '').split(';');
      let style = {};
      list.forEach(attr => {
        const arr = attr.split(':');
        if (arr.length === 2) {
          const name = arr[0].trim().toLowerCase();
          const val = arr[1].trim().toLowerCase();
          const valNormal = arr[1].trim();
          if (name === 'action' && (val === 'submit' || val === 'save' || val === 'sign')) {
            _userOpts["action"] = val;
          } else if (name === 'background-color') {
            style.backgroundColor = val;
          } else if (name === 'color' || name === 'icon' || name === 'size') {
            style[name] = val;
          } else if (name === 'onclick' && val.startsWith('fillfields')) {
            _userOpts["onclick"] = valNormal.substring('fillfields'.length + 1, val.length - 1);
          } else if (name === 'checkerrors' && val === 'true') {
            _userOpts['checkErrors'] = true;
          } else if (name === 'checkerrorsmessage') {
            _userOpts['checkErrorsMessage'] = arr[1].trim();
          }
        }
      });
      if (Object.keys(style).length > 0) {
        _userOpts["style"] = style;
      }
      _formatter = "actionButton";
    },
    _setEditorRANGE = function (inp, nodeName) {
      _edittype = "range";
      const list = (_htmlList || '').split(';');
      const rangeOpts = {min: 0, max: 100, step: 1};
      list.forEach(attr => {
        const arr = attr.split(':');
        if (arr.length === 2) {
          const name = arr[0].trim().toLowerCase();
          const val = arr[1].trim().toLowerCase();
          if (name === 'min' && !isNaN(+val)) {
            rangeOpts.min = +val;
          } else if (name === 'max' && !isNaN(+val)) {
            rangeOpts.max = +val;
          } else if (name === 'step' && !isNaN(+val)) {
            rangeOpts.step = +val;
          }
        }
      });
      _userOpts['range'] = rangeOpts;
      _formatter = "range";
    },
    _setOrderList = function (inp, ind, multiselect) {
      _edittype = "orderlist";
      _formatter = "orderlist";
      if (_htmlList.startsWith('DICT')) {
        _setEditorORDERLISTFromDict(inp);
        return;
      }
      var _res = "", _listItems = ("" + _htmlList).split(";"), _r;
      for (var i in _listItems) {
        _r = _listItems[i];
        var _recItems = ("" + _r).split(":"),
          _vl = _recItems[0], _lbl = _recItems.length > 1 ? _recItems[1] : _vl
        _res += (_res.length > 0 ? ";" : "") + (_vl + ":" + _lbl);
      }
      _edopts['value'] = _res;
    },
    _setEditorRICHTEXTAREA = function (inp, ind) {
      _edittype = "richtextarea";
      _formatter = "richtextarea";
    },
    _checkTypes = function (inp, ind) {
      var _maxlength = !isNaN(parseInt(inp.maxLength, 10)) ? parseInt(inp.maxLength, 10) : 0,
        _minVal = null, _maxVal = null;
      if (_dataType === 'NUMBER') {
        _minVal = !isNaN(parseFloat(inp.minValue)) ? parseFloat(inp.minValue) : null;
        _maxVal = !isNaN(parseFloat(inp.maxValue)) ? parseFloat(inp.maxValue) : null;
      } else {
        _minVal = !isNaN(parseInt(inp.minValue)) ? parseInt(inp.minValue) : null;
        _maxVal = !isNaN(parseInt(inp.maxValue)) ? parseInt(inp.maxValue) : null;
      }
      if (_maxlength !== 0) {
        var v = parseInt(_maxlength, 10);
        if (!isNaN(v) && v !== 0) _edopts['maxlength'] = _maxlength;
      }
      if (_dataType === "NUMBER") {
        if (_decimalPlaces !== 0) {
          _edrules["number"] = true;
          _formatter = "number";
          if (inp.required) {
            _formatopts = {
              decimalPlaces: _decimalPlaces
              , decimalSeparator: _decimalSeparator
              //,defaultValue:$.fmatter.util.NumberFormat('0', { decimalPlaces:_decimalPlaces , decimalSeparator:_decimalSeparator })
              , defaultValue: ""
            };
          } else {
            _formatopts = {
              decimalPlaces: _decimalPlaces
              , decimalSeparator: _decimalSeparator
              , defaultValue: ""
            };
          }
        } else {
          _edrules["number"] = true;
          _formatter = "number";
        }
        if (_minVal !== null) {
          _edrules["minValue"] = _minVal;
        }
        if (_maxVal !== null) {
          _edrules["maxValue"] = _maxVal;
        }
      } else if (_dataType === "INTEGER") {
        _formatter = "integer";
        _formatopts = {thousandsSeparator: "", defaultValue: "&nbsp;"};
        _edrules["integer"] = true;

        if (_minVal !== null) {
          _edrules["minValue"] = _minVal;
        }
        if (_maxVal !== null) {
          _edrules["maxValue"] = _maxVal;
        }
      }
    },
    _checkEditType = function (edtype) {
      if (edtype.length === 0) return "";
      var _permittedEdtypes = [
        "output", // output
        "subtitle", // subtitle
        "note", // note
        "grid", //grid
        "panel", // ...
        "dynamic-choice", // dynamic-choice
        "dynamic-group", // dynamic-group
        "dynamic-grid", // dynamic-grid
        "dynamic-group-key", //dynamic-group-key
        "dynamic-summary", // dynamic-summary
        "status", // status
        "dict", //dict
        "date", //date
        "select", //select
        "multiselect", //select with multiselect
        "radiobox", // radiobox
        "checkbox", // checkbox
        "text", // text
        "textarea", // textarea
        "richtextarea", // richtextarea
        "combobox", // combobox
        "multiselect-combobox", // combobox with multiselect
        "file", // file upload
        "button", // action buttons
        "range", // input range
        "orderlist", // ordered list picker
        "helpwindow" // ordered list picker
      ];
      if (_permittedEdtypes.indexOf(edtype) >= 0 || edtype.startsWith("radiogroup") || edtype.startsWith("checkgroup") ||
        edtype.startsWith('chart') ||
        edtype.startsWith('orderlist')) {
        return edtype;
      }
      return "text";
    };
  // Detect Wizard
  _initVars();
  // Cycle for all parameters
  $(inputs).each(function (ind) {
    var inp = this, nodeName = inp.field, radioOrCheckGroup = false;

    _dataType = ("" + inp.dataType).toUpperCase();
    _editable = typeof (inp.editable) !== 'undefined' ? (inp.editable === 1) : false;
    _htmlList = typeof (inp.htmlList) !== 'undefined' ? (inp.htmlList !== "" ? inp.htmlList : "") : "";
    _edittype = _checkEditType(typeof (inp.editType) !== 'undefined' ? (inp.editType !== "" ? inp.editType.toLowerCase() : "") : "");
    _formatter = "";
    _decimalPlaces = typeof (inp.decimalPlaces) !== 'undefined' ? parseInt(inp.decimalPlaces, 10) : 0;
    _decimalSeparator = typeof (inp.decimalSeparator) !== 'undefined' ? (inp.decimalSeparator !== "" ? inp.decimalSeparator : "") : "";
    _visible = typeof (inp.visible) !== 'undefined' ? (parseInt(inp.visible) === 1) : true;
    _isGenerate = typeof (inp.__is_generate) !== 'undefined' ? inp.__is_generate : false,
      _groupFld = typeof (inp.__groupFld) !== 'undefined' ? inp.__groupFld : "",
      _isGroup = typeof (inp.__is_group) !== 'undefined' ? inp.__is_group : false,
      _isSummary = typeof (inp.__is_summary) !== 'undefined' ? inp.__is_summary : false,
      _isKoef = typeof (inp.__is_koef) !== 'undefined' ? inp.__is_koef : false,
      _userOpts = {
        edittype: _edittype,
        datatype: _dataType,
        type: _dataType,
        baseType: inp.base,
        grpType: inp.grpType,
        isGenerate: _isGenerate && (_isKoef == false && _isSummary === false),
        dynTabForm: inp.dynTabForm
      };
    _edrules = {};
    _edopts = {};
    _formatopts = {};
    _cssLinePlace = "";
    _tabNum = inp.tabNum || 0;
    _hint = inp.hint;
    _inputMask = inp.inputMask;

    if (typeof (inp.css) !== 'undefined') {
      _userOpts["css"] = inp.css;
    }
    const linePlace = typeof (inp.linePlace) !== 'undefined' ? parseInt(inp.linePlace, 10) : 0;
    if ((linePlace & 1) !== 0) {
      _cssLinePlace += " field-group-border-top";// top
    }
    if ((linePlace & 2) !== 0) {
      _cssLinePlace += " field-group-border-bottom";// bottom
    }
    if ((linePlace & 4) !== 0) {
      _cssLinePlace += " field-group-page-break";// page-break
    }
    if (_edittype === 'output') { // Process edittype == "OUTPUT"
      if (inp.tabNum !== 0) return;
      _doElementOutput(inp, ind);
      return;
    } else if (_edittype === "subtitle") { // Process edittype == "SUBTITLE"
      if (inp.tabNum !== 0) return;
      _doElementSubtitle(inp, ind);
      return;
    } else if (_edittype === "note") { // Process edittype == "NOTE"
      if (inp.tabNum !== 0) return;
      _doElementNote(inp, ind);
      return;
    } else if (_edittype === 'grid') {
      if (inp.tabNum !== 0) return;
      _doElementGroup(inp, ind);
      return;
    } else if (_edittype === 'dynamic-grid' || _edittype === 'dynamic-group') {
      if (inp.tabNum !== 0) return;
      _doElementDynamicGroup(inp, ind);
      return;
    } else if (_edittype === 'dynamic-group-key') {
      if (inp.tabNum !== 0) return;
      _doElementDynamicGroupKeys(inp, ind);
      return;
    } else if (_edittype === 'dynamic-summary') {
      if (inp.tabNum !== 0) return;
      _doElementDynamicSummary(inp, ind);
      return
    } else if (_edittype === 'dynamic-choice') {
      _doElementDynamicChoice(inp, ind);
      if (inp.tabNum !== 0) return;
      return;
    } else if (_edittype === 'panel') {
      if (inp.tabNum !== 0) return;
      _doElementPanel(inp, ind);
      return;
    } else if (_edittype === 'helpwindow') {
      if (inp.tabNum !== 0) return;
      _doElementHelpWindow(inp, ind);
    }

    if (_tabNum !== 0 && _tabNum > currentTableNum) {
      delete panelVar;
      currentTableNum = _tabNum;
      colModel.push({
        index: "rn_" + _tabNum,
        name: "rn_" + _tabNum,
        editable: false,
        tabn: _tabNum,
        rownum: true,
        __is_editors: true
      });
      _doElementTable(inp, ind);
    }
    if (typeof (inp.initVal) !== 'undefined') {
      _userOpts['defaultVal'] = inp.initVal;
    }
    if (_dataType === 'DATE' && _edittype === 'dict') {
      _setEditorDICT(inp, ind);
      _edittype = 'date';
    }
    if (_dataType === 'DATE' && _edittype === "text") {
      _edittype = "date";
    }

    if (inp.required) _edrules["required"] = true;
    switch (_edittype) {
      case 'dict':
        _setEditorDICT(inp, ind);
        _checkTypes(inp, ind);
        break;
      case 'multiselect':
      case 'select':
        _setEditorSELECT(inp, ind, _edittype === "multiselect");
        _checkTypes(inp, ind);
        break;
      case "combobox":
      case "multiselect-combobox":
        _setEditorCOMBOBOX(inp, ind, _edittype === "multiselect-combobox");
        break;
      case 'radiobox':
        _setEditorRADIOBOX(inp, ind);
        break;
      case 'checkbox':
        _setEditorCHECKBOX(inp, ind);
        break;
      case 'date':
        _setEditorDATE(inp, ind);
        break;
      case 'status':
        _setEditorSTATUS(inp, ind);
        break;
      case 'file':
        _setEditorFILE(inp, ind);
        break;
      case 'button':
        _setEditorBUTTON(inp, ind);
        break;
      case 'range':
        _setEditorRANGE(inp, nodeName);
        break;
      case 'orderlist':
        _setOrderList(inp, ind, true);
        break;
      case 'richtextarea':
        _setEditorRICHTEXTAREA(inp, ind);
        break;
      default:
        _checkTypes(inp, ind);
        break;
    }
    var currCM = {
      index: nodeName,
      name: nodeName,
      label: typeof (inp.label) !== 'undefined' ? (inp.label.length !== "" ? inp.label : nodeName) : nodeName,
      shortName: inp.shortName,
      editable: _editable,
      tabn: _tabNum,
      hint: _hint,
      __is_editors: _visible,
      __forceValidate: false
    };
    if (_edittype !== "") {
      if (_edittype.indexOf("radiogroup") !== -1 || _edittype.indexOf("checkgroup") !== -1) {
        radioOrCheckGroup = true;
        currCM.__is_editors = false;
        currCM.hidden = true;
        currCM.__forceValidate = true
        if (!$.isEmptyObject(_userOpts))
          _userOpts["childGroup"] = true;
        else {
          _userOpts = {};
          _userOpts["childGroup"] = true;
        }
        _paintRadiogroup(inp, ind, currCM);
        _edittype = "";
      } else if ((_edittype || '').startsWith('chart')) { // Process edittype == "chart:[bar, line, radar]"
        if (inp.tabNum !== 0) return;
        const chartType = _edittype.split(':')[1];
        if (CHART_TYPES.indexOf(chartType) < 0) {
          return;
        }
        let data;
        const options = {};
        const params = (_htmlList || '').split(';');
        let plug_labels = false;
        params.forEach(p1 => {
          const p = p1.replace("\n", "");
          const arr = p.split(':');

          if (arr.length >= 2) {
            if (arr[0] === 'data') {
              data = arr[1].split('|').map(v => v.split(','));
            } else if (arr[0] === 'backgroundColor') {
              options.backgroundColor = arr[1].split('|').map(v => v.split(','));
            } else if (arr[0] === 'borderColor') {
              options.borderColor = arr[1].split('|').map(v => v.split(','));
            } else if (arr[0] === 'legend') {
              options.legend = arr[1].split('|');
            } else if (arr[0] === 'label') {
              options.label = arr[1].split(',');
            } else if (arr[0] === 'suggestedMin') {
              options.suggestedMin = arr[1];
            } else if (arr[0] === 'tableOrientation') {
              options.tableVertical = arr[1] !== 'horizontal';
            } else if (arr[0] === 'indexAxis') {
              options.indexAxis = arr[1];
            } else if (arr.length > 2) {
              const pos = p.indexOf(':');

              if (pos > 0) {
                const varName = p.substring(0, pos);
                const varValues = p.substring(pos + 1).split('|');
                if (varName === 'datalabels_plug') {
                  let plug_labels = {};
                  varValues.forEach(p => {
                    const arr33 = p.split(':');
                    if (arr33.length === 2) {
                      plug_labels[arr33[0]] = arr33[1];
                    }
                  });
                  // let plug_labels = varValues;
                  if (plug_labels && Object.keys(plug_labels).length > 0) {
                    plug_labels['display'] = true;
                    if ((plug_labels?.font_weight && plug_labels.font_weight != "undefined") || (plug_labels?.font_size && plug_labels.font_size != "undefined")) {
                      plug_labels['font'] = {};
                      if (plug_labels?.font_weight) {
                        plug_labels.font['weight'] = plug_labels?.font_weight;
                      }
                      if (plug_labels?.font_size) {
                        plug_labels.font['size'] = plug_labels?.font_size;
                      }
                      if (plug_labels?.font_family) {
                        plug_labels.font['family'] = plug_labels?.font_family;
                      }
                    }
                    options.plug_labels = plug_labels;
                  }
                } else if (varName === 'font_labels') {
                  let font_labels = {};
                  varValues.forEach(p => {
                    const arr33 = p.split(':');
                    if (arr33.length === 2) {
                      font_labels[arr33[0]] = arr33[1];
                    }
                  });
                  options.font_labels = font_labels;
                } else if (varName === 'font_legend') {
                  let font_legend = {};
                  varValues.forEach(p => {
                    const arr33 = p.split(':');
                    if (arr33.length === 2) {
                      font_legend[arr33[0]] = arr33[1];
                    }
                  });
                  options.font_legend = font_legend;
                }
              }
            }
          }
        });
        if (!data) {
          return;
        }
        _doElementChart(inp, ind);
        _edittype = "canvas";
        _userOpts['chart'] = {
          type: chartType,
          data: data,
          options: options
        };
        delete _edrules["required"];
      } else if (_edittype === 'text' || _edittype === 'textarea') {
        const params = (_htmlList || '').split(';');
        params.forEach(p => {
          const arr = p.split(':');
          if (arr.length === 2) {
            if (_edittype === 'textarea' && arr[0] === 'rows') {
              const val = arr[1];
              let rows = !isNaN(parseInt(val, 10)) ? parseInt(val, 10) : 3;
              if (rows > 100) {
                rows = 100;
              }
              _edopts.rows = rows;
            } else if (arr[0] === 'cols') {
              const val = arr[1];
              let cols = !isNaN(parseInt(val, 10)) ? parseInt(val, 10) : -1;
              if (cols > 150) {
                cols = 150;
              }
              if (cols) {
                _edopts.cols = cols;
              }
            }
          }
        });
      } else if (_edittype === 'richtextarea') {
        const params = (_htmlList || '').split(';');
        params.forEach(p => {
          const arr = p.split(':');
          if (arr.length === 2) {
            if (arr[0].toLowerCase() === 'height') {
              _userOpts.css = {...(_userOpts.css || {}), height: `${arr[1]}`};
            }
          }
        });
      } else if (_edittype === 'helpwindow') {
        _userOpts.helpText = _htmlList || '';
      }
    }
    if (_inputMask) {
      const params = _inputMask.split(';');
      params.forEach(p => {
        const arr = p.split(':');
        if (arr.length === 2 && INPUT_MASK_ATTRIBUTES.indexOf(arr[0]) >= 0) {
          _userOpts[arr[0] === 'pattern' ? 'inputMask' : 'inputMaskPlaceHolder'] = arr[1];
        }
      });
    }
    if (_isGroup) {
      _userOpts["group"] = true;
    }
    if (_isGenerate && _groupFld.length > 0) {
      _userOpts["afterSave"] = _genDynamicGroupAfterSaveFunc(currCM.index, _groupFld);
    }

    if (_edittype !== "") currCM.edittype = _edittype;
    if (_formatter !== "") currCM.formatter = _formatter;
    if (_edittype === 'date') currCM.datefmt = 'd.m.Y';
    if (!$.isEmptyObject(_userOpts)) currCM.useropts = _userOpts;
    if (!$.isEmptyObject(_edopts)) currCM.editoptions = _edopts;
    if (!$.isEmptyObject(_edrules)) currCM.editrules = _edrules;
    if (!$.isEmptyObject(_formatopts)) currCM.formatoptions = _formatopts;

    if (radioOrCheckGroup === false && _edittype !== 'canvas' && _edittype !== 'helpwindow') {
      currCM = _paintInput(inp, ind, currCM);
    }
    colModel.push(currCM);
  });
  pz.columnModel = colModel; // set columnModel
  pz.rules = rules; // set rules
  pz.dynaFields = dynaFields;

  return pz;
};

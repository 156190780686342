var DocumentInit = function (pz, modeDialog, callbackGetData) {
  modeDialog = modeDialog || false;
  var isMobileDevice = $.jdm.isMobileDevice(),
    $c = $('#DocContent'), $cont = $(".tmplPageDoc", $c);
  if ($cont.hasClass("content-empty")) {
    $cont.replaceWith("<div class='row tmplPageDoc'></div>");
    $cont = $(".tmplPageDoc", $c);
    $cont.addClass("dm-document-group");

    pz = checkDocumentVars($cont, pz, isMobileDevice);
    pz = setDefaultVars($cont, pz, isMobileDevice);
  } else {
    pz = setTemplateVars($cont, pz, isMobileDevice);
  }
  $cont.addClass("dm-document").removeClass("tmplPageDoc");
  if (modeDialog) $cont.addClass("dm-document-dialog");
  $(".dm-wizard-tab", $cont).hide();
  $(".sw-toolbar", $cont).hide();
  var $inst = $cont.jqDm({
    url: function (success, failure) {
      callbackGetData(success, failure);
    },
    datatype: "funcjson",
    viewMode: false,
    showErrorPrompts: true,
    cellEdit: true,
    colModel: pz.columnModel,
    cellActions: true,
    cdoc: pz.cdoc,
    iddoc: pz.iddoc,
    controls: pz.rules || [],
    autoFill: true,
    debug: false,
    /**
     *  Event risen  if the row added to the table */
    // afterInsertRow: function (act, rowid, tabn, data) {
    afterInsertRow: function (act, iRow, tabn, data) {

      var ts = $(this)[0];
      var rn = 0;
      // set matrix for hidden tabulates of row
      var rulesDTValidateTab = [];
      if (tabn > 0) {
        rn = ts.p._index[tabn][iRow]
        rulesDTValidateTab = ts.p.controls
          .filter(v => v.c_doc_rowc === "*" && v.expression.indexOf("DTValidateTab") >= 0)
          .map(v => v.expression.replaceAll("DTValidateTab", "").replace("(", "").replace(")", "").replaceAll('"', '').split(","));
        if (ts.p.DTTabulatesHidden[tabn][rn] === undefined || ts.p.DTTabulatesHidden[tabn][rn].length < 1) {
          ts.p.DTTabulatesHidden[tabn][rn] = [];
          var tabNumsDT = [];
          ts.p.colModel.filter(v => v.tabn === tabn && !v.rownum && v?.useropts?.dynTabForm?.secondary !== 0 && v?.useropts?.dynTabForm?.tab > 0)
            .forEach(currCM => {
              if (currCM.useropts.dynTabForm.tab > tabNumsDT.length)
                tabNumsDT[currCM.useropts.dynTabForm.tab - 1] = 0;
            });
          ts.p.DTTabulatesHidden[tabn][rn] = tabNumsDT;
        }
      }

      if (!pz.docLoaded) {
        return;
      }

      if (act.toLowerCase() === 'commit') {
        var env = new ScriptEnv($(this), 0, 0);
        env.autoFillTbParams(App, tabn, data, act);
        env = null;
        // chart update
        const cms = $(this)[0].p.colModel.filter(v => v.tabn === tabn);
        const field = cms.find(cm => cm?.useropts?.chart?.target?.length);
        if (field) {
          field.useropts.chart.target.forEach(v => PaintChart(v));
        }
      } else if (act.toLowerCase() === 'tbrecalc') {
        var env = new ScriptEnv($(this), iRow, 0);
        const cms = $(this)[0].p.colModel.filter(v => v.tabn === tabn);
        cms.forEach((vcurrCM, iCol) => {
          env.autoFillParams({evt: "commit"}, vcurrCM.index, false, tabn, iRow, act);
          if (vcurrCM.useropts?.edittype?.indexOf("select") >= 0) {
            env.autoFillParams({evt: "commit"}, vcurrCM.index, false, tabn, iRow, act);
          } else if (vcurrCM.useropts?.edittype?.indexOf("radiogroup:") >= 0
            || vcurrCM.useropts?.edittype?.indexOf("checkgroup:") >= 0
          ) {

            const valD = data[vcurrCM.index];
            if (typeof (valD) !== 'undefined' && valD !== '') {
              var lstEmpty = '';
              var lstSet = '';
              $(cms).each(function () {
                var _fld = this, _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
                if (_parent) {
                  if (_parent.name === vcurrCM.index) {
                    if (((_parent.value === valD) && vcurrCM.useropts?.edittype?.indexOf("radiogroup:") >= 0)
                      || (((';' + valD + ';').indexOf(';' + _parent.value + ';') >= 0) && vcurrCM.useropts?.edittype?.indexOf("checkgroup:") >= 0)) {
                      lstSet = lstSet + (lstSet === '' ? '' : ';') + _fld.index;
                    } else {
                      lstEmpty = lstEmpty + (lstEmpty === '' ? '' : ';') + _fld.index;
                    }
                  }
                }
              });
              var _arr;
              _arr = lstSet.split(";");
              if (_arr.length > 0) {
                for (var i in _arr) {
                  $(this).setCellValue(iRow, _arr[i], 1);
                }
                _arr = lstEmpty.split(";");
                for (var i in _arr) {
                  $(this).setCellValue(iRow, _arr[i], '');
                }
              }
            }


          }
          // env.autoFillParams({evt: "commit"}, vcurrCM.index, false, tabn, iRow, act);
        });
        env = null;

      } else if (act.toLowerCase() === 'init') {
        var env = new ScriptEnv($(this), iRow, 0);
        const cms = $(this)[0].p.colModel.filter(v => v.tabn === tabn);
        cms.forEach((vcurrCM, iCol) => {
          env.autoFillParams({evt: "commit"}, vcurrCM.index, false, tabn, iRow, act);
        });
        //ig added
        cms.forEach((vcurrCM, iCol) => {
          const valD = data[vcurrCM.index];
          if (typeof (valD) !== 'undefined' && valD !== '') {
            for (var rr = 0; rr < rulesDTValidateTab.length; rr++) {
              var groupsIds = ("" + rulesDTValidateTab[rr][2]).split(";"), tabulate;
              for (var i in groupsIds) {
                tabulate = groupsIds[i] - 1;
                if (tabulate <= ts.p.DTTabulatesHidden[tabn][rn].length) {
                  if (vcurrCM.index === rulesDTValidateTab[rr][0]) {
                    let vvData = valD.split(";")
                    let rr2Find = rulesDTValidateTab[rr][1].split(";")
                    // console.log("point5 ", valD, vcurrCM.index);
                    let foundDTVis = false;
                    for (var vvd = 0; vvd < vvData.length; vvd++) {
                      for (var rr2f = 0; rr2f < rr2Find.length; rr2f++) {
                        if (vvData[vvd] === rr2Find[rr2f]) {
                          // console.log("point5/2 found ", valD, vcurrCM.index);
                          foundDTVis = true;
                        }
                      }
                    }
                    ts.p.DTTabulatesHidden[tabn][rn][rulesDTValidateTab[rr][2] - 1] = (foundDTVis ? 0 : 1);
                  }
                }
              }
            }
          }
        });


        env = null;
      }


    },
    /**
     * Event risen once the row removed from the table */
    afterDeleteRow: function (rowid, tabn, data) {
      // console.log("afterDeleteRow", rowid, tabn, data);

      var env = new ScriptEnv($(this), 0, 0);
      env.autoFillTbParams(App, tabn, data, 'delrow');
      env = null;

      // chart update
      const cms = $(this)[0].p.colModel.filter(v => v.tabn === tabn);
      const field = cms.find(cm => cm?.useropts?.chart?.target?.length);
      if (field) {
        field.useropts.chart.target.forEach(v => PaintChart(v));
      }
    },
    /**
     * Event risen once the cell changed **/
    afterSaveCell: function (cellid, name, val, iTab, iRow, iCol) {
      App.evt = "commit";
      let env = new ScriptEnv($(this), iRow, iCol);
      const colModel = $(this)[0].p.colModel;
      const cm = colModel[iCol];
      if (val !== '') {
        const _func = cm && cm.useropts ? (cm.useropts.afterSave ? cm.useropts.afterSave : null) : null;
        if (_func) {
          eval(_func);
        }
      }
      env.autoFillParams(App, name, val, iTab, iRow, 'saveCell');
      env = null;

      if (cm?.useropts?.chart?.target?.length) {
        cm.useropts.chart.target.forEach(v => PaintChart(v));
      }
    },
    /**
     * Event risen if the data got from the backend **/
    loadComplete: function () {
      (pz.$inst).jqDm("initActionButtons", $cont);
      if (pz.mode === false) { // not editable
        $(this).jqDm('setDocStatus', true);
        var $inst = $(this);
        setTimeout(
          function () {
            App.evt = "init";
            let env = new ScriptEnv($inst, 0, 0);
            env.autoFillParams(App, "all");
            env = null;
            $(".dm-wizard-tab", $cont).show();
            $(".sw-toolbar", $cont).show();
            if (pz.charts?.length) {
              pz.charts.forEach(v => {
                if (v?.options?.pzField) PaintChart(v.options.pzField);
              });
            }
          }, 10);

      } else {
        // Document status = editable
        var $inst = $(this);
        setTimeout(
          function () {
            var $d = $inst[0], tbCnt = $d.p.data.length;
            // Refresh indexes
            for (var tb = 1; tb < tbCnt; tb++) {
              $d.refreshIndex(tb);
            }
            // Init document
            App.evt = "init";
            var env = new ScriptEnv($inst, 0, 0);
            env.autoFillParams(App, "all");

            if (pz.dynamicForm) {
              for (var i = 0; i < $d.p.colModel.length; i++) {
                var fld = $d.p.colModel[i], userOpts = fld.useropts;
                if (fld.tabn === 0) {
                  App.evt = "commit";
                  if (userOpts && userOpts.isGenerate && ("" + ($d.p.data[0][fld.index])).length !== 0) {
                    env.autoFillParams(App, fld.index, true);
                  }
                }
              }
            }
            env = null;
            $(".dm-wizard-tab", $cont).show();
            $(".sw-toolbar", $cont).show();
            if (pz.charts?.length) {
              pz.charts.forEach(v => {
                if (v?.options?.pzField) PaintChart(v.options.pzField);
              });
            }
          }, 10);
      }
      pz.docLoaded = true;
    }
  });
  pz.$inst = $inst;
  pz.callbackGetData = callbackGetData;
  pz.docLoaded = false;
};

(function ($) {
  "use strict";

  $.fn.jqDm = function (pin) {
    if (typeof pin === 'string') {
      var fn = $.jdm.getMethod(pin);
      if (!fn) {
        throw ("jqDm - No such method: " + pin);
      }
      var args = $.makeArray(arguments).slice(1);
      return fn.apply(this, args);
    }
    return this.each(function () {
      if (this.grid) {
        return;
      }

      var p = $.extend(true, {
        debug: false,
        url: "",
        viewMode: true,
        showLoading: true,
        colModel: [],
        cmTabCnt: 0, // Количество резиновых таблиц
        datatype: "json",
        mtype: "GET",
        onCellSelect: null, // (cellId,nm,tmp, iTab, iRow, iCol)
        onInitGrid: null,
        loadComplete: null,
        gridComplete: null,
        loadError: null,
        loadBeforeSend: null,
        afterRestoreCell: null, // (cellId, nm, val, iTab, iRow, iCol)
        afterSaveCell: null, // (cellId, nm, v, iTab, iRow, iCol)
        beforeSaveCell: null, // (cellId, nm, v, iTab, iRow,iCol)
        beforeSubmitCell: null, // (cellId, nm, v, iTab, iRow,iCol)
        afterInsertRow: null, //
        beforeRequest: null,
        beforeProcessing: null,
        editurl: null,//
        postData: {},
        prmNames: {nd: "nd", id: "id", oper: "oper", editoper: "edit", addoper: "add", deloper: "del"},
        cellEdit: false,
        autoencode: false,
        ajaxGridOptions: {},
        data: [],
        _index: [],
        _indexR: [],
        _reader: [],
        idPrefix: "",
        loadtext: "Waiting for the data...",
        cellActions: false,
        autoFill: false, // Авторасчет
        cdoc: "", // Document Code
        controls: [], // List of rules [ { c_doc_rowc, sign, expressio, desciption, } ]
        userOpts: {}, // Additional options
        tabsViewed: [], // wizard tabs which was done
        tabsViewedDt: [], // wizard dynamic table tabs which was done
        DTTabulatesHidden: [], // wizard dynamic table tabs which hidden by DTValidateTab
        _dataChanged: false
      }, $.jdm.defaults, pin || {});

      //moved from V
      const f_click = function (e) {
        const tsLocal = this;
        var el = e.target, p = $(el).attr("p"), inp = (p) ? true : false;
        if ($(el).hasClass('custom-formatter')) {
          el = $(el).parent();
          p = $(el).attr("p"), inp = (p) ? true : false;
        } else if ($(el).hasClass('custom-formatter-element') || $(el).hasClass('custom-formatter-input')) {
          el = $(el).parent().parent();
          p = $(el).attr("p"), inp = (p) ? true : false;
        } else if ($(el).hasClass('custom-range')) {
          el = $(el).parent().parent().parent();
          p = $(el).attr("id"), inp = (p) ? true : false;
        }
        if (!inp) {
          return this;
        }
        if ($(el).get(0).tagName.toLowerCase() === "div" && $(el).hasClass("dm-field")) {
          var rowId = $(el).attr("id"), iCol = ts.getColIndex(p), cm = ts.p.colModel[iCol], iRow = 0,
            iTab = (iCol !== -1) ? cm.tabn : 0, nm = cm.index, editable = cm.editable,
            parent = cm.useropts && cm.useropts.parent ? cm.useropts.parent : null;
          if (!editable || $(el).hasClass("dm-field-readonly")) return;

          if (iTab !== 0) {
            var nrow = ("" + rowId).replace("_" + p, "").replace('_det_', '');
            iRow = ts.p._index[iTab][nrow];
          }

          // For children fields (parent.name) and clear error message
          if (parent) {
            $(ts.p.colModel).each(function () {
              var _fld = this, _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
              if (_parent && _parent.name === parent.name && _fld.index !== nm) {
                $(ts).setCellStatus(iRow, _fld.index);
              }
            });
          }
          // Clear error message for this field
          $(ts).setCellStatus(iRow, nm);

          const cellId = $.jdm.getTbCellIdUI(ts.getRowId(cm.tabn, iRow), cm.index, cm.tabn);
          const cellId2 = (rowId.startsWith("_det_") ? "_det_" : "") + cellId;


          var e = $("#" + cellId2, $(tsLocal)), v2 = cm.tabn === 0 ? ts.p.data[0][nm] : ts.p.data[cm.tabn][iRow][nm],
            cbv = ["Yes", "No"], tmp;

          if (cm.editoptions) {
            cbv = cm.editoptions.value.split(":");
          }
          try {
            tmp = $.unformat.call(ts, $(e), {colModel: cm}, iCol);
          } catch (_) {
            tmp = (cm.edittype && cm.edittype === 'textarea') ? $(e).text() : (cm.edittype === 'range' ? $(e).val : $(e).html());
          }

          v2 = tmp == cbv[1] ? cbv[0] : cbv[1];
          if (cm.edittype === "checkbox" && ((cm.formatter.startsWith("rdDMGroup") && v2 === cbv[0]) || !cm.formatter.startsWith("rdDMGroup"))) {
            // prevent to select more than maxSelected options
            let valid = true;
            if (v2 === '1' && cm.useropts?.parent?.name) {
              const parName = cm.useropts?.parent?.name;
              const pos = ts.getColIndex(parName),
                parFld = pos !== -1 ? ts.p.colModel[pos] : null;
              if (parFld && parFld?.editrules?.maxSelected && parFld?.useropts?.edittype?.startsWith('checkgroup')) {
                const parVal = $(ts).jqDm("getCellValue", iRow, parFld.index);
                const edtrul = parFld.editrules;
                if (!($.jdm.isEmpty(parVal))) {
                  if (parVal.split(';').length >= edtrul.maxSelected) {
                    window.setTimeout(function () {
                      $.jdm.info_dialog($.jdm.errors.errcap, $.jdm.edit.msg.maxSelected.replace('%MAX_SELECTED%', edtrul.maxSelected));
                    }, 10);
                    valid = false;
                  }
                }
              }
            }
            if (valid) {
              ts.p._dataChanged = true;
              if ($.isFunction(ts.p.onCellSelect)) {
                ts.p.onCellSelect.call(ts, cellId2, nm, v2, iTab, iRow, iCol);
              }

              $(ts).jqDm("setCell", cellId2, iRow, iCol, v2, true);
              if (rowId.startsWith("_det_")) { //parent tbale refresh too
                $(ts).jqDm("setCell", cellId, iRow, iCol, v2, true);
              }
              if ($.isFunction(ts.p.afterSaveCell)) {
                ts.p.afterSaveCell.call(ts, cellId2, nm, v2, iTab, iRow, iCol);
              }
            }
            $(e).closest("td").removeClass("edit-cell");
          } else if (cm.edittype === "range" && cm.formatter.startsWith("range")) {
            e = $("#" + cellId, $(e));
            ts.p._dataChanged = true;
            v2 = e.val();
            if ($.isFunction(ts.p.onCellSelect)) {
              ts.p.onCellSelect.call(ts, cellId, nm, v2, iTab, iRow, iCol);
            }
            $(ts).jqDm("setCell", cellId, iRow, iCol, v2, true);
            if ($.isFunction(ts.p.afterSaveCell)) {
              ts.p.afterSaveCell.call(ts, cellId, nm, v2, iTab, iRow, iCol);
            }
            $(e).closest("td").removeClass("edit-cell");
          }
        }
      };

      var ts = this, grid = {loading: false}, // Initianlization column model fields
        initCMFieldAsDate = function (inp) {
          inp.editoptions = inp.editoptions || {};
          inp.editoptions = {dataInit: $.jdm.attachEdDate};
          inp.editrules = inp.editrules || {};
          inp.editrules.date = true;

          return inp;
        },
        initCMFieldAsTime = function (inp) {
          inp.editoptions = inp.editoptions || {};
          inp.editoptions = {dataInit: $.jdm.attachEdTime};

          return inp;
        },
        initCMFieldAsShortTime = function (inp) {
          inp.editoptions = inp.editoptions || {};
          inp.editoptions = {dataInit: $.jdm.attachEdShortTime};

          return inp;
        },
        initCMFieldAsNumber = function (inp) {
          var _edopts = inp.editoptions || null, _dataEvents = _edopts ? _edopts.dataEvents || [] : [];
          _dataEvents.push({
            type: 'keypress', fn: function (event) {
              if (event.which != 45) {
                if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
                  event.preventDefault();
                  return false;
                }
              }
            }
          });
          if (_dataEvents.length > 0) {
            inp.editoptions = inp.editoptions || {};
            inp.editoptions.dataEvents = _dataEvents;
          }
          return inp;
        },
        initCMFieldAsInteger = function (inp) {
          var _edopts = inp.editoptions || null, _dataEvents = _edopts ? _edopts.dataEvents || [] : [];
          _dataEvents.push({
            type: 'keypress', fn: function (event) {
              if ((event.which < 48 || event.which > 57)) {
                event.preventDefault();
                return false;
              }
            }
          });
          if (_dataEvents.length > 0) {
            inp.editoptions = inp.editoptions || {};
            inp.editoptions.dataEvents = _dataEvents;
          }

          return inp;
        },
        initCMFieldAsOutput = function (inp) {
          inp.edittype = "custom";
          inp.editoptions = inp.editoptions || {};
          inp.editoptions.custom_element = function (val, opts) {
            var el = document.createElement("div");
            $(el)
              .attr("style", "opacity:1; overflow:auto!important;")
              .attr('nowrap', 'true')
              .html("<span class='output-status'></span>");

            return el;
          };
          inp.editoptions.custom_value = function (elem, oper, val) {
            var $in = $('.output-status', $(elem));
            if (oper === 'set') {
              $in.html(val);
            }
            return JSON.stringify($in.html());
          };
          return inp;
        },
        initCMFieldAsDict = function (inp) {
          inp.edittype = "custom";
          inp.editoptions = inp.editoptions || {};
          inp.editoptions.custom_element = function (val, opts) {
            var $t = $(this)[0], cm = $t.p.colModel, pos = $t.getColIndex(opts.name), fld = pos !== -1 ? cm[pos] : {},
              useropts = fld.useropts || {};
            var _dct = {
              dct: useropts['dctName'], field: useropts['dctField'], group: useropts['dctGroup']
            };
            if (useropts['dctFilter'] !== undefined) {
              _dct['filter'] = useropts['dctFilter'];
            }
            var el = document.createElement("div");
            $(el)
              .attr("style", "opacity:1;")
              .attr('nowrap', 'true')
              .html("<input type='text' class='inp-dct custom-form-control form-control form-control-sm' style='width:100%;' />" + "<div title='Show a dictinary browser' class='dct-browse ui-dctinput-browse'>&nbsp;</div>" + "<div title='Clear the input' class='dct-clear ui-dctinput-clear'>&nbsp;</div>");
            var $in = $('input', $(el));
            $in.val(val);
            $('.dct-browse', $(el)).click(function () {
              $.jdm.attachDctBrowse($($t), _dct, $in, opts);
            });
            $('.dct-clear', $(el)).click(function () {
              $in.val("");
              $in.trigger("change");
            });
            return el;
          };
          inp.editoptions.custom_value = function (elem, oper, val) {
            if (oper === 'set') $('.inp-dct', elem).val(val);
            return $('.inp-dct', elem).val();
          };
          return inp;
        },
        initCMFieldMask = function (inp) {
          inp.editoptions = inp.editoptions || {};
          inp.editoptions = {...inp.editoptions, dataInit: $.jdm.attachEdMask};
          inp.editrules = inp.editrules || {};
          inp.editrules.masked = true;
          return inp;
        },
        _initCMField = function (inp) {
          var _useropts = inp.useropts || {}, _edrules = inp.editrules || null;
          const _editoptions = inp.editoptions;
          if (_useropts.edittype === 'dict') {
            if (_useropts.dctName && _useropts.dctField) {
              inp = initCMFieldAsDict(inp);
            }
          } else if (_useropts.edittype === "status") {
            inp = initCMFieldAsOutput(inp);
          } else if ((_useropts.edittype === 'date') || (_edrules && _edrules.date)) {
            inp = initCMFieldAsDate(inp);
          } else if (_useropts.datatype && _useropts.datatype === "TIME") {
            inp = initCMFieldAsTime(inp);
          } else if (_useropts.type && _useropts.type === "TIME") {
            inp = initCMFieldAsShortTime(inp);
          } else if (_useropts.edittype === 'text' && _useropts.inputMask) {
            inp = initCMFieldMask(inp);
          }

          if (_useropts.edittype?.startsWith('checkgroup') && _editoptions?.maxlength) {
            inp.editrules = inp.editrules || {};
            inp.editrules.maxSelected = _editoptions?.maxlength;
          }

          if (_edrules) {
            if (_edrules.integer) inp = initCMFieldAsInteger(inp);
            if (_edrules.number) inp = initCMFieldAsNumber(inp);
          }
          return inp;
        }, // check
        _chkUIEditor = function (inp, field, i) {
          var tabn = field.tabn;
          if (tabn !== 0) {
            $.jdm.initTbTmplUI(inp, tabn);
          }
          $(inp)
            .removeClass("fc").addClass("dm-field")
            .removeAttr("id").removeAttr("dict")
            .removeAttr("cmrow").attr("cmrow", i).removeAttr("p").attr("p", field.index)
            .removeAttr("ld").removeAttr("fmt").removeAttr("tabn").removeAttr("ed").removeAttr("dt")
            .removeAttr("ma").removeAttr("ro").removeAttr("di").removeAttr("vt").removeAttr("tab")
            .removeAttr("storecmb").removeAttr("outputfld").removeAttr("displayfld").removeAttr("valuefld");
          if ($(inp).closest("td").children().length !== 1) {
            $(inp).closest("td").addClass("dm-cell-multiels");
          } else {
            var txtHtml = $(inp).parent().html(), par = $(inp).parent(), els = par.children();
            if (par.prop('tagName').toLowerCase() !== 'td') {
              for (var i = 0; i < els.length; i++) {
                txtHtml = txtHtml.replace(els[i].outerHTML, '');
              }
              if (txtHtml.length !== 0) $(inp).closest("td").addClass("dm-cell-multiels");
            }
          }

          if (tabn !== 0) {
            $(inp).attr("tabn", tabn);
          } else {
            $(inp).attr("id", field.index);
            $(inp).closest("td").addClass("dm-tblcell");
            $(inp).closest("tr").addClass("dm-tblrow");

            _attachFieldEditor.call(ts, $(inp), field);
          }
        },
        _getRowId = function (tabn, rn) {
          return (tabn !== 0) ? ((isNaN(rn)) ? rn : ts.p._indexR[tabn][rn]) : null;
        },
        _getRowNum = function (tabn, rn) {
          return (tabn !== 0) ? ((isNaN(rn)) ? ts.p._index[tabn][rn] : parseInt(rn, 10)) : 0;
        },
        _getColIndex = function (col) {
          let ret;
          if (isNaN(col)) {
            ret = ts.p.colModel.findIndex((v) => v.index === col);
          } else {
            ret = parseInt(col, 10);
          }
          return ret;
        },
        _getFieldValue = function (field, cm) {
          var v, v2;
          switch (cm.edittype) {
            case "select":
              if (!cm.editoptions.multiple) {
                v = $("option:selected", field).val();
                v2 = $("option:selected", field).text();
              } else {
                var selectedText = [];
                v = field.val();
                if (v) {
                  v = v.join(",");
                } else {
                  v = "";
                }
                $("option:selected", field).each(function (i, selected) {
                  selectedText[i] = $(selected).text();
                });
                v2 = selectedText.join(",");
              }
              if (cm.formatter) {
                v2 = v;
              }
              break;
            case "checkbox":
              var cbv = ["Yes", "No"];
              if (cm.editoptions) {
                cbv = cm.editoptions.value.split(":");
              }
              v = field.is(":checked") ? cbv[0] : cbv[1];
              v2 = v;
              break;
            case "text":
            case "textarea":
            case "richtextarea":
            case "range":
              v = field.val();
              v2 = v;
              break;
            case "file":
              v = field.val();
              var arrItems = v.split("\\");
              if (arrItems.length > 0) {
                v = arrItems[arrItems.length - 1];
              }
              v2 = v;
              break;
            case "orderlist":
              break;
            case 'custom' :
              try {
                if (cm.editoptions && $.isFunction(cm.editoptions.custom_value)) {
                  v = cm.editoptions.custom_value.call($(ts), field, 'get'); // cc
                  if (v === undefined) {
                    throw "e2";
                  } else {
                    v2 = v;
                  }
                } else {
                  throw "e1";
                }
              } catch (e) {
                if (e === "e1") {
                  $.jdm.info_dialog($.jdm.errors.errcap, "function 'custom_value' " + $.jdm.edit.msg.nodefined, "error");
                }
                if (e === "e2") {
                  $.jdm.info_dialog($.jdm.errors.errcap, "function 'custom_value' " + $.jdm.edit.msg.novalue, "error");
                } else {
                  $.jdm.info_dialog($.jdm.errors.errcap, e.message, "error");
                }
              }
              break;
          }
          return {v: v, v2: v2};
        },
        _setFieldValue = function ($elem, field, val, fmtVal) {
          $elem.each(function () {
            var $fld = $(this), tagNm = $fld.get(0).tagName;
            if (tagNm.toLowerCase() === "div") {
              if ($fld.hasClass("customelement")) {
                try {
                  if (field.editoptions && $.isFunction(field.editoptions.custom_value)) {
                    field.editoptions.custom_value.call($(ts), $fld, 'set', val); // cc
                  } else {
                    throw "e1";
                  }
                } catch (e) {
                  if (e === "e1") {
                    $.jdm.info_dialog($.jdm.errors.errcap, "function 'custom_value' " + $.jdm.edit.msg.nodefined, "error");
                  } else {
                    $.jdm.info_dialog($.jdm.errors.errcap, e.message, "error");
                  }
                }
              } else {
                $fld.html(fmtVal);
              }
            } else if (tagNm.toLowerCase() === "select") {
              if (field.editoptions && field.editoptions.multiple) {
                var ovm = (val + '').split(",");
                ovm = $.map(ovm, function (n) {
                  return $.trim(n);
                });
                $fld.val(ovm);
              } else {
                if (field.edittype === "select" && ("" + val).length > 0) {
                  if ($("option[value=\"" + val + "\"]", $fld).length === 0) {
                    const ov = $("<option>");
                    $fld.append(ov);
                    ov.val(val).text(val);
                    if (!field.useropts['dctName']) {
                      ov.addClass("errorSelectOption");
                      $fld.addClass("errorSelectObject");
                    }
                  }
                }
                $fld.val(val);
              }
              try {
                if ($fld.hasClass("selectpicker")) $fld.selectpicker("refresh");
              } catch (_) {
              }
            } else if (tagNm.toLowerCase() === "input" && $fld.get(0).type === "file") {

            } else if (tagNm.toLowerCase() === "orderlist" && $fld.get(0).type === "orderlist") {

            } else {
              $fld.val($.jdm.htmlDecode(fmtVal));
            }
          });
        },
        _saveField = function (iRow, cm, isdet) {
          //ig added
          const dynTabCmp = $('#dynTabRowEdit');
          var $t = ts, iCol = $t.getColIndex(cm.index), nm = cm.index, iTab = cm.tabn,
            cellId = $.jdm.getTbCellIdUI($t.getRowId(iTab, iRow), nm, iTab);
          if (!!dynTabCmp && (isdet === 1)) {
            cellId = "_det_" + cellId;
          }

          var field = $("#" + cellId),
            cc = field.closest(),
            rval = _getFieldValue(field, cm),
            v = rval["v"], v2 = rval["v2"]

          if ($.isFunction($t.p.beforeSaveCell)) {
            var vv = $t.p.beforeSaveCell.call($t, cellId, nm, v, iTab, iRow, iCol);
            if (vv) {
              v = vv;
              v2 = vv;
            }
          } else {
            var edtrul = cm.editrules;
            if (edtrul) {
              if (edtrul.number === true) {
                v = ("" + v).replace(",", ".");
                v2 = v;
              }
            }
          }
          var cv = $.jdm.checkValues.call($t, cellId, v, iCol);
          if (cv[0] === true) {
            if ($.isFunction($t.p.beforeSubmitCell)) {
              $t.p.beforeSubmitCell.call($t, cellId, nm, v, iTab, iRow, iCol);
            }
            if ($("input.hasDatepicker", cc).length > 0) {
              $("input.hasDatepicker", cc).datepicker('hide');
            }
            v = cm.formatter && typeof cm.formatter === 'string' && cm.formatter === 'date' ? $.unformat.date.call($t, v, cm) : v;
            if (isNaN(iTab) || ($t.p.data.length < 1) || ($t.p.data.length < (iTab + 1))
              || ($t.p.data[iTab] === undefined)) { //ig added
              // console.log("_setFieldValue: broken data, not set value[6]. iTab,nm, v, len, $t.p.data ", iTab, nm, v, $t.p.data.length, $t.p.data)
              console.log("_setFieldValue: broken data, not set value[6].");
            } else {
              if (iTab === 0) {
                $t.p.data[0][nm] = v;
              } else {
                $t.p.data[iTab][iRow][nm] = v;
              }
            }
            _setFieldValue(field, cm, v, formatter(cellId, v, iCol, (iTab === 0 ? $t.p.data[0] : $t.p.data[iTab][iRow]), 'edit'));

            $(cc).closest("tr").addClass("edited");
            if ($.isFunction($t.p.afterSaveCell)) {
              $t.p.afterSaveCell.call($t, cellId, nm, v, iTab, iRow, iCol);
            }
          } else {
            try {
              v = cm.formatter && typeof cm.formatter === 'string' && cm.formatter === 'date' ? $.unformat.date.call($t, v, cm) : v;
              if (isNaN(iTab) || ($t.p.data.length < 1) || ($t.p.data.length < (iTab + 1))
                || ($t.p.data[iTab] === undefined)) { //ig added
                // console.log("_setFieldValue: broken data, not set value[5]. iTab,nm, v, len, $t.p.data ", iTab, nm, v, $t.p.data.length, $t.p.data)
                console.log("_setFieldValue: broken data, not set value[5].");
              } else {
                if (iTab === 0) {
                  $t.p.data[iTab][nm] = v;
                } else {
                  $t.p.data[iTab][iRow][nm] = v;
                }
              }
              _setFieldValue(field, cm, v, formatter(cellId, v, iCol, (iTab === 0 ? $t.p.data[0] : $t.p.data[iTab][iRow]), 'edit'));

              window.setTimeout(function () {
                $.jdm.info_dialog($.jdm.errors.errcap, cv[1]);
              }, 100);
            } catch (e) {
            }
          }
        },
        _bindFieldEvents = function ($inp) {
          var tagNm = $inp.get(0).tagName;
          if (tagNm.toLowerCase() === "div") {
            if ($inp.hasClass("customelement")) {
              $(".custom-form-control", $inp).on("change", function () {
                var id = $inp.attr("id"), p = $inp.attr("p"), iCol = _getColIndex(p), fld = ts.p.colModel[iCol] || {};
                if (fld.tabn === 0) {
                  _saveField(0, fld, 0);
                } else {
                  var nrow = ("" + id).replace("_" + p, "");
                  _saveField(ts.p._index[fld.tabn][nrow], fld, 0);
                }
                ts.p._dataChanged = true;
              });
            }
          } else {
            $inp.on("focus", function (e) {
              var id = $inp.attr("id"), p = $inp.attr("p"), iCol = _getColIndex(p), fld = ts.p.colModel[iCol] || {},
                rowId, tdHtml = _getFieldValue($inp, fld), iRow = 0;
              if (fld.tabn === 0) {
                rowId = p;
              } else {
                rowId = ("" + id).replace("_" + p, "");
                iRow = ts.p._index[fld.tabn][rowId];
              }

              $.jdm.removePrompt($inp);
              $inp.removeClass('dm-value-error').removeAttr('error');

              if ($.isFunction(ts.p.onCellSelect)) {
                ts.p.onCellSelect.call(ts, rowId, fld.name, tdHtml["v"], fld.tabn, iRow, iCol);
              }

              return false;
            });
            $inp.on("change", function (e) {
              var id = $inp.attr("id"), p = $inp.attr("p"), iCol = _getColIndex(p), fld = ts.p.colModel[iCol] || {};
              // only for editType = "select"
              if (fld.edittype === "select") {
                var _vl = $inp.val(), $opt = $("option[value='" + _vl + "']", $inp);
                // if user enters a value is not with class = errorSelectOption
                if (!$opt.hasClass("errorSelectOption")) {
                  // remove class errorSelectObject
                  $inp.removeClass("errorSelectObject");
                  // remove class errorSelectOption for these options
                  $("option.errorSelectOption", $inp).remove();
                }
              }
              var v = $inp.val(), edtrul = fld.editrules || {};
              if (typeof (v) !== 'undefined') {
                if (edtrul.number === true) {
                  var fmtopts = fld.formatoptions || {};
                  if (fmtopts.decimalPlaces) {
                    v = parseFloat(v);
                    if (isNaN(v)) {
                      $inp.val("");
                    } else {
                      $inp.val(v.toFixed(fmtopts.decimalPlaces));
                    }
                  }
                } else if (edtrul.integer === true) {
                  v = parseInt(v, 10);
                  if (isNaN(v)) {
                    $inp.val("");
                  } else {
                    $inp.val(v);
                  }
                }
              } else { // typeof(v) == 'undefined'
                $inp.val("");
              }
              ts.p._dataChanged = true;
              if (fld.tabn === 0) {
                _saveField(0, fld, 0);
              } else {
                // var nrow = ("" + id).replace("_" + p, "");
                var nrow = ("" + id).replace("_" + p, "").replace('_det_', '');
                _saveField(ts.p._index[fld.tabn][nrow], fld, id.startsWith("_det_") ? 1 : 0);
              }

              return false;
            });
          }
        },
        _cbDTParseLog = function ($inst, logs, iTab, iRow) {
          $inst.jqDm("resetTableEditStatus", iTab, iRow);
          if (logs.length > 0) {
            const errFieldNames = logs.map(v => v.field);
            const tableCols = $inst[0].p.colModel
              .filter(v => v.tabn !== 0 && !v.rownum && !v.hidden && v?.useropts?.dynTabForm?.secondary !== 0 &&
                errFieldNames.indexOf(v.index) >= 0);

            logs.filter(v => tableCols.find(t => t.index === v.field))
              .map(v => ({rn: v.pos, tabn: tableCols.find(t => t.index === v.field).tabn}))
              .forEach(v => $inst.jqDm("setTableEditStatus", v.tabn, v.rn, "error"));
            var r, rnum, tp, type, chk;
            for (var i in logs) {
              r = logs[i];
              rnum = r.pos;
              tp = r.level;
              type = "";
              switch (tp) {
                case 1:
                  type = "Integrity check";
                  break;
                case 2:
                  type = "Inspection check";
                  break;
                case 3:
                  type = "XSD check";
                  break;
              }
              //ig added error type to params
              chk = pz.$inst.jqDm("setCellStatus", rnum, r.field, "error", "<b>" + type + "</b>: " + r.msg, r?.errorType, r?.grpType);
              if (chk === -2) {// for complex control like radio
                var cm = $inst[0].p.colModel;
                $(cm).each(function () {
                  var _fld = this, _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
                  if (_parent) {
                    if (_parent.name === r.field) {
                      chk = 0;
                      //ig added error type to params
                      pz.$inst.jqDm("setCellStatus", rnum, _fld.index, "error", "<b>" + type + "</b>: " + r.msg, r?.errorType, r?.grpType);
                      return true;
                    }
                  }
                });
              }
            }
          }
        },

        _attachFieldAction = function ($div, rowid, tabn) {
          $div.on("click", function () {
            var $o = $(this);
            if ($o.hasClass("jdm-row-add")) {
              $(ts).jqDm("addRowData", tabn, {}, "after", rowid);
            } else if ($o.hasClass("jdm-row-copy")) {
              var rdata = $(ts).jqDm("getRowData", tabn, rowid);
              $(ts).jqDm("addRowData", tabn, rdata, "end", rowid);
            }
            if ($o.hasClass("jdm-row-del")) {
              $.jdm.confirm("Confirmation", "Are you sure you want to remove this item?", function () {
                $(ts).jqDm("delRowData", tabn, rowid);
                var ln = $(ts).jqDm("getTabLen", tabn);
                if (ln === 0) {
                  $(ts).jqDm("addRowData", tabn, {}, "first");
                }
              }, function () {
              });
            } else if ($o.hasClass("jdm-row-edit")) {
              //param1 callback for show
              //param2 callback for hide
              $.jdm.showRowEditDialog(() =>
                  //callback for show
                {
                  let ts = pz.$inst[0];

                  // object of content of  'Details' window  to edit one row
                  const dynTabCmp = $('#dynTabRowEdit');
                  if (!dynTabCmp) {
                    return;
                  }
                  const rdata = $(ts).jqDm("getRowData", tabn, rowid);

                  // clear fields on document form row ('Details' window )
                  $(dynTabCmp).empty();

                  // draw group  tabulation on upper on form
                  const tabCnt = pz.groups.filter(v => v.tabn === tabn).length;
                  if (tabCnt > 0) {
                    $(dynTabCmp).append("<div class='col-12 dm-wizard'></div>");
                    $('.dm-wizard', $(dynTabCmp)).append("<ul class='dm-wizard-tab'></ul>");
                    pz.groups.filter(v => v.tabn === tabn).forEach(grp => {
                      $('.dm-wizard-tab', $(dynTabCmp)).append("<li><a href='#tableStep" + grp.grpType + "'>" + grp.grpShortname + "</a></li>");
                    });
                    $('.dm-wizard', $(dynTabCmp)).append("<div class='dm-wizard-body'></div>");
                    pz.groups.filter(v => v.tabn === tabn).forEach(grp => {
                      $('.dm-wizard-body', dynTabCmp).append("<div id='tableStep" + grp.grpType + "'></div>");
                    });
                    $(".dm-wizard", $(dynTabCmp)).smartWizard({
                      selected: 0,
                      transitionEffect: 'fade',
                      transitionSpeed: '400',
                      keyNavigation: false,
                      contentCache: false,
                      showStepURLhash: false,
                      toolbarSettings: {
                        toolbarPosition: 'none'
                      },
                      anchorSettings: {
                        enableAllAnchors: true
                      }
                    });
                  }
                  $(".dm-wizard", $(dynTabCmp)).on("showStep", function (e, anchorObject, stepNumber, stepDirection, stepPosition) {
                    var $inst = pz.$inst, editable = $inst.jqDm("getDocStatus");
                    if (editable) {
                      $.jdm.save();
                      let steps = [];
                      //ig may be error here
                      // $(".dm-wizard li.nav-item.danger a.nav-link, .dm-wizard li.nav-item.done a.nav-link, .dm-wizard li.nav-item a.nav-link", $(dynTabCmp))
                      $(".dm-wizard li.nav-item.danger a.nav-link, .dm-wizard li.nav-item.done a.nav-link", $(dynTabCmp))
                        .each(function () {
                          const v = this;
                          const href = v.href || '';
                          steps.push(+href.substring(href.indexOf('#tableStep') + 10));
                        });
                      $inst[0].p.tabsViewedDt = steps;
                      if (steps.length) {

                        const res = $inst.jqDm("check", undefined, tabn, rowid);
                        _cbDTParseLog($inst, res.rows, tabn, rowid);
                        const errorsSteps = Array.from(new Set(res.rows.filter(v => v?.dynTab >= 0 && steps.indexOf(v?.dynTab) >= 0)
                          .map(v => v.dynTab - 1)));
                        $inst.jqDm("wizardHighlight", errorsSteps, tabn, rowid);
                      }
                    }
                  });


                  //rename id of each element to '<ID>_tmp' why?
                  ts.p.colModel.filter(v => v.tabn === tabn && !v.rownum && (!v.hidden || v?.useropts?.childGroup) && v.edittype !== 'checkbox' && v?.useropts?.dynTabForm?.secondary !== 0)
                    .forEach(currCM => {
                      const cellId = $.jdm.getTbCellIdUI(rowid, currCM.index);
                      $('#' + cellId, $(ts)).attr('id', cellId + '_tmp');
                    });

                  // generate form for each of tab of window 'Details'
                  ts.p.colModel.filter(v => v.tabn === tabn && !v.rownum && (!v.hidden || v?.useropts?.childGroup) && v.edittype !== 'checkbox' && v?.useropts?.dynTabForm?.secondary !== 0)
                    .forEach(currCM => {
                      const colpos = _getColIndex(currCM.index);

                      // reference to tab window 'Details'
                      const parCmp = tabCnt > 0 ? $('#tableStep' + (currCM?.useropts?.dynTabForm?.tab || 1), $(dynTabCmp)) : dynTabCmp;
                      const editType = currCM?.useropts?.edittype;
                      let inpHtml = '';
                      const isRadioGroup = editType.startsWith('radiogroup') || editType.startsWith('checkgroup');
                      if (isRadioGroup) {
                        const cssGroupAnswers = "field-group-answers-" + (editType.indexOf(":vertical") !== -1 ? "vertical" : "horizontal");
                        ts.p.colModel.filter(v => v.tabn === tabn && v?.useropts?.parent?.name === currCM.index)
                          .forEach(v => {
                            inpHtml += "<li>" + "<div class='fc' p='" + v.index + "'></div>" + "</li>";
                          });
                        inpHtml = "<ul class='" + cssGroupAnswers + "'>" + inpHtml + "</ul>";
                      } else {
                        inpHtml = "<div class='fc' p='" + currCM.index + "' style='width:99%!important;'></div>";
                      }
                      $(parCmp).append("<div class='form-row form-group field-group'>" + "<div class='col-12 col-md-6 field-label'>" + "<span class='jdm-fldlabel jdm-fld-" + (currCM.index) + (currCM?.editrules?.required ? ' required' : '') + "'>" + "&nbsp;" + currCM.label + "</span>" + (currCM.hint ? "<i class='jdm-fldhint far fa-question-circle' onmouseover=\"$.jdm.buildPromptHint(this, '" + currCM.hint + "')\"></i>" : "") + "</div>" + "<div class='col-12 col-md-6 field-value'>" + inpHtml + "</div>" + "</div>");
                      let inps = [];
                      if (isRadioGroup) {
                        ts.p.colModel.filter(v => v.tabn === tabn && v?.useropts?.parent?.name === currCM.index)
                          .forEach(v => inps.push($("div.fc[p='" + v.index + "']", $(dynTabCmp))[0]));
                      } else {
                        inps.push($("div.fc[p='" + currCM.index + "']", $(dynTabCmp))[0]);
                      }
                      inps.forEach(inp => {
                        const index = $(inp).attr('p');
                        const cm = ts.p.colModel.find(v => v.index === index);
                        const cpos = _getColIndex(cm.index);
                        //it risk to set there wo check cm.rownum=ts.p._index[tabn][rowid];
                        const cellId = '_det_' + $.jdm.getTbCellIdUI(rowid, index);
                        $(inp).attr("tabn", tabn);
                        if (isRadioGroup) {
                          _chkUIEditor($(inp), cm, cpos);
                        }
                        _attachFieldEditor.call($(parCmp), $(inp), cm, tabn > 0 ? ts.p._index[tabn][rowid] : 0); // why cm ??? rn need
                        const newEl = $(".dm-field[p=" + index + "]", $(dynTabCmp));
                        newEl.attr("id", cellId);
                        _setFieldValue.call($(parCmp), newEl, cm.index, rdata[cm.index], formatter(cellId, rdata[cm.index], cpos, rdata, 'edit'));
                      });
                      //ig added
                      $('#dynTabRowEdit .form-row .field-value').click(f_click)
                        .bind('mousedown', function (event) {
                          if (event && event.button && event.button == 2) {
                            event.preventDefault();
                          }
                        });
                    });
                  (pz.$inst).jqDm("initActionButtons", $(dynTabCmp));
                  //ig added
                  recalculateTabData(tabn, rowid, rdata);
                  // to paint error status when opened  roweditor
                  const res = pz.$inst.jqDm("check", undefined, tabn, rowid);
                  _cbDTParseLog(pz.$inst, res.rows, tabn, rowid);
                },
                //callback for hide dynamic roweditor
                () => {
                  const dynTabCmp = $('#dynTabRowEdit');
                  if (!dynTabCmp) {
                    return;
                  }
                  var $inst = pz.$inst
                  const res = $inst.jqDm("check", undefined, tabn, rowid);
                  _cbDTParseLog($inst, res.rows, tabn, rowid);
                  $(dynTabCmp).empty();
                  // get row columns
                  const rdata = $(ts).jqDm("getRowData", tabn, rowid);
                  ts.p.colModel.filter(v => v.tabn === tabn && !v.rownum && (!v.hidden || v?.useropts?.childGroup) && v.edittype !== 'checkbox' && v?.useropts?.dynTabForm?.secondary !== 0)
                    .forEach(currCM => {
                      const colpos = _getColIndex(currCM.index);
                      const cellId = $.jdm.getTbCellIdUI(rowid, currCM.index);
                      const inp = $('#' + cellId + '_tmp', $(ts));
                      $('#' + cellId + '_tmp', $(ts)).attr('id', cellId);
                      _setFieldValue.call($(ts), inp, currCM.index, rdata[currCM.index], formatter(cellId, rdata[currCM.index], colpos, rdata, 'edit'));
                    });

                });
            }
          });
        },
        _attachFieldEditor = function ($div, field, rn) {
          rn = 'undefined' === typeof (rn) ? 0 : rn;
          var ro = (field.editable !== undefined) ? (field.editable === false) : false,
            ma = (field.editrules !== undefined) ? ((field.editrules.required !== undefined) ? field.editrules.required : false) : false,
            dataType = "dm-field-default", inputWidth = "99%";
          if (field.editrules !== undefined) {
            if (field.editrules.integer) dataType = "dm-field-integer";
            if (field.editrules.number) dataType = "dm-field-number";
            if (field.editrules.date) {
              dataType = "dm-field-date";
            }
          } else {
            var _useropts = field.useropts || {};
            if ((_useropts.datatype && _useropts.datatype === "TIME") || _useropts.type && _useropts.type === "TIME") {
              dataType = "dm-field-time";
            }
          }
          if (field.edittype === "canvas") {
            let opt = $.extend({}, field.editoptions || {}, {
              name: field.index, tabn: field.tabn, rn: rn, useropts: field.useropts
            });
            if (ro) {
              opt["disabled"] = "disabled";
            }
            if (ma) {
              opt["required"] = "";
            }
            const elc = $.jdm.createEl.call($(ts), field.edittype, opt, "");
            $(elc)
              .attr("style", "width:" + inputWidth + ";")
              .attr("p", field.index)
              .attr("id", field.index);
            $div.replaceWith(elc);
            $(elc).addClass("dm-field").addClass(dataType);
          } else if (field.edittype === "range") {
            let opt = $.extend({}, field.editoptions || {}, {
              name: field.index, tabn: field.tabn, rn: rn, useropts: field.useropts
            });
            if (ro) {
              opt["disabled"] = "disabled";
            }
            if (ma) {
              opt["required"] = "";
            }
            const elc = $.jdm.createEl.call($(ts), field.edittype, opt, "");
            $(elc)
              .attr("style", "width:" + inputWidth + ";")
              .attr("p", field.index)
              .attr("id", field.index);
            $div.replaceWith(elc);
            $(elc).addClass("dm-field").addClass(dataType);
          } else if (field.edittype !== "checkbox") {
            var opt = $.extend({}, field.editoptions || {}, {
              name: field.index, tabn: field.tabn, rn: rn, useropts: field.useropts
            });
            if (ro) {
              opt["disabled"] = "disabled";
            }
            if (ma) {
              opt["required"] = "";
            }
            var elc = $.jdm.createEl.call($(ts), field.edittype, opt, "", $.extend({}, $.jdm.ajaxOptions, ts.p.ajaxSelectOptions || {}));
            var $par = $div.parent();
            if ($par.hasClass("grid-value-full")) {
              inputWidth = "95%!important";
            }
            if (opt.cols && (field.edittype === 'text' || field.edittype === 'textarea')) {
              $(elc)
                .attr("style", "width: unset; max-width:" + inputWidth + ";")
                .attr(field.edittype === 'text' ? 'size' : 'cols', opt.cols);
            } else {
              $(elc).attr("style", "width:" + inputWidth + ";")
            }
            if (field.useropts?.css) {
              $(elc).css(field.useropts.css);
            }

            $(elc).attr("p", field.index);
            if (field.tabn === 0) {
              $(elc).attr("id", field.index);
            }
            $div.replaceWith(elc);
            $(elc).addClass("dm-field").addClass(dataType);
            var tagNm = $(elc).get(0).tagName;
            if (tagNm.toLowerCase() !== "div" && field.edittype !== "range") {
              $(elc).addClass("form-control-sm").addClass("form-control");
            }
            $.jdm.bindEv.call($(ts), elc, opt);
            if (field?.editrules?.masked) {
              field.editrules.pattern = $(elc).maskRegex();
            }
            _bindFieldEvents.call($(ts), $(elc));
          }
        },
        _uiAddActionsFields = function () {
          $("tr.dm-tbl-template", $(ts)).each(function () {
            var $cell = $(this).find(".row-template-actions");
            if ($cell.length === 0) $cell = $(this).find('td:first');
            var _html = $cell.html(), el = document.createElement("div");
            $(el)
              .attr("style", "opacity: 1; width:100%; margin: 0 auto; display: flex;")
              .attr('nowrap', 'true')
              .html("<div title='Add new row' class='dm-row-action jdm-row-add'><i class=\"fas fa-plus\"></i></div>" + "<div title='Clone the selected row' class='dm-row-action jdm-row-copy'><i class=\"far fa-clone\"></i></div>" + "<div title='Remove the selected row' class='dm-row-action jdm-row-del'><i class=\"fas fa-trash\"></i></div>" + "<div title='Edit the selected row' class='dm-row-action jdm-row-edit'><i class=\"fas fa-pencil-alt\"></i></div>" + "<div'>" + _html + "</div>");
            $cell.empty().append($(el));
          });
        },
        _chkUITables = function () {
          $('div.dm-field', $(ts)).each(function () {
            var $cell = $(this).closest('td'), $row = $(this).closest('tr');
            if (!$cell.hasClass('dm-cell-val')) {
              $cell.addClass('dm-cell-val');
            }
            if (!$row.hasClass('dm-row-val')) {
              $row.addClass('dm-row-val');
            }
          });

          var _tblW = parseInt($(ts).width(), 10), _maxW = 0;
          $("table", $(ts)).each(function () {
            var t = this, _wStr = $(t).attr("width"), mtc = /(\d+)%/.exec(_wStr);
            if (mtc) {
              var _w = parseInt(mtc[1], 10);
              if (_w > 100) {
                $(t).attr("width", "101%");
                if (_w > _maxW) _maxW = _w;
              }
            }
          });
          if (_maxW > 0) {
            _tblW = Math.round(_tblW * _maxW / 100);
          }
        },
        refreshTbIndex = function (tabn) {
          if (ts.p.data[tabn] === undefined) {
            console.log("refreshTbIndex: broken data ", ts.p.data)
            return
          }
          var datalen = ts.p.data[tabn].length, data = ts.p.data[tabn], idname = $.jdm.getTbIdKey(tabn), rn, val;

          ts.p._index[tabn] = [];
          ts.p._indexR[tabn] = [];

          for (var i = 0; i < ts.p.colModel.length; i++) {
            var cm = ts.p.colModel[i];
            if (cm.tabn === tabn && cm.rownum) {
              rn = ts.p.colModel[i].index;
              break;
            }
          }
          for (var i = 0; i < datalen; i++) {
            val = $.jdm.getAccessor(data[i], idname);
            if (val === undefined) {
              val = String(i/*+1*/);
            }
            ts.p._index[tabn][val] = i;
            ts.p._indexR[tabn][i] = val;

            if (rn) {
              $(ts).jqDm("setCell", $.jdm.getTbCellIdUI(val, rn)/*rowid*/, i/*iRow*/, rn/*iCol*/, (i + 1), true);
            }
          }
        },
        refreshIndex = function (tabn) {
          if (tabn === undefined) {
            if (ts.p.cmTabCnt > 0) {
              for (var k = 0; k <= ts.p.cmTabCnt; k++) {
                refreshTbIndex(k);
              }
            }
          } else refreshTbIndex(tabn);
        },
        cellVal = function (val) {
          return val === null || val === "" ? "&#160;" : (ts.p.autoencode ? $.jdm.htmlEncode(val) : String(val));
        },
        formatter = function (rowId, cellval, colpos, rwdat, _act) {
          var cm = ts.p.colModel[colpos], v;
          if (cm.formatter !== undefined) {
            rowId = String(ts.p.idPrefix) !== "" ? $.jdm.stripPref(ts.p.idPrefix, rowId) : rowId;
            var opts = {rowId: rowId, colModel: cm, gid: ts.p.id, pos: colpos};
            if ($.isFunction(cm.formatter)) {
              v = cm.formatter.call(ts, cellval, opts, rwdat, _act);
            } else if ($.fmatter) {
              v = $.fn.fmatter.call(ts, cm.formatter, cellval, opts, rwdat, _act);
            } else {
              v = cellVal(cellval);
            }
          } else {
            v = cellVal(cellval);
          }
          return v;
        },
        reader = function (datatype, tabn) {
          var field, f = [], j = 0, i;
          for (i = 0; i < ts.p.colModel.length; i++) {
            field = ts.p.colModel[i];
            field.name = field.name || field.index;
            field.tabn = field.tabn || 0;

            if (field.tabn === tabn) {
              if (field.name !== 'rn') {
                f[j] = datatype === "local" ? field.name : ((datatype === "xml" || datatype === "xmlstring") ? field.xmlmap || field.name : field.jsonmap || field.name);
                j++;
              }
            }
          }
          return f;
        },
        dataReader = function (tabn) {
          var f = {}, field, i;
          tabn = parseInt(tabn);
          for (i = 0; i < ts.p.colModel.length; i++) {
            field = ts.p.colModel[i];
            field.name = field.name || field.index;
            field.tabn = parseInt(field.tabn || 0);

            if (field.tabn === tabn) {
              if (field.name !== 'rn') {
                f[field.name] = "";
              }
            }
          }
          return f;
        },
        emptyRows = function (tabn) {
          tabn = (tabn === undefined) ? -1 : tabn;

          if (tabn === -1) {
            for (var i = 1; i <= this.p.cmTabCnt; i++) {
              if (this.p.data[i]) {
                for (var k = 0; k < this.p._indexR[i].length; k++) {
                  $("#" + this.p._indexR[i][k]).remove();
                }
              }
            }

            this.p.data = [];
            this.p._index = [];
            this.p._indexR = [];
            for (var i = 0; i < this.p.colModel.length; i++) {
              var cm = this.p.colModel[i];
              _setFieldValue.call($(this), $(".dm-field[p=" + cm.index + "]", $(this)), cm, "");
            }
          } else {
            for (var i = tabn; i <= tabn; i++) {
              if (this.p.data[i]) {
                for (var k = 0; k < this.p._indexR[i].length; k++) {
                  $("#" + this.p._indexR[i][k]).remove();
                }
              }
              this.p.data[i] = [];
              this.p._index[i] = [];
              this.p._indexR[i] = [];
            }
          }
        },
        addTabData = function (self, tabn, rows, fget, objectReader, afterInsRow, dicts) {
          var enDct = true;
          if (!rows) {
            rows = [];
          }
          if (dicts === undefined) {
            dicts = [];
            enDct = false;
          }

          var len = rows.length, rd = {}, rowReader, cur;
          //ig added for set hidden state for tabulates of dynamic tables
          ts.p.DTTabulatesHidden[tabn] = [];
          if (tabn !== 0) {
            var tb = [], rowTmpl = "#" + $.jdm.getTbIdTmplUI(tabn), rowId, lastRowId = rowTmpl;
            ts.p._index[tabn] = [];
            ts.p._indexR[tabn] = [];
            // itterate  row by row
            for (var i = 0; i < len; i++) {
              cur = rows[i];
              rowReader = objectReader;

              rowId = $.jdm.randId();
              rd[$.jdm.getTbIdKey(tabn)] = rowId;

              ts.p._index[tabn][rowId] = i;
              ts.p._indexR[tabn][i] = rowId;
              // add new row to dynamic table
              var newRow = $.jdm.addTbRowUI(rowId, tabn, i, $(rowTmpl, $(ts)), $(lastRowId, $(ts)), i === 0 ? 'last_and_del_empty' : 'last');
              // var newRow = $.jdm.addTbRowUI(rowId, tabn, i, $(rowTmpl, $(ts)), $(lastRowId, $(ts)));
              $(newRow).on("click", function (e) {
                var $e = $(e.currentTarget);
                $("tr.selected", $(ts)).removeClass("selected");
                $e.addClass("selected");
              });
              // attach editors to new row & bind events to it
              $(".dm-field", $(newRow)).each(function () {
                var p = $(this).attr("p"), ncol = ts.getColIndex(p), field = ts.p.colModel[ncol] || {};
                _attachFieldEditor($(this), field, i);
              });
              // attach row acitons to every row
              $(".dm-row-action", $(newRow)).each(function () {
                _attachFieldAction($(this), rowId, tabn);
              });
              // iterate for values each row
              for (var j = 0; j < rowReader.length; j++) {
                var fieldNm = rowReader[j], npos = ts.getColIndex(fieldNm), field = ts.p.colModel[npos] || {},
                  isDct = (fieldNm.substring(0, 5) === 'dict_') ? enDct : false,
                  v = (isDct) ? fget(dicts, fieldNm + "[rownum=" + (i + 1) + "]") : fget(cur, fieldNm), // v = fget(rows|cur, rowReader[j])
                  cellId = $.jdm.getTbCellIdUI(rowId, fieldNm);
                rd[fieldNm] = v;

                var $els = $(".dm-field[p=" + fieldNm + "]", $(newRow));
                $els.attr("id", cellId);
                _setFieldValue.call($(ts), $els, field, v, formatter(cellId, v, npos, cur, 'add'));
              }
              tb.push(rd);
              lastRowId = "#" + rowId;
              if (afterInsRow) {
                ts.p.afterInsertRow.call(ts, "init", rowId, tabn, rd, cur);
              }
              rd = {};
            }
            // ts.p.data.push(tb);
            ts.p.data[tabn] = tb;

          } else {
            rowReader = objectReader;
            ts.p._index[0] = 0;
            ts.p._indexR[0] = 0;
            //ig added for set hidden state for tabulates of dynamic tables
            ts.p.DTTabulatesHidden[tabn][0] = [];

            for (var j = 0; j < rowReader.length; j++) {
              var isDct = (rowReader[j].substring(0, 5) === 'dict_') ? enDct : false, v, fieldNm = rowReader[j],
                npos = ts.getColIndex(fieldNm), field = ts.p.colModel[npos] || {};
              if (isDct) {
                v = fget(dicts, fieldNm);
                if (v === undefined) {
                  v = "";
                }
              } else {
                v = fget(rows, fieldNm);
              }
              rd[rowReader[j]] = v;
              _setFieldValue.call($(ts), $(".dm-field[p=" + fieldNm + "]", $(ts)), field, v, formatter(fieldNm/* rowId */, v, npos, rows, 'add'));
            }
            // ts.p.data.push(rd);
            ts.p.data[tabn] = rd;
            if (afterInsRow) {
              ts.p.afterInsertRow.call(ts, "init", undefined, 0, rd, rows);
            }
          }
        },

        recalculateTabData = function (tabn, rowid, row) {
          if (tabn !== 0) {
            const iRow = _getRowNum(tabn, rowid);
            ts.p.afterInsertRow.call(ts, "tbrecalc", iRow, tabn, row);
          }
        },
        setJSONData = function (data) {
          var cm = ts.p.colModel, ret = [], row, rdata;

          var getReader = function (tabn) {
            var ret = {};
            tabn = parseInt(tabn, 10);
            $(cm).each(function () {
              var fld = this;
              if (parseInt(fld.tabn, 10) === tabn) {
                ret[fld.index] = "";
              }
            });
            return ret;
          };

          for (var t = 0; t <= ts.p.cmTabCnt; t++) {
            var rdr = getReader(t);
            if (t === 0) {
              row = Object.assign({}, rdr);
              var mainTb = data.main || [];
              for (var iii in mainTb) {
                rdata = mainTb[iii];
                var nm = rdata.name, pos = ts.getColIndex(nm), fld = cm[pos],
                  datatype = fld && fld.useropts && fld.useropts.datatype ? fld.useropts.datatype : 'STRING';
                if (fld && fld.useropts) {
                  var edtype = fld.useropts.edittype ? fld.useropts.edittype : "text";
                  if (edtype.startsWith("radiogroup")) {
                    $(cm).each(function () {
                      var _fld = this, _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
                      if (_parent) {
                        if (_parent.name === nm && ((datatype === 'INTEGER' && parseInt(_parent.value) === parseInt(rdata.value)) || _parent.value === rdata.value)) {
                          row[_fld.index] = 1;
                          return false;
                        }
                      }
                    });
                  } else if (edtype.startsWith("checkgroup")) {
                    var _arr = rdata.value.split(";"), _rvalue;
                    for (var i in _arr) {
                      _rvalue = _arr[i];
                      $(cm).each(function () {
                        var _fld = this, _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
                        if (_parent) {
                          if (_parent.name === nm && ((datatype === 'INTEGER' && parseInt(_parent.value) === parseInt(_rvalue)) || _parent.value === _rvalue)) {
                            row[_fld.index] = 1;
                            return false;
                          }
                        }
                      });
                    }
                  } else {
                    if (fld.useropts.group ? fld.useropts.group : false) {
                      try {
                        var _val = JSON.parse(rdata.value);
                        for (var k in _val) {
                          row[k] = _val[k]
                        }
                      } catch (ex) {
                        console.warn(ex);
                      }
                    }
                  }
                }
                row[nm] = (datatype === 'INTEGER') ? parseInt(parseFloat("" + rdata.value)) : (datatype === 'NUMBER' ? parseFloat(rdata.value) : rdata.value);
              }
              ret.push(row);
            } else {
              var tb = [], rowEt = getReader(t), tabRow, row;
              if (data.tabdata && ("tab_" + t) in data.tabdata) {
                var tabData = data.tabdata["tab_" + t];
                for (var nrow in tabData) {
                  tabRow = tabData[nrow];
                  row = Object.assign({}, rowEt);
                  for (var j in tabRow) {
                    rdata = tabRow[j];
                    var nm = rdata.name, pos = ts.getColIndex(nm), fld = cm[pos],
                      datatype = fld && fld.useropts && fld.useropts.datatype ? fld.useropts.datatype : 'STRING';
                    if (fld && fld.useropts) {
                      let edtype = fld.useropts.edittype ? fld.useropts.edittype : "text";
                      if (edtype.startsWith("radiogroup")) {
                        $(cm).each(function () {
                          var _fld = this,
                            _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
                          if (_parent) {
                            if (_parent.name === nm && ((datatype === 'INTEGER' && parseInt(_parent.value) === parseInt(rdata.value)) || _parent.value === rdata.value)) {
                              row[_fld.index] = 1;
                              //ig added
                              row[nm] = rdata.value;
                              return false;
                            }
                          }
                        });
                      } else if (edtype.startsWith("checkgroup")) {
                        var _arr = rdata.value.split(";"), _rvalue;
                        for (var i in _arr) {
                          _rvalue = _arr[i];
                          $(cm).each(function () {
                            var _fld = this,
                              _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
                            if (_parent) {
                              if (_parent.name === nm && ((datatype === 'INTEGER' && parseInt(_parent.value) === parseInt(_rvalue)) || _parent.value === _rvalue)) {
                                row[_fld.index] = 1;
                                //ig added
                                row[nm] = rdata.value;
                                return false;
                              }
                            }
                          });
                        }
                      } else {
                        row[rdata.name] = (datatype === 'INTEGER') ? parseInt(parseFloat("" + rdata.value)) : (datatype === 'NUMBER' ? parseFloat(rdata.value) : rdata.value);
                      }
                    } else {
                      row[rdata.name] = (datatype === 'INTEGER') ? parseInt(parseFloat("" + rdata.value)) : (datatype === 'NUMBER' ? parseFloat(rdata.value) : rdata.value);
                    }
                  }
                  tb.push(row);
                  row = null;
                  tabRow = null;
                  rdata = null;
                }
              } else {
                row = Object.assign({}, rowEt);
                tb.push(row);
              }
              ret.push(tb);
            }
          }
          return ret;
        },
        addJSONData = function (data) {
          if (data) {
            emptyRows.call(ts);
          } else {
            return;
          }
          var drows = setJSONData.call(ts, data);
          var afterInsRow = $.isFunction(ts.p.afterInsertRow), len = drows.length,
            fGet = function (cur, nm) {
              return cur[nm];
            };
          for (var i = 0; i < len; i++) {
            addTabData($(ts), i, drows[i], fGet, reader("local", i), afterInsRow);
          }

          refreshIndex();
        },
        initDynaFields = function (callback) {
          if (!pz) {
            return;
          }
          const cm = ts.p.colModel;
          const dynaFieldsLength = (pz.dynaFields && pz.dynaFields?.length) || 0;
          let dynaFieldsDone = 0;
          if (dynaFieldsLength === 0) {
            callback();
          }
          (pz.dynaFields || []).forEach(fieldName => {
            const npos = ts.getColIndex(fieldName);
            const field = cm[npos] || {};
            if (field.useropts) {
              const _formatter = field.useropts.edittype.indexOf("radiogroup") !== -1 ? "rdDMGroupWithLabel" : "chkDMGroupWithLabel";
              $.jdm.getDictData(field.useropts.dctName, field.useropts.dctValueField, field.useropts.dctParentName, field.useropts.dctParentValueField, null, function (data) {
                const arrValues = data.map(v => v.value);
                const ul = $('[field="' + fieldName + '"] ul');
                data.forEach((v, i) => {
                  const newNm = fieldName + "_" + i;
                  const col = {
                    index: newNm,
                    name: newNm,
                    label: v.label,
                    editable: field.editable,
                    edittype: "checkbox",
                    formatter: _formatter,
                    tabn: 0,
                    __is_editors: true,
                    editoptions: {
                      value: "1:0"
                    },
                    useropts: {
                      parent: {
                        name: fieldName, value: v.value
                      },
                      afterSave: $.jdm.radiogroup__generateAfterSaveFunc((_formatter === "rdDMGroupWithLabel" || _formatter === "rdDMGroup") ? "radiogroup" : "checkgroup", fieldName, i + '', arrValues)
                    }
                  };
                  const colpos = cm.length;
                  pz.columnModel.push(col);
                  cm.push(col);
                  const li = document.createElement("li");
                  li.innerHTML = "<div class='fc' p='" + newNm + "'></div>";
                  ul.append($(li));
                  _chkUIEditor($($("div.fc[p='" + newNm + "']")[0]), col, colpos);
                  ts.p.data[0][newNm] = (ts.p.data[0][fieldName] + ';').indexOf(v.value + ';') !== -1 ? 1 : '';
                  const v1 = (ts.p.data[0][fieldName] + ';').indexOf(v.value + ';') !== -1 ? 1 : 0;
                  _setFieldValue($(".dm-field[p=" + newNm + "]", $(ts)), col, v1, formatter(newNm, v1, colpos, ts.p.data[0], 'edit'));
                });
                if (pz.mode === false) {
                  $('div.dm-field', $(ts)).addClass('dm-field-readonly');
                  $('input[type="checkbox"]', $('div.dm-field', $(ts))).attr("disabled", "disabled");
                }
                dynaFieldsDone++;
                if (dynaFieldsLength === dynaFieldsDone) {
                  callback();
                }
              });
            }
          });
        },
        _initCharts = function () {
          Chart.register(ChartDataLabels);
          pz.chartsData = [];
          pz.charts = [];
          const colModel = pz.$inst[0].p.colModel || [];
          colModel.filter(v => v?.useropts?.chart?.type?.length)
            .forEach((cm, index) => {
              cm.useropts.chart.index = index;
              const fields = cm.useropts.chart.data || [];
              // const cmFields = colModel.filter(v => fields.indexOf(v.index) >= 0);
              const cmFields = fields.map(f => colModel.filter(v => f.indexOf(v.index) >= 0));
              cmFields.forEach(f => f.forEach(v => {
                v.useropts.chart = v.useropts.chart || {};
                v.useropts.chart.target = v.useropts.chart.target || [];
                v.useropts.chart.target.push(cm.index);
              }));
              const cmFieldsLength = cmFields.length;
              if (!cmFieldsLength) {
                return;
              }
              const cmFieldsRowLength = cmFields[0].length;
              if (!cmFieldsRowLength) {
                return;
              }
              let labels = [];
              if (cm.useropts.chart.options?.label) {
                labels = cm.useropts.chart.options?.label;
                labels.forEach(l => {
                  if (l.startsWith('#')) {
                    const cm1 = colModel.find(vv => vv.index === l.substring(1));
                    if (cm1) {
                      cm1.useropts.chart = cm1.useropts.chart || {};
                      cm1.useropts.chart.target = cm1.useropts.chart.target || [];
                      cm1.useropts.chart.target.push(cm.index);
                    }
                  }
                });
              } else {
                cmFields[0].forEach((v, ind) => {
                  let label = v.label;
                  for (let i = 1; i < cmFieldsLength; i++) {
                    label += ' / ' + cmFields[i][ind].label;
                  }
                  labels.push(label);
                });
              }
              const chartType = cm.useropts.edittype.split(':')[1];

              let datalabels = cm.useropts.chart.options?.plug_labels ? cm.useropts.chart.options.plug_labels : false;
              let l_scales = {};
              const indexAxis = (cm.useropts.chart.options?.indexAxis && (cm.useropts.chart.options.indexAxis)) ? cm.useropts.chart.options.indexAxis : 'x';
              if (cm.useropts.chart.type !== 'radar' && cm.useropts.chart.options?.font_labels && Object.keys(cm.useropts.chart.options.font_labels).length) {
                l_scales[indexAxis === 'y' ? 'y' : 'x'] = {
                  ticks: {
                    font: cm.useropts.chart.options.font_labels
                  }
                };
              }
              if (cm.useropts.chart.type !== 'radar' && cm.useropts.chart.options?.font_legend && Object.keys(cm.useropts.chart.options.font_legend).length) {
                l_scales[indexAxis === 'y' ? 'x' : 'y'] = {
                  ticks: {
                    font: cm.useropts.chart.options.font_legend
                  }
                };
              }
              if (cm.useropts.chart.type === 'radar' && cm.useropts.chart.options?.font_legend && Object.keys(cm.useropts.chart.options.font_legend).length) {
                l_scales['r'] = {
                  pointLabels: {
                    font: cm.useropts.chart.options.font_legend
                  }
                };
              }
              if (cm.useropts.chart.options?.suggestedMin) {
                if (cm.useropts.chart.type === 'radar') {
                  if (l_scales?.r && Object.keys(l_scales?.r).length > 0) {
                    l_scales.r['suggestedMin'] = cm.useropts.chart.options?.suggestedMin;
                  } else {
                    l_scales['r'] = {suggestedMin: cm.useropts.chart.options?.suggestedMin};
                  }
                } else {
                  if (indexAxis === 'y') {
                    if (l_scales?.x && Object.keys(l_scales?.x).length > 0) {
                      l_scales.x['suggestedMin'] = cm.useropts.chart.options?.suggestedMin;
                    } else {
                      l_scales['x'] = {suggestedMin: cm.useropts.chart.options?.suggestedMin};
                    }
                  } else {
                    if (l_scales?.y && Object.keys(l_scales?.y).length > 0) {
                      l_scales.y['suggestedMin'] = cm.useropts.chart.options?.suggestedMin;
                    } else {
                      l_scales['y'] = {suggestedMin: cm.useropts.chart.options?.suggestedMin};
                    }
                  }
                }
              }

              let options = {
                pzField: cm.index, responsive: true, indexAxis: indexAxis, maintainAspectRatio: false, plugins: {
                  legend: {
                    display: false, position: 'top'
                  }, datalabels: datalabels
                }, title: {
                  display: false
                }, scales: l_scales
              };
              pz.chartsData.push({labels: labels, labelsTmp: labels});
              let chart = new Chart(cm.index, {
                type: chartType, data: pz.chartsData[index], options: options
              });
              pz.charts.push(chart);
            });
        },
        beginReq = function () {
          ts.grid.loading = true;
          if (ts.p.showLoading) {
            $("#lui_" + $.jdm.jqID(ts.p.id)).show();
            $("#load_" + $.jdm.jqID(ts.p.id)).show();
          }
        },
        endReq = function () {
          ts.grid.loading = false;
          if (ts.p.showLoading) {
            $("#lui_" + $.jdm.jqID(ts.p.id)).hide();
            $("#load_" + $.jdm.jqID(ts.p.id)).hide();
          }
        },
        populate = function () {
          if (!ts.grid.loading) {
            var prm = {}, dt, dstr, pN = ts.p.prmNames;
            if (pN.nd !== null) {
              prm[pN.nd] = new Date().getTime();
            }
            var lcf = $.isFunction(ts.p.loadComplete), lc = lcf ? ts.p.loadComplete : null;

            $.extend(ts.p.postData, prm);

            if ($.isFunction(ts.p.datatype)) {
              ts.p.datatype.call(ts, ts.p.postData, "load_" + ts.p.id);
              return;
            }
            if ($.isFunction(ts.p.beforeRequest)) {
              var bfr = ts.p.beforeRequest.call(ts);
              if (bfr === undefined) {
                bfr = true;
              }
              if (bfr === false) {
                return;
              }
            }
            dt = ts.p.datatype.toLowerCase();
            switch (dt) {
              case "funcxml":
              case "funcjson":
                beginReq();
                if ($.isFunction(ts.p.url)) {
                  ts.p.url(function (data) {
                    //beginReq();
                    if ($.isFunction(ts.p.beforeProcessing)) {
                      if (ts.p.beforeProcessing.call(ts, data, st, xhr) === false) {
                        endReq();
                        return;
                      }
                    }
                    addJSONData(data);
                    initDynaFields(() => {
                      if (lc) {
                        lc.call(ts, data);
                      }
                      endReq();
                    });
                    data = null;
                  });
                }
                break;
              case "jsonstring":
                beginReq();
                if (typeof ts.p.datastr === 'string') {
                  dstr = $.jdm.parse(ts.p.datastr);
                } else {
                  dstr = ts.p.datastr;
                }
                addJSONData(dstr);
                if (lcf) {
                  ts.p.loadComplete.call(ts, dstr);
                }
                ts.p.datatype = "local";
                ts.p.datastr = null;
                endReq();
                break;
              case "local":
              case "clientside":
                beginReq();
                ts.p.datatype = "local";
                var req = addLocalData();
                addJSONData(req);
                if (lc) {
                  lc.call(ts, req);
                }

                endReq();
                break;
            }
          }
        };

      if (document.documentMode !== undefined) { // IE only
        if (document.documentMode <= 5) {
          alert("Grid can not be used in this ('quirks') mode!");
          return;
        }
      }
      // Define ID
      if (this.id === undefined || this.id === '') {
        this.id = $.jdm.randId('gridId');
      }
      // TODO: What is it?
      $(this).attr("tabindex", "0");
      this.p = p;
      this.p.useProp = !!$.fn.prop;

      // paint container and plugin
      {
        // realign table
        $(this).removeAttr("style").removeAttr("form");
        $("table", $(this)).each(function () {
          var $tbl = $(this), _w = $tbl.attr("width");
          if (typeof (_w) !== 'undefined') {
            $tbl.removeAttr("width").attr("width", "100%");
          }
        });
        // Paint Container
        var eg = $("<div style='width:100%;'>" + "<div style='vertical-align:top;" + (ts.p.cmTabCnt === 0 ? "" : "padding-left: 80px;padding-right: 80px;") + "' id='gboxcont_" + this.id + "'></div>" + "</div>");
        $(eg)
          .attr({
            id: "gbox_" + this.id, cellspacing: "0", cellpadding: "0", border: "0", align: "center", width: "100%"
          })
          .addClass("ui-jdm")
          .insertBefore(this);
        $("<div class='ui-widget-overlay jdm-overlay' id='lui_" + this.id + "'></div>").insertBefore(eg);
        //todo $("<div class='dm-loading ui-widget ui-state-default ui-state-active' id='load_" + this.id + "'>" + this.p.loadtext + "</div>").insertBefore(eg);
        $("<div class='dm-loading p-component' id='load_" + this.id + "'>" + this.p.loadtext + "</div>").insertBefore(eg);
        $("<div class='jdm-preview-content' id='preview_" + this.id + "'></div>").insertBefore(eg);

        $(this).appendTo($("#gboxcont_" + this.id));
        $(this).removeAttr("width");
      }
      this.p.id = this.id;

      $('.dm-tblwrow', $(ts)).bind('mouseover', function (e) {
        $(e).addClass("ui-state-hover").addClass("dm-tblrow-hover");
      }).bind('mouseout', function (e) {
        $(e).removeClass("ui-state-hover").removeClass("dm-tblrow-hover");
      });
      let parContainers = [ts];
      // point1 dyn table id anchor
      const dynTabCmp = $('#dynTabRowEditWrapper');
      if (dynTabCmp?.length) {
        parContainers.push(dynTabCmp.get(0));
      }
      $(parContainers).click(f_click)
        .bind('mousedown', function (event) {
          if (event && event.button && event.button == 2) {
            event.preventDefault();
          }
        })
        .bind('reloadGrid', function (e, opts) {
          ts.emptyRows(-1);
          if (ts.p.datatype === "local") {
            $(ts).jqDm("resetSelection");
            if (ts.p.data.length) {
              refreshIndex();
            }
          }
          ts.grid.populate();
          return false;
        })
        .dblclick(function (e) {
          var el = e.target, p = $(el).attr("p"), inp = (p) ? true : false;
          if (!inp) {
            return this;
          }
        })
        .bind('contextmenu', function (e) {
          var el = e.target, p = $(el).attr("p"), inp = (p) ? true : false;
          if (inp && $(el).hasClass("dm-field")) {
            var pos = ts.getColIndex(p), cm = ts.p.colModel[pos] || {};
            $.jdm.buildPromptDef($(ts), $(el), cm);
          }
          return false;
        });

      ts.formatter = function (rowId, cellval, colpos, rwdat, act) {
        return formatter(rowId, cellval, colpos, rwdat, act);
      };
      $.extend(grid, {populate: populate, emptyRows: emptyRows});

      this.grid = grid;
      ts.emptyRows = emptyRows;
      ts.refreshIndex = refreshIndex;
      ts.addJSONData = function (d) {
        addJSONData(d);
      };
      ts.addTabData = function (self, tabn, rows, fget, objectReader, afterInsRow, dicts) {
        addTabData(self, tabn, rows, fget, objectReader, afterInsRow, dicts);
      };
      ts.chkUIEditor = function (inp, field, i) {
        _chkUIEditor(inp, field, i);
      };

      ts.getRowId = _getRowId;
      ts.getRowNum = _getRowNum;
      ts.getColIndex = _getColIndex;
      ts.setFieldValue = _setFieldValue;
      ts.getFieldValue = _getFieldValue;
      ts.attachFieldEditor = _attachFieldEditor;
      ts.attachFieldAction = _attachFieldAction;
      ts.reader = reader;
      ts.dataReader = dataReader;
      ts.beginReq = beginReq;
      ts.endReq = endReq;

      /** START PROCESS "Init Column Model" */
      for (var i = 0; i < ts.p.colModel.length; i++) {
        var cm = ts.p.colModel[i];

        cm.tabn = parseInt(cm.tabn, 10) || 0;
        cm.rownum = (cm.rownum !== undefined) ? cm.rownum : false;
        cm.label = cm.label || cm.index;
        cm.editable = ((cm.rownum) ? false : ((cm.editable !== undefined) ? cm.editable : true));
        cm.edittype = cm.edittype || "text";
        cm.name = cm.index; // TODO: Let's try to avoid
        cm = _initCMField.call($(ts), cm);
        cm.hidden = (cm.hidden !== undefined) ? cm.hidden : false;
        ts.p.colModel[i] = cm;

        if (ts.p.cmTabCnt < cm.tabn) {
          ts.p.cmTabCnt = cm.tabn;
        }
      }
      /** END PROCESS "Init Column Model" **/

      /** START PROCESS "Init UI Elements" */
      $("div.fc", $(ts)).each(function () {
        var inp = $(this), name = inp.attr("p");
        if (typeof (name) !== 'undefined') {
          var mtc = /TAB(\d+)\(\?\).(\w+)/.exec(name);
          if (mtc) {
            name = mtc[2];
          }
          var pos = _getColIndex(name);
          if (pos !== -1) {
            var cm = ts.p.colModel[pos];
            _chkUIEditor(inp, cm, pos);
          } else {
            console.warn(" UIEditors::Element is not found in the column model", name);
            inp.remove();
          }
        } else {
          console.warn(" UIEditors::Attribute 'p' is not defined");
          inp.remove();
        }
      });
      // Remove all unused elements
      $(ts).find(".table-template-row-first").remove();
      $(ts).find(".table-last-row").remove();
      $(ts).find(".table-template-row").remove();
      $(ts).find("input[type=hidden]").remove();
      // building actions for dynamic tables
      if (ts.p.cellActions) {
        _uiAddActionsFields(this);
      }

      _chkUITables.call($(this));
      /** END PROCESS "Init UI Elements" */

      if ($.isFunction(ts.p.onInitGrid)) {
        ts.p.onInitGrid.call(ts);
      }


      setTimeout(function () {
        populate();
        _initCharts();
      }, 100);
    });
  };
})(jQuery);

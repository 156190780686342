(function ($) {
  "use strict";

  $.jdm = $.jdm || {};
  $.extend($.jdm, {
    formatMessage: function (nm, lbl) {
      return $.jdm.edit.msg.fieldNm + " \"" + lbl + "\"";
    },
    htmlDecode: function (value) {
      if (value && (value === '&nbsp;' || value === '&#160;' || (value.length === 1 && value.charCodeAt(0) === 160))) {
        return "";
      }
      return !value ? value : String(value).replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, '"').replace(/&amp;/g, "&");
    },
    htmlEncode: function (value) {
      return !value ? value : String(value).replace(/&/g, "&amp;").replace(/\"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    },
    format: function (format) { //jqgformat
      var args = $.makeArray(arguments).slice(1);
      if (format == null) {
        format = "";
      }
      return format.replace(/\{(\d+)\}/g, function (m, i) {
        return args[i];
      });
    },
    msie: navigator.appName === 'Microsoft Internet Explorer',
    msiever: function () {
      var rv = -1;
      var ua = navigator.userAgent;
      var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
      if (re.exec(ua) != null) {
        rv = parseFloat(RegExp.$1);
      }
      return rv;
    },
    stripHtml: function (v) {
      v = String(v);
      var regexp = /<("[^"]*"|'[^']*'|[^'">])*>/gi;
      if (v) {
        v = v.replace(regexp, "");
        return (v && v !== '&nbsp;' && v !== '&#160;') ? v.replace(/\"/g, "'") : "";
      }
      return v;
    },
    stripPref: function (pref, id) {
      var obj = $.type(pref);
      if (obj === "string" || obj === "number") {
        pref = String(pref);
        id = pref !== "" ? String(id).replace(String(pref), "") : id;
      }
      return id;
    },
    //
    round: function (value, places) {
      var multiplier = Math.pow(10, places);
      return (Math.round(value * multiplier) / multiplier);
    },
    //
    parse: function (jsonString) {
      var js = jsonString;
      if (js.substr(0, 9) === "while(1);") {
        js = js.substr(9);
      }
      if (js.substr(0, 2) === "/*") {
        js = js.substr(2, js.length - 4);
      }
      if (!js) {
        js = "{}";
      }
      return ($.jdm.useJSON === true && typeof JSON === 'object' && typeof JSON.parse === 'function') ?
        JSON.parse(js) :
        eval('(' + js + ')');
    },
    // convert value of type "string" to type "integer"
    parseInt: function (val, defval) {
      val = parseInt(val, 10);
      if (isNaN(val)) {
        return defval || 0;
      }
      return val;
    },
    // convert value of type "string" to type "float|number"
    parseFloat: function (val, defval) {
      val = parseFloat(val, 10);
      if (isNaN(val)) {
        return defval || 0;
      }
      return val;
    },
    // convert "string" value to type "boolean"
    parseBool: function (value, defaultVal) {
      if (defaultVal === undefined) {
        defaultVal = true;
      }
      if (typeof (value) === 'boolean' || value instanceof Boolean) {
        return value;
      }
      if (typeof (value) === 'string' || value instanceof String) {
        value = value.trim().toLowerCase();
        if (value === 'true' || value === 'false') {
          return value === 'true';
        }
      }
      return defaultVal;
    },
    convert2Date: function (date, format, delimiter) {
      //ig added
      if (date === undefined || date === "" || typeof date === 'boolean') {
        return date;
      }

      function getMonthIndex(name) {
        name = name.toLowerCase();
        if (name == "jan" || name == "january") {
          return 0;
        } else if (name == "feb" || name == "february") {
          return 1;
        } else if (name == "mar" || name == "march") {
          return 2;
        } else if (name == "apr" || name == "april") {
          return 3;
        } else if (name == "may" || name == "may") {
          return 4;
        } else if (name == "jun" || name == "june") {
          return 5;
        } else if (name == "jul" || name == "july") {
          return 6;
        } else if (name == "aug" || name == "august") {
          return 7;
        } else if (name == "sep" || name == "september") {
          return 8;
        } else if (name == "oct" || name == "october") {
          return 9;
        } else if (name == "nov" || name == "november") {
          return 10;
        } else if (name == "dec" || name == "december") {
          return 11;
        }
      }

      delimiter = delimiter === undefined ? "." : delimiter;
      var formatedDate = null,
        formatLowerCase = format.toLowerCase(),
        formatItems = formatLowerCase.split(delimiter),
        dateItems = date.split(delimiter),
        monthIndex = formatItems.indexOf("mm"),
        monthNameIndex = formatItems.indexOf("mmm"),
        dayIndex = formatItems.indexOf("dd"),
        yearIndex = formatItems.indexOf("yyyy"),
        d = dateItems[dayIndex];
      if (d < 10) {
        d = "0" + d;
      }
      if (monthIndex > -1) {
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        if (month < 10) {
          month = "0" + month;
        }
        formatedDate = new Date(dateItems[yearIndex], month, d);
      } else if (monthNameIndex > -1) {
        var monthName = dateItems[monthNameIndex];
        month = getMonthIndex(monthName);
        if (month < 10) {
          month = "0" + month;
        }
        formatedDate = new Date(dateItems[yearIndex], month, d);
      }
      return formatedDate;
    },
    parseDate: function (val) {
      return $.jdm.convert2Date(val, "yyyy-mm-dd", "-");
    },
    conv2date: function (val) {
      var ret = "";
      if (!(val === "31122999" || val === "")) {
        ret = "" + val;
        ret = ret.substr(4, 4) + "-" + ret.substr(2, 2) + "-" + ret.substr(0, 2);
      }
      return ret;
    },
    //
    getQuarterVars: function (year, nmonth) {
      var quarter = Math.ceil(nmonth / 3),
        startDate = $.jdm.getQuarterStartDate(year, quarter),
        endDate = $.jdm.getQuarterEndDate(year, quarter);
      return {quarter: quarter, startDt: startDate, endDt: endDate};
    },
    getQuarterStartDate: function (year, quarter) {
      return new Date(year, quarter * 3 - 3, 1);
    },
    getQuarterEndDate: function (year, quarter) {
      return new Date(year, quarter * 3, 0);
    },
    getMonthVars: function (year, nmonth) {
      var startDate = $.jdm.getMonthStartDate(year, nmonth),
        endDate = $.jdm.getMonthEndDate(year, nmonth);
      return {month: nmonth, startDt: startDate, endDt: endDate};
    },
    getMonthStartDate: function (year, nmonth) {
      return new Date(year, nmonth - 1, 1);
    },
    getMonthEndDate: function (year, nmonth) {
      return new Date(year, nmonth, 0);
    },
    jqID: function (sid) {
      return String(sid).replace(/[!"#$%&'()*+,.\/:; <=>?@\[\\\]\^`{|}~]/g, "\\$&");
    },
    uuid: function () {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    randId: function (prefix) {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }

      return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    },
    // The improvement of String, function repeat
    repeat: function (s, num) {
      return new Array(Number(num) + 1).join(s);
    },
    // The improvement of String, function lpad
    lpad: function (str, len, pad) {
      return $.jdm.pad(str, len, pad, 1);
    },
    // The improvement of String, function rpad
    rpad: function (str, len, pad) {
      return $.jdm.pad(str, len, pad, 2);
    },
    // The improvement of String, function pad
    pad: function (str, len, pad, dir) {
      /*
	   var STR_PAD_LEFT = 1;
	   var STR_PAD_RIGHT = 2;
		var STR_PAD_BOTH = 3; */
      len = (typeof (len) === "undefined") ? 0 : len;
      pad = (typeof (pad) === "undefined") ? ' ' : pad;
      dir = (typeof (dir) === "undefined") ? 2/*STR_PAD_RIGHT*/ : dir;

      if (len + 1 >= str.length) {
        switch (dir) {
          case 1/*STR_PAD_LEFT*/
          :
            str = Array(len + 1 - str.length).join(pad) + str;
            break;
          case 3/*STR_PAD_BOTH*/
          :
            var padlen = len - str.length, right = Math.ceil(padlen / 2), left = padlen - right;
            str = Array(left + 1).join(pad) + str + Array(right + 1).join(pad);
            break;
          default:
            str = str + Array(len + 1 - str.length).join(pad);
            break;
        }
      }
      return str;
    },
    // The improvement of String, function isEmpty
    isEmpty: function (s) {
      return String(s) === "";
    },
    // The improvement of String, function strlen
    strlen: function (s) {
      return String(s).length;
    },
    // The improvement of String, function trim
    isWhitespace: function (s) {
      return " \t\n\r\f".indexOf(s) !== -1;
    },
    // The improvement of String, function trim
    trim: function (str) {
      return String(str).replace(/^\s+|\s+$/g, '');
    },
    // The improvement of String, function rtrim
    rtrim: function (str) {
      return String(str).replace(/\s+$/g, '');
    },
    // The improvement of String, function ltrim
    ltrim: function (str) {
      return String(str).replace(/^\s+/g, '');
    },

    getAccessor: function (obj, expr) {
      var ret, p, prm = [], i;
      if (typeof expr === 'function') {
        return expr(obj);
      }
      ret = obj[expr];
      if (ret === undefined) {
        try {
          if (typeof expr === 'string') {
            prm = expr.split('.');
          }
          i = prm.length;
          if (i) {
            ret = obj;
            while (ret && i--) {
              p = prm.shift();
              ret = ret[p];
            }
          }
        } catch (e) {
        }
      }
      return ret;
    },
    ajaxOptions: {},
    getMethod: function (name) {
      return this.getAccessor($.fn.jqDm, name);
    },
    extend: function (methods) {
      $.extend($.fn.jqDm, methods);
      if (!this.no_legacy_api) {
        $.fn.extend(methods);
      }
    },
    // returns row id for the dynamic table
    getTbIdKey: function (tabn) {
      return "_id_" + tabn + "_";
    },
    // returns template id for thr dynamic table
    getTbIdTmplUI: function (tabn) {
      return "DM-TAB" + tabn + "-TMPL";
    },
    // inits the dynamic table
    initTbTmplUI: function (el, tabn) {
      var tb = $(el).closest(".table-template-row");
      if (tb) {
        if (typeof (tb.attr("p")) !== 'undefined') {
          tb.removeClass("table-template-row").addClass("dm-tbl-template")
            .removeAttr("p")
            .removeAttr("id").attr("id", $.jdm.getTbIdTmplUI(tabn))
            .hide();
        }
      }
    },
    // returns cell id in the dymamic table
    getTbCellIdUI: function (rowid, nm, tabn, rn) {
      if (tabn === undefined) {
        tabn = (rowid !== null) ? 1000 : 0;
      }
      return (tabn === 0) ? nm : (rowid + "_" + nm);
    },
    // adds new row to the dynamic table using the template
    addTbRowUI: function (rowId, tabn, rn, tmpl, src, pos) {
      if (!pos) {
        pos = "last";
      }
      if (!src) {
        src = tmpl;
      }
      pos = String(pos).toLowerCase();

      var newRow = tmpl.clone(true);
      $(newRow)
        .removeAttr("id").attr("id", rowId).attr("rn", rn).attr("tabn", tabn)
        .removeClass("dm-tbl-template").addClass("dm-tblrow").addClass("dm-tbl");
      switch (pos) {
        // workaround for empty row created by default twice
        case "last_and_del_empty":
          pos = "last";
          var el = $("tr[rn='0']", $(tmpl.parent()));
          if ((el) && (el?.length >= 1)) {
            // console.log("row 0 already has duplicate added to tb ", tabn);
            // console.log("row 0 already will be deleted ", el[0]);
            el[0].remove();
          }
          $(newRow).insertAfter(src).show();
          break;
        case "last":
          $(newRow).insertAfter(src).show();
          break;
        case "first":
          $(newRow).insertAfter(tmpl).show();
          break;
        case "after":
          $(newRow).insertAfter(src).show();
          break;
        case "before":
          $(newRow).insertBefore(src).show();
          break;
      }
      return newRow;
    },
    // reserved
    radiogroup__generateAfterSaveFunc: function (type, nm, i, arr) {
      var ret = " if(name === '" + (nm + "_" + ("" + i)) + "') { ";
      if (type === "radiogroup") {
        ret += " var res = '" + arr[i] + "';";
        for (var k in arr) {
          if (k !== i) {
            ret += " if (parseInt(val, 10) !== 0) { $(this).setCellValue(iRow, '" + (nm + "_" + ("" + k)) + "', ''); }";
          }
        }
      } else {
        ret += " var res = '';";
        for (var k in arr) {
          ret += " if ($(this).getCellValue(iRow, '" + (nm + "_" + ("" + k)) + "') == '1') { res += (res.length>0 ? ';':'') + '" + arr[k] + "'; } ";
        }
      }
      ret += " $(this).setCellValue(iRow, '" + nm + "', res);";
      ret += " }";
      // ret += " env.autoFillParams(App, '" + nm + "');";
      //  ----autoFillParams({evt: "commit"},vcurrCM.index, false, tabn, iRow, iCol);
      ret += " env.autoFillParams(App, '" + nm + "',false, iTab, iRow,'runtime');";
      return ret;
    },
    /* ---*/
    getPromptClsName: function (field) {
    },
    removePrompt: function (field) {
      var compRef = window['editorComponentRef'], isMobileDevice = false;
      if (compRef && (typeof (compRef.zone) !== 'undefined' && typeof (compRef.component) !== 'undefined')) {
        compRef.zone.run(function () {
          isMobileDevice = compRef.component.configService.deviceType > 0;
        });
      }
      if (!isMobileDevice) {
        $(field).webuiPopover('destroy');
      }
    },
    removeAllPrompts: function ($t) {
      var compRef = window['editorComponentRef'], isMobileDevice = false;
      if (compRef && (typeof (compRef.zone) !== 'undefined' && typeof (compRef.component) !== 'undefined')) {
        compRef.zone.run(function () {
          isMobileDevice = compRef.component.configService.deviceType > 0;
        });
      }
      if (!isMobileDevice) {
        $("div.dm-value-error", $t).webuiPopover('destroy');
      }
    },
    buildPrompt: function (field, errors, OnlyHL) {
      var compRef = window['editorComponentRef'], isMobileDevice = false;

      if (compRef && (typeof (compRef.zone) !== 'undefined' && typeof (compRef.component) !== 'undefined')) {
        compRef.zone.run(function () {
          isMobileDevice = compRef.component.configService.deviceType > 0 || compRef.component.configService.touchable;
        });
      }
      if (typeof (OnlyHL) === 'undefined') {
        OnlyHL = false;
      }
      if (!isMobileDevice) {
        var promptTxt = "", r;
        for (var k in errors) {
          r = errors[k];
          // ig added to prevent display popup window for the mandatory fields

          if (OnlyHL && r?.errorType === "required") {
            continue;
          }
          promptTxt += "<li class='item'>" + r.message + "</li>";
        }
        if (promptTxt === "") {
          $(field).webuiPopover("destroy");
        } else {
          // removed popup errors
          // $(field)
          //   .webuiPopover("destroy")
          //   .webuiPopover({
          //     title: '<span class="error">Error</span>',
          //     content: "<div class='error-content'><ul class='list'>" + promptTxt + "</ul></div>",
          //     closeable: true,
          //     trigger: 'hover'
          //   });
        }
      }
    },
    buildPromptDef: function ($t, field, cm) {
      var compRef = window['editorComponentRef'], isMobileDevice = false;
      if (compRef && (typeof (compRef.zone) !== 'undefined' && typeof (compRef.component) !== 'undefined')) {
        compRef.zone.run(function () {
          isMobileDevice = compRef.component.configService.deviceType > 0;
        });
      }
      if (!isMobileDevice) {
        var promptTxt = "", promptTxt1 = "", cntExpr = 0, edtrul = cm.editrules || {},
          controls = $t.jqDm("getGridParam", "controls"), rls, isTypeString = true;
        promptTxt += "<li class='item'>Variable name: <b>" + cm.index + "</b></li>";
        if (edtrul.required === true) {
          promptTxt += "<li class='item'>Required: <b>Yes</b></li>";
        }
        if (edtrul.number === true) {
          isTypeString = false;
          promptTxt += "<li class='item'>Field type: <b>NUMBER</b></li>";
        }
        if (edtrul.integer === true) {
          isTypeString = false;
          promptTxt += "<li class='item'>Field type: <b>INTEGER</b></li>";
        }
        if (edtrul.date === true) {
          isTypeString = false;
          promptTxt += "<li class='item'>Field type: <b>DATE (yyyy-mm-dd)</b></li>";
        }
        if (edtrul.time === true) {
          isTypeString = false;
          promptTxt += "<li class='item'>Field type: <b>TIME (hh:mi)</b></li>";
        }
        if (isTypeString) {
          promptTxt += "<li class='item'>Field type: <b>STRING</b></li>";
        }
        if (edtrul.email === true) {
          promptTxt += "<li class='item'>Field type: <b>EMAIL</b></li>";
        }
        if (edtrul.integer === true || edtrul.number === true) {
          if (edtrul.minValue !== undefined && !isNaN(edtrul.minValue)) {
            promptTxt += "<li class='item'>Field value must be greater than " + edtrul.minValue + "</li>";
          }
          if (edtrul.maxValue !== undefined && !isNaN(edtrul.maxValue)) {
            promptTxt += "<li class='item'>Field value must be less than " + edtrul.maxValue + "</li>";
          }
        }

        for (var i in controls) {
          rls = controls[i];
          if (rls.c_doc_rowc === ("^" + cm.index)) {
            cntExpr++;
            if (cntExpr === 1) {
              promptTxt1 += "&nbsp;" + rls.sign + "&nbsp;" + rls.description;
            } else {
              if (cntExpr === 2) {
                promptTxt1 = "<li class='item'>" + promptTxt1 + "</li>;"
              }
              promptTxt1 += "<li class='item'>&nbsp;" + rls.sign + "&nbsp;" + rls.description + "</li>";
            }
          }
        }
        if (promptTxt1.length > 0) {
          if (cntExpr < 2)
            promptTxt += "<li>Calculated:&nbsp;" + promptTxt1 + "</li>";
          else
            promptTxt += "<li>Calculated:&nbsp;<ul>" + promptTxt1 + "</ul></li>";
        }
        $.jdm.open_dialog(cm.label || cm.index, "<div class='content'><ul class='list'>" + promptTxt + "</ul></div>");
      }
    },
    buildPromptHint: function (field, hint) {
      const compRef = window['editorComponentRef'];
      let isMobileDevice = false;
      if (compRef && (typeof (compRef.zone) !== 'undefined' && typeof (compRef.component) !== 'undefined')) {
        compRef.zone.run(function () {
          isMobileDevice = compRef.component.configService.deviceType > 0 || compRef.component.configService.touchable;
        });
      }
      $(field)
        .webuiPopover({
          content: "<div>" + hint + "</div>",
          trigger: isMobileDevice ? 'click' : 'hover',
          cache: false,
          multi: true
        }).webuiPopover('show');
    },
    /*<-- -->*/
    confirm: function (title, message, clbYes, clbNo) {
      console.warn("Function \"CONFIRM\" not developed\"", title, message);
    },
    error: function (message, title, clb) {
      console.warn("Function \"ERROR\" not developed\"", message, title);
    },
    info: function (message, title, clb) {
      console.warn("Function \"ALERT\" not developed\"", message, title);
    },
    alert: function (message, title, clb) {
      console.warn("Function \"ALERT\" not developed\"", message, title);
    },
    info_dialog: function (title, message, type) {
      console.warn("Function \"INFO_DIALOG\"  not developed\"", title, message);
    },
    open_dialog: function (title, content, type) {
      console.warn("open_dialog", title, content, type);
    },
    groupBy: function (array, col, value, mathopr) {
      var r = [], o = {};
      if (typeof (mathopr) === 'undefined') mathopr = "sum";

      var getUniqueValues = function (ar) {
        var a = [], l = ar.length;
        for (var i = 0; i < l; i++) {
          for (var j = i + 1; j < l; j++) {
            if (ar[i].value === ar[j].value) {
              j = ++i;
            }
          }
          a.push(ar[i]);
        }
        return a;
      };

      array.forEach(function (a) {
        if (!o[a[col]]) {
          o[a[col]] = {};
          o[a[col]]["Key"] = a[col];
          o[a[col]]["Value"] = 0;
          o[a[col]]["Values"] = [];
          r.push(o[a[col]]);
        }
        o[a[col]]["Values"].push({"value": a[value]});
      });

      r.forEach(function (a) {
        var ret = 0, values = a["Values"];

        if (mathopr.toLowerCase() === "count distinct") {
          var values1 = getUniqueValues(values);
          ret = values1.length;
        } else {
          var v, sum = 0, min, max, v, ln = values.length;
          for (var k in values) {
            v = parseFloat(values[k]["value"]);
            v = !isNaN(v) ? v : 0;
            sum += v;
            if (max === undefined) {
              max = min = v;
            }
            min = Math.min(min, v);
            max = Math.max(max, v);
          }

          switch (mathopr.toLowerCase()) {
            case 'sum':
              ret = sum;
              break;
            case 'avg':
              ret = sum / ln;
              break;
            case 'count':
              ret = ln;
              break;
            case 'min':
              ret = min;
              break;
            case 'max':
              ret = max;
              break;
          }
        }
        o[a["Key"]]["Value"] = ret;
      });

      return r;
    },
    /** Specific functions */
    getTBWHydrationFactor: function (sex, age) {
    },
    getCountryRecord: function (code, clb) {
    },
    save: function () {
    },
    submit: function () {
      $.jdm.info_dialog("Warning!", "Function 'Submit' is not developed", "warn");
    },
    showRowEditDialog: function () {
      $.jdm.info_dialog("Warning!", "Function 'showRowEditDialog' is not developed", "warn");
    },

    // get current date as string using format 'yyyy-mm-dd'
    getCurrentDate: function () {
      var dt = new Date(),
        day = dt.getDate().toString(),
        month = (dt.getMonth() + 1).toString(),
        year = dt.getFullYear().toString();
      return year + "-" + $.jdm.lpad(month, 2, '0') + "-" + $.jdm.lpad(day, 2, '0');
    },
    // convert date to string using format 'yyyy-mm-dd'
    getFormattedDate: function (val) {
      var date = new Date(val),
        year = date.getFullYear(),
        day = date.getDate().toString(),
        month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      day = day.length > 1 ? day : '0' + day;

      return year + "-" + month + "-" + day;
    },
    // build Date Editor
    attachEdDate: function (elem) {
      $(elem).datepicker({
        startView: 1, // months
        format: 'yyyy-mm-dd', // default format
        todayBtn: 'linked', // show button "today"
        daysOfWeekHighlighted: "1,2,3,4,5", // Highlight work days
        //calendarWeeks: true, // show weeks
        autoclose: true, // autoclose effects
        todayHighlight: true // highlight today in the calendar
      });
      // mask input
      $(elem).mask('0000-00-00', {placeholder: "yyyy-mm-dd"});
    }
    // build Time Editor
    , attachEdTime: function (elem) {
      $(elem).mask('00:00:00', {placeholder: "hh:mi:ss"});
    }
    // build Time Editor
    , attachEdShortTime: function (elem, isShort) {
      $(elem).mask('00:00', {placeholder: "hh:mi"});
    },

    attachEdMask: function (elem, opt) {
      const options = {
        clearIfNotMatch: true,
        translation: {
          '0': {pattern: /\d/},
          '9': {pattern: /\d/, optional: true},
          'A': {pattern: /[a-zA-Z0-9]/},
          'B': {pattern: /[a-zA-Z0-9]/, optional: true},
          'C': {pattern: /[a-zA-Z]/},
          'D': {pattern: /[a-zA-Z]/, optional: true},
          "E": {pattern: /[\w@\-.+]/, recursive: true}
        }
      };
      if (opt.useropts.inputMaskPlaceHolder) {
        options.placeholder = opt.useropts.inputMaskPlaceHolder;
      }
      $(elem).mask(opt.useropts.inputMask, options);
    }
    // attach Dict Browse
    , attachDctBrowse: function ($inst, opt, el, opts) {
      var _doc = $inst, p = $(_doc)[0].p, colModel = p.colModel, data = p.data,
        _dctField = opt.field, _dctInp = el,
        _setData = function (rec) {
          for (var k in rec) {
            if (k === _dctField) {
              _dctInp.val(rec[k]);
              _dctInp.trigger("change");
              break;
            }
          }

          $(colModel).each(function () {
            var cm = this, userOpts = cm.useropts;
            if (userOpts && userOpts.dctName) {
              if (userOpts.dctName == opt.dct && userOpts.dctGroup == opt.group && cm.index !== opts.name) {
                var dctField = userOpts.dctField;
                if (typeof (rec[dctField]) !== 'undefined') {
                  var _v = rec[dctField];
                  _doc.setCell(null, p.iRow, cm.index, _v, true);

                  var env = new ScriptEnv($(_doc), p.iRow, cm.index);
                  env.autoFillParams(App, cm.index, _v);
                  env = null;
                }
              }
            }
          });
        },
        _choiceRowData = function (rdata) {
          if (rdata instanceof Array) {
            if (rdata.length > 1) {
              _doc.jqDm("emptyTab", p.iTab);
              for (var i in rdata) {
                var rec = rdata[i], data = {};
                data[opts.name] = "";
                for (var k in rec) {
                  if (k === _dctField) {
                    data[opts.name] = rec[k];
                    break;
                  }
                }

                $(colModel).each(function () {
                  var cm = this, userOpts = cm.useropts;
                  if (userOpts && userOpts.dctName) {
                    if (userOpts.dctName == opt.dct && userOpts.dctGroup == opt.group && cm.index !== opts.name) {
                      var dctField = userOpts.dctField;
                      if (typeof (rec[dctField]) !== 'undefined') {
                        data[cm.index] = rec[dctField];
                        return false;
                      }
                    }
                  }
                });

                var ln = _doc.jqDm("getTabLen", p.iTab);
                if (ln === 0) {
                  _doc.jqDm("addRowData", p.iTab, data, "first");
                } else {
                  _doc.jqDm("addRowData", p.iTab, data, "last");
                }

                for (var k in data) {
                  var env = new ScriptEnv($(_doc), ln, k);
                  env.autoFillParams(App, k, data[k]);
                  env = null;
                }
              }
            } else {
              _setData(rdata[0]);
            }
          } else {
            _setData(rdata);
          }
        };
      var sFilter = opt.filter, sFilterRet = "";
      if (sFilter !== undefined) {
        if ($.jdm.isEmpty(sFilter) === false) {
          var arrayItems = sFilter.split('&');
          for (var k1 in arrayItems) {
            var item = arrayItems[k1], arrayItems1 = item.split("=");
            if (arrayItems1.length >= 2) {
              var fieldDb = arrayItems1[0], fieldNm = arrayItems1[1];
              if (fieldNm.substr(0, 1) === "^") {
                var nm = fieldNm.substr(1), pos = $(_doc)[0].getColIndex(nm),
                  cm = pos >= 0 ? colModel[pos] : undefined;
                if (cm) {
                  if (cm.tabn === 0) {
                    sFilterRet += (!$.jdm.isEmpty(sFilterRet) ? ";" : "") + fieldDb + ":" + data[0][nm];
                  }
                }
              } else {
                sFilterRet += (!$.jdm.isEmpty(sFilterRet) ? ";" : "") + fieldDb + ":" + fieldNm;
              }
            }
          }
        }
      }
      var compRef = window['editorComponentRef'];
      if (compRef && (typeof (compRef.zone) !== 'undefined' && typeof (compRef.component) !== 'undefined')) {
        compRef.zone.run(function () {
          compRef.component.callbackInitDictBrowse(opt.dct, false, sFilterRet, _choiceRowData);
        });
      }
    },
    draggableHighlight: function (event, el) {
      event.preventDefault();
      $(el).parent().parent().addClass('draggable-highlight');
    },
    draggableUnhighlight: function (el) {
      $(el).parent().parent().removeClass('draggable-highlight');
    },
    draggableDrop: function (event, el) {
      event.preventDefault();
      $(el).parent().parent().removeClass('draggable-highlight');
      if (event.dataTransfer?.files?.length === 1) {
        const fileEl = $(el).parent();
        $.jdm.uploadFileData(fileEl.attr('p'), fileEl.attr('rn'), {files: event.dataTransfer.files});
      }
    }
  });
})(jQuery);

(function ($) {
  "use strict";
  $.extend($.jdm, {
    bindEv: function (el, opt) {
      var $t = this;
      if ($.isFunction(opt.dataInit)) {
        opt.dataInit.call($t, el, opt);
      } else {
        var tagNm = $(el).get(0).tagName;
        if (tagNm.toLowerCase() === "select") {
          var _useropts = opt.useropts,
            _userEdittype = _useropts && _useropts.edittype ? _useropts.edittype : "",
            _userSearch = _useropts && _useropts.select_search ? _useropts.select_search : false;
          if (_userSearch) {
            if (_userEdittype === "select" || _userEdittype === "combobox") {
              $(el)
                .attr("title", "Choose one of the following...")
                .attr("data-live-search", "true")
                .attr("data-header", "Choose one of the following")
                .attr("data-size", 8)
                .attr("data-width", "99%");
            } else if (_userEdittype === "multiselect" || _userEdittype === "multiselect-combobox") {
              $(el)
                .attr("title", "Choose some of the following...")
                .attr("data-live-search", "true")
                .attr("data-header", "Choose some of the following")
                .attr("data-selected-text-format", "count > 10")
                .attr("data-size", 8)
                .attr("data-actions-box", "true")
                .attr("data-width", "99%");
              $(el).addClass("dm-field-multiselect");
            }
            $(el).addClass("dropup").addClass("selectpicker")
            $(el).selectpicker();
          }
        }
      }
      if (opt.dataEvents) {
        $.each(opt.dataEvents, function () {
          if (this.data !== undefined) {
            $(el).on(this.type, this.data, this.fn);
          } else {
            $(el).on(this.type, this.fn);
          }
        });
      }
    },
    // Form Functions
    createEl: function (eltype, options, vl) {
      var elem = "", $t = this;

      function setAttributes(elm, atr, exl) {
        var exclude = [
          'dataInit',
          'dataEvents',
          'dataUrl',
          'buildSelect',
          'sopt',
          'searchhidden',
          'defaultValue',
          'attr',
          'custom_element',
          'custom_value',
          'useropts'];
        if (exl !== undefined && $.isArray(exl)) {
          $.merge(exclude, exl);
        }
        $.each(atr, function (key, value) {
          if ($.inArray(key, exclude) === -1) {
            $(elm).attr(key, value);
          }
        });
        if (!atr.hasOwnProperty('id')) {
          $(elm).attr('id', $.jdm.randId());
        }
      }

      switch (eltype) {
        case "textarea" :
          elem = document.createElement("textarea");
          if (!options.rows) {
            options.rows = 3;
          }
          if (vl === '&nbsp;' || vl === '&#160;' || (vl.length === 1 && vl.charCodeAt(0) === 160)) {
            vl = "";
          }
          elem.value = vl;
          setAttributes(elem, options);
          $(elem).attr({"role": "textbox", "multiline": "true"});
          if (options.useropts && options.useropts.css) $(elem).addClass(options.useropts.css);
          break;
        case "checkbox" : //what code for simple checkbox
          elem = document.createElement("input");
          elem.type = "checkbox";
          if (!options.value) {
            var vl1 = (vl + "").toLowerCase();
            if (vl1.search(/(false|f|0|no|n|off|undefined)/i) < 0 && vl1 !== "") {
              elem.checked = true;
              elem.defaultChecked = true;
              elem.value = vl;
            } else {
              elem.value = "on";
            }
            $(elem).attr("offval", "off");
          } else {
            var cbval = options.value.split(":");
            if (vl === cbval[0]) {
              elem.checked = true;
              elem.defaultChecked = true;
            }
            elem.value = cbval[0];
            $(elem).attr("offval", cbval[1]);
          }
          setAttributes(elem, options, ['value']);
          $(elem).attr("role", "checkbox");
          break;
        case "select" :
          elem = document.createElement("select");
          elem.setAttribute("role", "select");
          var msl, ovm = [];
          if (options.multiple === true) {
            msl = true;
            elem.multiple = "multiple";
            $(elem).attr("aria-multiselectable", "true");
          } else {
            msl = false;
          }
          if (options.useropts?.dctName) {
            setAttributes(elem, options, ['value']);
          } else if (options.value) {
            var i;
            if (options.size === undefined) {
              options.size = msl ? 3 : 1;
            }
            if (msl) {
              ovm = vl.split(",");
              ovm = $.map(ovm, function (n) {
                return $.trim(n);
              });
            }
            if (typeof options.value === 'function') {
              options.value = options.value();
            }
            var so, sv, ov,
              sep = options.separator === undefined ? ":" : options.separator,
              delim = options.delimiter === undefined ? ";" : options.delimiter;
            if (typeof options.value === 'string') {
              so = options.value.split(delim);
              for (i = 0; i < so.length; i++) {
                sv = so[i].split(sep);
                if (sv.length > 2) {
                  sv[1] = $.map(sv, function (n, ii) {
                    if (ii > 0) {
                      return n;
                    }
                  }).join(sep);
                }
                ov = document.createElement("option");
                ov.setAttribute("role", "option");
                ov.value = sv[0];
                ov.innerHTML = sv[1]; //if (msl) ov.title = sv[0];
                elem.appendChild(ov);
                if (!msl && ($.trim(sv[0]) === $.trim(vl) || $.trim(sv[1]) === $.trim(vl))) {
                  ov.selected = "selected";
                }
                if (msl && ($.inArray($.trim(sv[1]), ovm) > -1 || $.inArray($.trim(sv[0]), ovm) > -1)) {
                  ov.selected = "selected";
                }
              }
            } else if (typeof options.value === 'object') {
              var oSv = options.value, key;
              for (key in oSv) {
                if (oSv.hasOwnProperty(key)) {
                  ov = document.createElement("option");
                  ov.setAttribute("role", "option");
                  ov.value = key;
                  ov.innerHTML = oSv[key]; //if (msl) ov.title = key;
                  elem.appendChild(ov);
                  if (!msl && ($.trim(key) === $.trim(vl) || $.trim(oSv[key]) === $.trim(vl))) {
                    ov.selected = "selected";
                  }
                  if (msl && ($.inArray($.trim(oSv[key]), ovm) > -1 || $.inArray($.trim(key), ovm) > -1)) {
                    ov.selected = "selected";
                  }
                }
              }
            }
            setAttributes(elem, options, ['value']);
          }
          if (options.useropts && options.useropts.css) $(elem).addClass(options.useropts.css);
          break;
        case "text" :
        case "password" :
          elem = document.createElement("input");
          elem.type = eltype;
          elem.value = vl;
          setAttributes(elem, options);
          if (!options.size) {
            options.size = 20;
          }
          $(elem).attr("role", "textbox");
          $(elem).attr("autocomplete", "off");
          if (options.useropts && options.useropts.css) $(elem).addClass(options.useropts.css);
          break;
        case "orderlist" :
          elem = document.createElement("div");
          elem.type = eltype;
          break;
        case "image" :
        case "file" :
        case "button" :
        case "range" :
        case "richtextarea" :
          elem = document.createElement("div");
          elem.type = eltype;
          setAttributes(elem, options);
          break;
        case "canvas" :
          elem = document.createElement("canvas");
          elem.type = eltype;
          setAttributes(elem, options);
          break;
        case "custom" :
          try {
            if ($.isFunction(options.custom_element)) {
              var celm = options.custom_element.call($t, vl, options);
              if (celm) {
                celm = $(celm).addClass("customelement").attr({id: options.id, name: options.name});
              } else {
                throw "e2";
              }
            } else {
              throw "e1";
            }
            elem = celm;
          } catch (e) {
            elem = document.createElement("span");
            if (e === "e1") {
              $.jdm.info_dialog($.jdm.errors.errcap, "function 'custom_element' " + $.jdm.edit.msg.nodefined, "error");
            }
            if (e === "e2") {
              $.jdm.info_dialog($.jdm.errors.errcap, "function 'custom_element' " + $.jdm.edit.msg.novalue, "error");
            } else {
              $.jdm.info_dialog($.jdm.errors.errcap, typeof e === "string" ? e : e.message, "error");
            }
          }
          break;
      }
      return elem;
    },
    // Date Validation Javascript
    checkDate: function (format, date) {
      var daysInFebruary = function (year) {
          // February has 29 days in any year evenly divisible by four,
          // EXCEPT for centurial years which are not also divisible by 400.
          return (((year % 4 === 0) && (year % 100 !== 0 || (year % 400 === 0))) ? 29 : 28);
        },
        tsp = {}, sep;
      format = format.toLowerCase();
      //we search for /,-,. for the date separator
      if (format.indexOf("/") !== -1) {
        sep = "/";
      } else if (format.indexOf("-") !== -1) {
        sep = "-";
      } else if (format.indexOf(".") !== -1) {
        sep = ".";
      } else {
        sep = "/";
      }
      format = format.split(sep);
      date = date.split(sep);
      if (date.length <= 1) {
        return true;
      }
      if (date.length !== 3) {
        return false;
      }
      var j = -1, yln, dln = -1, mln = -1, i;
      for (i = 0; i < format.length; i++) {
        var dv = isNaN(date[i]) ? 0 : parseInt(date[i], 10);
        tsp[format[i]] = dv;
        yln = format[i];
        if (yln.indexOf("y") !== -1) {
          j = i;
        }
        if (yln.indexOf("m") !== -1) {
          mln = i;
        }
        if (yln.indexOf("d") !== -1) {
          dln = i;
        }
      }
      if (format[j] === "y" || format[j] === "yyyy") {
        yln = 4;
      } else if (format[j] === "yy") {
        yln = 2;
      } else {
        yln = -1;
      }
      var daysInMonth = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        strDate;
      if (j === -1) {
        return false;
      }
      strDate = tsp[format[j]].toString();
      if (yln === 2 && strDate.length === 1) {
        yln = 1;
      }
      if (strDate.length !== yln || (tsp[format[j]] === 0 && date[j] !== "00")) {
        return false;
      }
      if (mln === -1) {
        return false;
      }
      strDate = tsp[format[mln]].toString();
      if (strDate.length < 1 || tsp[format[mln]] < 1 || tsp[format[mln]] > 12) {
        return false;
      }
      if (dln === -1) {
        return false;
      }
      strDate = tsp[format[dln]].toString();
      if (strDate.length < 1 || tsp[format[dln]] < 1 || tsp[format[dln]] > 31 || (tsp[format[mln]] === 2 && tsp[format[dln]] > daysInFebruary(tsp[format[j]])) || tsp[format[dln]] > daysInMonth[tsp[format[mln]]]) {
        return false;
      }
      return true;
    },
    isEmpty: function (val) {
      //ig added check to null
      if (("" + val).match(/^\s+$/) || val === "" || val === null) {
        return true;
      }
      return false;
    },
    checkTime: function (time) {
      // checks only hh:ss (and optional am/pm)
      var re = /^(\d{1,2}):(\d{2})([apAP][Mm])?$/, regs;
      if (!$.jdm.isEmpty(time)) {
        regs = time.match(re);
        if (regs) {
          if (regs[3]) {
            if (regs[1] < 1 || regs[1] > 12) {
              return false;
            }
          } else {
            if (regs[1] > 23) {
              return false;
            }
          }
          if (regs[2] > 59) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    checkValues: function (cellId, val, valref, customobject, nam) {
      var edtrul, i, nm, dft, len, g = this, cm = g.p.colModel;
      if (customobject === undefined) {
        if (typeof valref === 'string') {
          for (i = 0, len = cm.length; i < len; i++) {
            if (cm[i].name === valref) {
              edtrul = cm[i].editrules;
              valref = i;
              if (cm[i].formoptions != null) {
                nm = cm[i].formoptions.label;
              }
              break;
            }
          }
        } else if (valref >= 0) {
          edtrul = cm[valref].editrules;
        }
      } else {
        edtrul = customobject;
        nm = nam === undefined ? "_" : nam;
      }
      var fmtMsg = $.jdm.formatMessage(cm[valref].index, (!nm ? cm[valref].label : ""));
      if (edtrul) {
        if (!nm) {
          nm = cm[valref].label;
        }
        if (edtrul.masked === true) {
          const cc = $("#" + cellId)
          if ($(cc).data('mask-cleared') === true) {
            return [false, fmtMsg + ": " + $.jdm.edit.msg.pattern, ""];
          }
        }
        if (edtrul.required === true) {
          if ($.jdm.isEmpty(val)) {
            return [false, fmtMsg + ": " + $.jdm.edit.msg.required, ""];
          }
        }
        // force required
        var rqfield = edtrul.required === false ? false : true;
        if (edtrul.number === true || edtrul.integer === true) {
          if (edtrul.minValue !== undefined && !isNaN(edtrul.minValue)) {
            if (parseFloat(val) < parseFloat(edtrul.minValue)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.minValue + " " + edtrul.minValue, ""];
            }
          }
          if (edtrul.maxValue !== undefined && !isNaN(edtrul.maxValue)) {
            if (parseFloat(val) > parseFloat(edtrul.maxValue)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.maxValue + " " + edtrul.maxValue, ""];
            }
          }
        }
        if (edtrul.number === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            if (isNaN(val)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.number, ""];
            }
          }
        }
        var filter;
        if (edtrul.email === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            // taken from $ Validate plugin
            filter = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
            if (!filter.test(val)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.email, ""];
            }
          }
        }
        if (edtrul.integer === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            if (isNaN(val)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.integer, ""];
            }
            if ((val % 1 !== 0) || (val.indexOf('.') !== -1)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.integer, ""];
            }
          }
        }
        if (edtrul.date === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            if (cm[valref].formatoptions && cm[valref].formatoptions.newformat) {
              dft = cm[valref].formatoptions.newformat;
              if ($.jdm.formatter.date.masks.hasOwnProperty(dft)) {
                dft = $.jdm.formatter.date.masks[dft];
              }
            } else {
              dft = cm[valref].datefmt || "Y-m-d";
            }
            if (!$.jdm.checkDate(dft, val)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.date + " - " + dft, ""];
            }
          }
        }
        if (edtrul.time === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            if (!$.jdm.checkTime(val)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.date + " - hh:mm (am/pm)", ""];
            }
          }
        }
        if (edtrul.url === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            filter = /^(((https?)|(ftp)):\/\/([\-\w]+\.)+\w{2,3}(\/[%\-\w]+(\.\w{2,})?)*(([\w\-\.\?\\\/+@&#;`~=%!]*)(\.\w{2,})?)*\/?)/i;
            if (!filter.test(val)) {
              fmtMsg += ", " + $.jdm.edit.msg.fieldVal + " \"" + val + "\"";
              return [false, fmtMsg + ": " + $.jdm.edit.msg.url, ""];
            }
          }
        }
        if (edtrul.custom === true) {
          if (!(rqfield === false && $.jdm.isEmpty(val))) {
            if ($.isFunction(edtrul.custom_func)) {
              var ret = edtrul.custom_func.call(g, val, nm, valref);
              return $.isArray(ret) ? ret : [false, $.jdm.edit.msg.customarray, ""];
            }
            return [false, $.jdm.edit.msg.customfcheck, ""];
          }
        }
      }
      return [true, "", ""];
    }
  });
})(jQuery);

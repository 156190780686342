function insertAt(array, index) {
  var arrayToInsert = Array.prototype.splice.apply(arguments, [2]);
  return insertArrayAt(array, index, arrayToInsert);
}
function insertArrayAt(array, index, arrayToInsert) {
  Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
  return array;
}
Date.isDate = function(obj) {
  if (obj == null) return false;
  return !/Invalid|NaN/.test(new Date(obj).toString());
};
Date.formatDate = function(date, fmt) {
  if (fmt === undefined) fmt = 'YYYY-MM-DD';
  if (typeof date === 'object' && moment.isDate(date)) {
    return moment(date).format(fmt);
  }
  return null;
};
Date.daysBetween = function( date1, date2 ) {
  //Get 1 day in milliseconds
  var one_day=1000*60*60*24;
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms;
  // Convert back to days and return
  return Math.round(difference_ms/one_day);
};
Date.daysBetweenInYears = function(d2, d1) {
  if (("" + d2) === "" || ("" + d1) === "") return "";
  var startDate = moment($.jdm.parseDate(d2)), endDate = moment($.jdm.parseDate(d1));
  if (startDate > endDate) return startDate.diff(endDate, "years", true);

  return -1*endDate.diff(startDate, "years", true);
};
Date.daysBetweenInYears1 = function(d2, y, m) {
  y = y || 0;
  m = m || 0;
  if (("" + d2) === "" || (y == 0 && m == 0)) return "";
  var startDate = moment($.jdm.parseDate(d2)), endDate = moment().add(-1*y, 'years').add(-1*m, 'months');
  if (startDate > endDate) return startDate.diff(endDate, "years", true);

  return -1*endDate.diff(startDate, "years", true);
};
Date.daysBetweenInMonths = function(d2, d1) {
  if (("" + d2) === "" || ("" + d1) === "") return "";
  var startDate = moment($.jdm.parseDate(d2)), endDate = moment($.jdm.parseDate(d1));
  if (startDate > endDate) return startDate.diff(endDate, "months", true);

  return -1*endDate.diff(startDate, "months", true);
};
Date.daysBetweenInMonths1 = function(d2, y, m) {
  y = y || 0;
  m = m || 0;
  if (("" + d2) === "" || (y == 0 && m == 0)) return "";
  var startDate = moment($.jdm.parseDate(d2)), endDate = moment().add(-1*y, 'years').add(-1*m, 'months');
  if (startDate > endDate) return startDate.diff(endDate, "months", true);

  return -1*endDate.diff(startDate, "months", true);
};
Date.daysBetweenInDays = function(d2, d1) {
  if (("" + d2) === "" || ("" + d1) === "") return "";
  var startDate = moment($.jdm.parseDate(d2)), endDate = moment($.jdm.parseDate(d1)), ret = 0;
  if (startDate > endDate) {
    ret = startDate.diff(endDate, "days");
  } else {
    ret = endDate.diff(startDate, "days");
  }
  return ret;
};
Date.timeDiff = function(t2, t1) {
  function time2int(t) {
    return (""+t).substring(0, 2)*60*60 + (""+t).substring(3, 5)*1*60 + ((""+t).length === 8 ? ((""+t).substring(6, 8)*1) : 0);
  }
  var val2 = time2int(t2), val1 = time2int(t1); if (val1 === 0 || val2 === 0) return "";

  var val = val2 - val1,
      hours = Math.floor(val/(60*60)),
      mins = Math.floor((val - hours*60*60)/60);
  return $.jdm.lpad((""+hours), 2, '0') + ":" + $.jdm.lpad((""+mins), 2, '0');
};
Date.timeDiff2CStr = function(t2, t1) {
  function time2int(t) {
    return (""+t).substring(0, 2)*60*60 + (""+t).substring(3, 5)*1*60 + (""+t).substring(6, 8)*1;
  }
  var val2 = time2int(t2), val1 = time2int(t1); if (val1 === 0 || val2 === 0) return "";
  var val = val2 - val1,
      hours = Math.floor(val/(60*60)),
      mins = Math.floor((val - hours*60*60)/60),
      secs = val - hours*60*60 - mins*60;
  return $.jdm.lpad((""+hours), 2, '0') + ":" +  $.jdm.lpad((""+mins), 2, '0') + ":" + $.jdm.lpad((""+secs), 2, '0');
};
Date.geThan = function(dt2, dt1) {
  if (("" + dt2) === "" || ("" + dt1) === "") return "";

  var startDate = moment(dt2 instanceof Date ? dt2 : $.jdm.parseDate(dt2)),
      endDate = moment(dt1 instanceof Date ? dt1 : $.jdm.parseDate(dt1));
  return (startDate >= endDate);
};
Date.gtThan = function (dt2, dt1) {
  if (("" + dt2) === "" || ("" + dt1) === "") return "";

  var startDate = moment(dt2 instanceof Date ? dt2 : $.jdm.parseDate(dt2)),
      endDate = moment(dt1 instanceof Date ? dt1 : $.jdm.parseDate(dt1));
  return (startDate > endDate);
};
// Math MAX
Math.MAX = function() {
  if (arguments.length == 0) return null;

  var ret = arguments[0], val;
  for(var i = 0; i < arguments.length; i++) {
    val = arguments[i];
    if (!isNaN(parseFloat(val))) {
      val = 1*val;
      if (ret < val) {
        ret = val;
      }
    }
  }
  return ret;
};
// Math MIN
Math.MIN = function() {
  if (arguments.length == 0) return null;

  var ret = arguments[0], val;
  for(var i = 0; i < arguments.length; i++) {
    val = arguments[i];
    if (!isNaN(parseFloat(val))) {
      val = 1*val;
      if (ret > val) {
        ret = val;
      }
    }
  }
  return ret;
};
// Math SUM
Math.SUM = function() {
  var ret = 0, len = 0, val;
  for(var i = 0; i < arguments.length; i++) {
    val = arguments[i];
    if (!isNaN(parseFloat(val))) {
      ret += 1*val;
      len++;
    }
  }
  return (len !== 0 ? ret : null);
};
// Math AVG
Math.AVG = function() {
  /*
  var numbersArr = Array.prototype.slice.call(arguments);
	  //--CALCULATE AVAREGE--
	  var total = 0;
	  for(var key in numbersArr)
		  total += numbersArr[key];
	  var meanVal = total / numbersArr.length;

	  return meanVal;
   */
  var ret = 0, len = 0, val;
  for(var i = 0; i < arguments.length; i++) {
    val = arguments[i];
    if (!isNaN(parseFloat(val))) {
      val = parseFloat(val);
      if (val !== 0) {
        ret += val;
        len++;
      }
    }
  }
  return (len !== 0 ? ret/len : null);
};
Math.DeviationDegreeValue = function() {
  var numbersArr = Array.prototype.slice.call(arguments);
  //--CALCULATE AVAREGE--
  var total = 0;
  for(var key in numbersArr)
    total += numbersArr[key];
  var meanVal = total / numbersArr.length;
  //--CALCULATE AVAREGE--

  //--CALCULATE STANDARD DEVIATION--
  var SDprep = 0;
  for(var key in numbersArr)
    SDprep += Math.pow((parseFloat(numbersArr[key]) - meanVal),2);
  var SDresult = Math.sqrt(SDprep/(numbersArr.length - 1));

  return SDresult;
};
String.noExponents = function (n) {
  var [lead,decimal,pow] = n.split(/E|\./);
  return +pow <= 0
    ? "0." + "0".repeat(Math.abs(pow)-1) + lead + decimal
    : lead + ( +pow >= decimal.length ? (decimal + "0".repeat(+pow-decimal.length)) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
};
var Excel = {
  isLeapYear: function (year) {
    return new Date(year, 1, 29).getMonth() === 1;
  },

  daysBetween: function(start_date, end_date) {
    return Math.ceil((end_date - start_date) / 1000 / 60 / 60 / 24);
  },

  YEARFRAC: function(start_date, end_date, basis) {
    if ((typeof (start_date) === 'string' && start_date==='') || typeof (start_date) === 'undefined') { //ig added for empty data
      return ;
    }
    if ((typeof (end_date) === 'string' && end_date==='') || typeof (end_date) === 'undefined') { //ig added for empty data
      return ;
    }
    start_date = $.jdm.parseDate(start_date);
    if (start_date instanceof Error ) {
      return start_date;
    }
    end_date = $.jdm.parseDate(end_date);
    if (end_date instanceof Error) {
      return end_date;
    }

    basis = basis || 0;
    var sd = start_date.getDate();
    var sm = start_date.getMonth() + 1;
    var sy = start_date.getFullYear();
    var ed = end_date.getDate();
    var em = end_date.getMonth() + 1;
    var ey = end_date.getFullYear();

    switch (basis) {
      case 0:
        // US (NASD) 30/360
        if (sd === 31 && ed === 31) {
          sd = 30;
          ed = 30;
        } else if (sd === 31) {
          sd = 30;
        } else if (sd === 30 && ed === 31) {
          ed = 30;
        }
        return ((ed + em * 30 + ey * 360) - (sd + sm * 30 + sy * 360)) / 360;
      case 1:
        // Actual/actual
        var feb29Between = function (date1, date2) {
          var year1 = date1.getFullYear();
          var mar1year1 = new Date(year1, 2, 1);
          if (Excel.isLeapYear(year1) && date1 < mar1year1 && date2 >= mar1year1) {
            return true;
          }
          var year2 = date2.getFullYear();
          var mar1year2 = new Date(year2, 2, 1);
          return (Excel.isLeapYear(year2) && date2 >= mar1year2 && date1 < mar1year2);
        };
        var ylength = 365;
        if (sy === ey || ((sy + 1) === ey) && ((sm > em) || ((sm === em) && (sd >= ed)))) {
          if ((sy === ey && this.isLeapYear(sy)) ||
            feb29Between(start_date, end_date) ||
            (em === 1 && ed === 29)) {
            ylength = 366;
          }
          return this.daysBetween(start_date, end_date) / ylength;
        }
        var years = (ey - sy) + 1;
        var days = (new Date(ey + 1, 0, 1) - new Date(sy, 0, 1)) / 1000 / 60 / 60 / 24;
        var average = days / years;
        return this.daysBetween(start_date, end_date) / average;
      case 2:
        // Actual/360
        return this.daysBetween(start_date, end_date) / 360;
      case 3:
        // Actual/365
        return this.daysBetween(start_date, end_date) / 365;
      case 4:
        // European 30/360
        return ((ed + em * 30 + ey * 360) - (sd + sm * 30 + sy * 360)) / 360;
    }
  }
}

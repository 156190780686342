var App = {evt: "commit"}, pz = {};

const CHART_BORDER_COLORS = ['#809BCE', '#E0D7DA', '#B0D6D2', '#C7E5D9', '#8BAAD0', '#95B8D1', '#B8E0D2',
  '#D6EADF', '#DBE1DD', '#86A3CF', '#EAC4D5', '#ECC9D9', '#DA95B4', '#DD9FBB', '#7FC7AE', '#AEB3B5',
  '#97BDB2', '#449C7D', '#79A899', '#55A589', '#A63A6B', '#756B74', '#458730', '#766176'];
var _checkFieldValue = function ($t, col, value, iTab, iRow, iCol) {
    var ts = $t[0], cm = ts.p.colModel,
      pos = ts.getColIndex(col), fld = pos !== -1 ? cm[pos] : null,
      type = ((fld && fld.useropts) ? (fld.useropts.edittype ? fld.useropts.edittype : '?') : '?');


    var nrow = (fld && fld.tabn ? (fld.tabn !== 0 ? ((this.nrow === undefined && iRow !== undefined) ? iRow : this.nrow) : 0) : 0);

    var ret = $t.getCell(nrow, col);
    var valueArr = ("" + value).split(",");

    if (isNaN(ret) === false) {
      if (fld && fld.editrules && fld.editrules.number) {
        ret = parseFloat(ret);
        return valueArr.indexOf("" + ret) !== -1;
      } else if (fld && fld.editrules && fld.editrules.integer) {
        ret = parseInt(ret, 10);
        return valueArr.indexOf("" + ret) !== -1;
      }
    }
    if (type.startsWith("checkgroup") && (typeof ret == "boolean")) {
      return ((ret + '').indexOf(value) >= 0);
    }
    return (type.startsWith("checkgroup")) ? (ret.indexOf(value) >= 0) : (valueArr.indexOf("" + ret) !== -1);
  },
  _getFieldValue = function ($t, col) {
    var ts = $t[0], cm = ts.p.colModel,
      pos = ts.getColIndex(col), fld = pos !== -1 ? cm[pos] : null,
      nrow = fld && fld.tabn ? (fld.tabn !== 0 ? this.nrow : 0) : 0, ret = $t.getCell(nrow, col);
    if (isNaN(ret) === false) {
      if (fld && fld.editrules && fld.editrules.number) {
        return parseFloat(ret);
      } else if (fld && fld.editrules && fld.editrules.integer) {
        return parseInt(ret, 10);
      }
    }
    return ret;
  },
  //ig added  iTab, iRow, iCol
  _clearInputValue = function ($t, name, iTab, iRow, iCol) {
    iTab = iTab ?? 0;
    var _forChildInputs = false, colModel = $t[0].p.colModel.filter(v => v.tabn === iTab),
      clearInputValue = function (name) {
        const fld = colModel.find(v => v.index === name);
        if (fld) {
          $t.setCell(null, iRow !== undefined ? iRow : 0, fld.index, "", true);
          if (fld.__is_editors === false) {
            forChildFields(fld.index);
          }
        }
      },
      forChildFields = function (nm) {
        colModel.filter(v => v?.useropts?.parent?.name === nm).forEach(fld => {
          $t.setCell(null, 0, fld.index, "", true);
          _forChildInputs = true;
        });
      };
    clearInputValue(name);
    if (_forChildInputs) {
      if (App.evt === "commit") {
        var evt = new ScriptEnv($t, 0, 0);
        evt.autoFillParams(App, name, false);
        evt = null;
      }
    }
  },
  //
  /**
   * @param clear if true then clear input when disabled (default is true)
   */
  ValidateInput = function (field, fieldValue, names, clear, iTab, iRow, iCol) {
    const namesArr = ('' + names).split(";");
    for (const name of namesArr) {
      InputState(name, _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow, iCol) ? "enable" : "disable", clear, iTab, iRow, iCol);
    }
  },
  SetRequiredInput = function (field, fieldValue, names, iTab, iRow, iCol) {
    const namesArr = ('' + names).split(";");
    for (const name of namesArr) {
      SetRequiredState(name, _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow, iCol) ? "enable" : "disable");
    }
  },
  VisibleInput = function (field, fieldValue, names, iTab, iRow, iCol) {
    const namesArr = ('' + names).split(";");
    for (const name of namesArr) {
      const gridInputInd = pz.inputs.findIndex(v => v.field === name && v.editType === 'grid');
      if (gridInputInd >= 0) {
        const $el = $("div.docelement-grid.grid-cmp-" + gridInputInd, $(pz.$inst));
        if ($el.length > 0) {
          const state = _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow, iCol) ? "show" : "hide";
          if (state === "show") {
            $el.show();
          } else {
            $el.hide();
          }
        }
      } else {
        var $el = $("div[field=" + name + "]", $(pz.$inst));
        if ($el.length > 0) {
          if ($el.hasClass("field-group")) {
            //ig added iTab, iRow, iCol
            InputVisible(name, _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow, iCol) ? "show" : "hide");
          } else {
            //ig added iTab, iRow, iCol
            var state = _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow, iCol) ? "show" : "hide";
            if (state === "show") {
              $el.show();
            } else {
              $el.hide();
            }
          }
        }
      }
    }
  },
  //
  //ig added iTab, iRow, iCol
  SetElementStyle = function (field, style, iTab, iRow, iCol) {
    (pz.$inst).jqDm("setColStyle", field, style, iTab, iRow, iCol);
  },
  //
  ValidateTab = function (field, fieldValue, ids) {
    //ig added iTab, iRow, iCol
    var state = _checkFieldValue(pz.$inst, field, fieldValue, 0, 0, null) ? "enable" : "disable",
      groupsIds = ("" + ids).split(";"), groupNo;
    for (var i in groupsIds) {
      groupNo = groupsIds[i];
      for (let grp of (pz.groups || [])) {
        if (grp.grpType === +groupNo && (grp.tabn || 0) === 0) {
          grp.hidden = state !== 'enable';
        }
      }
      GroupState(groupNo, state);
    }

    const $c = pz.$inst[0];
    const $w = $(".dm-wizard", $c);

    const getNextVisibleTabs = function (stepNumber) {
      let cnt = 0;
      const navItems = $(".nav-item", $(".dm-wizard", $c));
      for (let i = 0; i < navItems.length; i++) {
        if (!$(navItems[i]).hasClass("hidden") && (stepNumber - 1) < i) {
          cnt++;
        }
      }
      return cnt;
    };
    const getPrevVisibleTabs = function (stepNumber) {
      let cnt = 0;
      const navItems = $(".nav-item", $(".dm-wizard", $c));
      for (let i = 0; i < navItems.length; i++) {
        if (!$(navItems[i]).hasClass("hidden") && (stepNumber - 1) > i) {
          cnt++;
        }
      }
      return cnt;
    };
    const navItemActive = $(".nav-item.active", $w),
      itemActive = $("a.nav-link", navItemActive),
      numStep = +itemActive.attr("href").substr(5);
    const cnt = getNextVisibleTabs(numStep);
    if (cnt === 0) {
      const editable = pz.$inst.jqDm("getDocStatus");
      $(".btn-next", $c).hide();
      if (editable && (pz?.access?.accessSubmit === undefined || pz?.access?.accessSubmit === true)) {
        $(".btn-finish", $c).show();
      } else if (editable && pz?.access?.accessWrite) {
        $(".btn-save", $c).show();
      }
    } else {
      if (getPrevVisibleTabs(numStep) === 0) {
        $(".btn-prev", $c).hide();
      } else {
        $(".btn-prev", $c).show();
      }
      $(".btn-next", $c).show();
      $(".btn-finish", $c).hide();
      $(".btn-save", $c).hide();
    }


  },
  //ig added
  DTValidateTab = function (field, fieldValue, ids, iTab, iRow) {
    if (iTab === undefined || iTab < 1) return;
    const grpThisTab = (pz.groups || []).filter(grp => (grp.tabn > 0 && grp.tabn === iTab));
    var state = _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow) ? "enable" : "disable",
      groupsIds = ("" + ids).split(";"), groupNo;
    for (var i in groupsIds) {
      groupNo = groupsIds[i];
      for (let grp of (grpThisTab)) {
        //ig if (grp.grpType === +groupNo && (grp.tabn || 0) === 0) {
        if (grp.grpType === +groupNo) {
          grp.hidden = state !== 'enable';
          _DTGroupState(groupNo, state, iTab);
          if (iTab > 0 && pz.$inst[0].p.DTTabulatesHidden.length >= iTab) {
            if (iRow !== undefined && iRow > -1) {
              pz.$inst[0].p.DTTabulatesHidden[iTab][iRow][groupNo - 1] = (grp.hidden ? 1 : 0)
            }
          }
        }
      }
    }
  },
  //ig added iTab, iRow, iCol
  ValidateTable = function (field, fieldValue, ids, iTab, iRow, iCol) {
    //ig added iTab, iRow, iCol
    var state = _checkFieldValue(pz.$inst, field, fieldValue, iTab, iRow, iCol) ? "enable" : "disable",
      groupsIds = ("" + ids).split(";"), groupNo;
    for (var i in groupsIds) {
      groupNo = groupsIds[i];
      _TableState(groupNo, state);
    }
  },
  ValidatePanel = function (field, fieldValue, ids) {
    var state = _checkFieldValue(pz.$inst, field, fieldValue) ? "enable" : "disable",
      groupsIds = ("" + ids).split(";"), groupNo;
    for (var i in groupsIds) {
      groupNo = groupsIds[i];
      _PanelState(groupNo, state);
    }
  },
  ValidateOrgLevelTab = function (levels, ids, state) {
    state = typeof (state) === 'undefined' ? "show" : ("" + state).toLowerCase();
    const userOrgLevel = pz.authuser?.orgLevel || -1, orgLevels = ("" + levels).split(";"), $w = $(".dm-wizard");
    const userPassed = (userOrgLevel === -1 && state === 'show') || (pz.authuser.username?.length && levels === '*')
      || orgLevels.indexOf("" + (userOrgLevel + 1)) >= 0;
    var tabsIds = ("" + ids).split(";"), tabId;
    for (let i in tabsIds) {
      tabId = tabsIds[i];
      const intTabId = parseInt(tabId, 10);
      if (state === "hideempty" && userPassed) {
        const found = pz.inputs.some(v => v.grpType === intTabId);
        if (!found) {
          $w.smartWizard("stepState", [intTabId - 1], "disable");
          $w.smartWizard("stepState", [intTabId - 1], "hide");
        }
      } else {
        const isEnable = state === "show" ? (userPassed ? "enable" : "disable") : (userPassed ? "disable" : "enable");
        const isShow = state === "show" ? (userPassed ? "show" : "hide") : (userPassed ? "hide" : "show");
        $w.smartWizard("stepState", [intTabId - 1], isEnable);
        $w.smartWizard("stepState", [intTabId - 1], isShow);
        $w.data('smartWizard').goToStep($w.data('smartWizard').current_index);
      }
    }
  },
  ValidateUserInputs = function (users, ids, state) {
    state = typeof (state) === 'undefined' ? "show" : ("" + state).toLowerCase();
    var uname = pz.authuser.username || '-', usersNms = ("" + users).toUpperCase().split(";");
    var inputsIds = ("" + ids).split(";"), inpId;
    const userPassed = usersNms.indexOf(("" + uname).toUpperCase()) >= 0;
    for (var i in inputsIds) {
      inpId = inputsIds[i];
      InputVisible(inpId, state === "show" ? (userPassed ? "show" : "hide") : (userPassed ? "hide" : "show"), false);
    }
  },
  ValidateUserTab = function (users, ids, state) {
    state = typeof (state) === 'undefined' ? "show" : ("" + state).toLowerCase();
    var uname = pz.authuser.username || '-', usersNms = ("" + users).toUpperCase().split(";"), $w = $(".dm-wizard");
    const userPassed = (pz.authuser.username?.length && users === '*') || usersNms.indexOf(("" + uname).toUpperCase()) >= 0;
    var tabsIds = ("" + ids).split(";"), tabId;
    for (let i in tabsIds) {
      tabId = tabsIds[i];
      const intTabId = parseInt(tabId, 10);
      if (state === "hideempty" && userPassed) {
        const found = pz.inputs.some(v => v.grpType === intTabId);
        if (!found) {
          $w.smartWizard("stepState", [intTabId - 1], "disable");
          $w.smartWizard("stepState", [intTabId - 1], "hide");
        }
      } else {
        const isEnable = state === "show" ? (userPassed ? "enable" : "disable") : (userPassed ? "disable" : "enable");
        const isShow = state === "show" ? (userPassed ? "show" : "hide") : (userPassed ? "hide" : "show");
        $w.smartWizard("stepState", [intTabId - 1], isEnable);
        $w.smartWizard("stepState", [intTabId - 1], isShow);
      }
    }
  },
  ValidateUserTables = function (users, ids, state) {
    state = typeof (state) === 'undefined' ? "show" : ("" + state).toLowerCase();
    var uname = pz.authuser.username || '-', usersNms = ("" + users).toUpperCase().split(";");
    if (usersNms.indexOf(("" + uname).toUpperCase()) >= 0) {
      var tabsIds = ("" + ids).split(";"), tabId;
      for (var i in tabsIds) {
        tabId = tabsIds[i];
        _TableState(tabId, state === "show" ? "enable" : "disable");
      }
    }
  },
// Used in several surveys in the private part
  ValidateUser = function (users, ids, state) {
    ValidateUserInputs(users, ids, state);
  },
  //ig added iTab, iRow, iCol
  InputVisible = function (name, state, enclear, iTab, iRow, iCol) {
    state = typeof (state) === 'undefined' ? "show" : ("" + state).toLowerCase();
    enclear = typeof (enclear) === 'undefined' ? true : enclear;
    (pz.$inst).jqDm("setColProp", name, {hidden: state === "hide"}, false, iTab, iRow, iCol);
    if (state === "show") {
      $("div.field-group[field=" + name + "]", $(pz.$inst)).show();
      $("div.field-label[field=" + name + "]", $(pz.$inst)).show();
    } else {
      $("div.field-group[field=" + name + "]", $(pz.$inst)).hide();
      $("div.field-label[field=" + name + "]", $(pz.$inst)).hide();
      if (enclear) {
        _clearInputValue(pz.$inst, name);
      }
    }
  },
  //ig added iTab, iRow, iCol
  InputState = function (name, state, clear, iTab, iRow, iCol) {
    state = typeof (state) === 'undefined' ? "enable" : ("" + state).toLowerCase();
    (pz.$inst).jqDm("setColProp", name, {editable: state === "enable"}, false, iTab, iRow, iCol);

    if (state === "disable" && clear !== false) {
      _clearInputValue(pz.$inst, name, iTab, iRow, iCol);
    }
  },
  //ig added iTab, iRow, iCol
  SetRequiredState = function (name, state, iTab, iRow, iCol) {
    state = typeof (state) === 'undefined' ? "enable" : ("" + state).toLowerCase();
    (pz.$inst).jqDm("setColProp", name, {editrules: {required: state === 'enable'}}, false, iTab, iRow, iCol);
  },
  //ig added iTab, iRow, iCol
  SetColumnMinValue = function (name, val, iTab, iRow, iCol) {
    val = typeof (val) === 'undefined' ? null : val;
    var cm = (pz.$inst).jqDm("getColProp", name), edrules = cm.editrules || {};
    if (val === null) {
      delete edrules["minValue"];
    } else {
      edrules['minValue'] = parseInt(val);
    }
    (pz.$inst).jqDm("setColProp", name, {editrules: edrules}, false, iTab, iRow, iCol);
  },
  //ig added iTab, iRow, iCol
  SetColumnMaxValue = function (name, val, iTab, iRow, iCol) {
    val = typeof (val) === 'undefined' ? null : val;
    var cm = (pz.$inst).jqDm("getColProp", name), edrules = cm.editrules || {};
    if (val === null) {
      delete edrules["maxValue"];
    } else {
      edrules['maxValue'] = parseInt(val);
    }
    (pz.$inst).jqDm("setColProp", name, {editrules: edrules}, false, iTab, iRow, iCol);
  },
  GetCountryRec = function (targetNm, countryCode, partNm) {
    partNm = partNm || 'name';
    if (("" + countryCode).length !== 0) {
      $.jdm.getCountryRecord(countryCode, function (rec) {
        if (rec && typeof (rec[partNm]) !== 'undefined') {
          pz.$inst.jqDm("setCellValue", 0, targetNm, rec[partNm]);
        }
      });
    }
  },
  GetHydrationFactor = function (targetNm, sex, age) {
    if (sex && age && isNaN(sex) === false && isNaN(age) === false) {
      $.jdm.getTBWHydrationFactor(sex, age, function (rec) {
        if (rec && typeof (rec["koef"]) !== 'undefined') {
          pz.$inst.jqDm("setCellValue", 0, targetNm, rec["koef"]);
        }
      });
    }
  },

  GroupState = function (groupNo, state) {
    var $w = $(".dm-wizard");
    $w.smartWizard("stepState", [parseInt(groupNo, 10) - 1], state === "enable" ? "show" : "hide");
    const navItemActive = $(".nav-item.active", $w),
      itemActive = $("a.nav-link", navItemActive),
      numStep = +itemActive.attr("href").substr(5);
    if (numStep === 1 && navItemActive.hasClass('hidden')) {
      $w.smartWizard("next");
      let i = 0;
      while (true) {
        i++;
        const navItemActive1 = $(".nav-item.active", $w);
        if (navItemActive1 && navItemActive1.hasClass('hidden')) {
          $w.smartWizard("next");
        } else {
          break;
        }
        if (i > 50) {
          break;
        }
      }
    }
    if (state !== "enable") {
      var $t = pz.$inst, colModel = $t[0].p.colModel,
        forTabFields = function (tabIndex) {
          $(colModel).each(function () {
            var fld = this, useropts = fld.useropts || {}, grpType = useropts.grpType || 0;
            if (parseInt(grpType, 10) === parseInt(tabIndex, 10)) {
              _clearInputValue($t, fld.index);
            }
          });
        };
      forTabFields(groupNo);
    }
  },
  _DTGroupState = function (groupNo, state, iTab) {
    const dynTabCmp = $('#dynTabRowEdit');
    if (!dynTabCmp) {
      return;
    }
    $(".dm-wizard", $(dynTabCmp)).smartWizard("stepState", [parseInt(groupNo, 10) - 1], state === "enable" ? "show" : "hide");
    if (state !== "enable") {
      var $t = pz.$inst, colModel = $t[0].p.colModel.filter(v => v.index === iTab),
        forTabFields = function (tabIndex) {
          $(colModel).each(function () {
            var fld = this, useropts = fld.useropts || {}, grpType = useropts.grpType || 0;
            if (parseInt(grpType, 10) === parseInt(tabIndex, 10)) {
              _clearInputValue($t, fld.index, iTab);
            }
          });
        };
      forTabFields(groupNo);
    }
  },
  _TableState = function (tableNum, state) {
    var $tbody = $(".table-template[p=template\\:TAB" + tableNum + "]"), $table = $tbody.parent("table");
    if (state === "enable") {
      $table.show();
    } else {
      $(pz.$inst).jqDm("emptyTab", parseInt(tableNum), true);
      $table.hide();
    }
  },
  _PanelState = function (groupNo, state) {
    if (state === 'enable') {
      $("div.panel-" + ("" + groupNo).toLowerCase()).show();
    } else {
      var $t = pz.$inst;
      $("div.panel-" + ("" + groupNo).toLowerCase()).hide();
      $("div.panel-" + ("" + groupNo).toLowerCase()).each(function () {
        var $inp = $(this), attrField = $inp.attr("field");
        if (typeof (attrField) !== 'undefined') {
          _clearInputValue($t, $inp.attr("field"));
        }
      });

      $('[field]:not([field=""])', $("div.panel-" + ("" + groupNo).toLowerCase())).each(function () {
        var $inp = $(this);
        _clearInputValue($t, $inp.attr("field"));
      });
    }
  },
  FilterSelect = function (targetNm, parValue) {
    const inst = pz.$inst || [], ts = inst[0], cm = ts.p.colModel,
      pos = ts.getColIndex(targetNm), fld = pos !== -1 ? cm[pos] : null,
      nrow = fld && fld.tabn ? (fld.tabn !== 0 ? this.nrow : 0) : 0,
      fldVal = inst.getCell(nrow, targetNm);
    if (fld && fld.editoptions && fld.editoptions.cascade) {
      const cc = $("#" + targetNm);
      const newValue = fld.editoptions.valueFull.split(';')
        .filter(v =>
          v.split(':')[3].split(',').indexOf(parValue) >= 0)
        .map(v => v.split(':')[0] + ':' + v.split(':')[1])
        .join(';');
      let needClear = true;
      if (newValue !== fld.editoptions.value) {
        $("option", cc).remove();
        if (newValue.length > 0) {
          for (const v of newValue.split(';')) {
            const sv = v.split(':');
            const ov = document.createElement("option");
            ov.setAttribute("role", "option");
            if (fldVal === sv[0]) {
              needClear = false;
              ov.setAttribute('selected', 'selected');
            }
            ov.value = sv[0];
            ov.innerHTML = sv[1];
            cc.append($(ov));
          }
        }
        fld.editoptions.value = newValue;
        if (needClear) {
          _clearInputValue(inst, targetNm);
        } else {
          pz.$inst.jqDm("setCellValue", 0, targetNm, fldVal);
        }
        if (fld.edittype === 'combobox') {
          $(cc).selectpicker('refresh');
        }
      }
    }
  },
  FilterDict = function (targetNm, parValue) {
    let inst = pz.$inst || [], ts = inst[0], cm = ts.p.colModel,
      pos = ts.getColIndex(targetNm), fld = pos !== -1 ? cm[pos] : null,
      nrow = fld && fld.tabn ? (fld.tabn !== 0 ? this.nrow : 0) : 0,
      fldVal = inst.getCell(nrow, targetNm);
    if (fld && fld.useropts && fld.useropts.dctName && fld.useropts.dctParentName) {
      const edittype = fld.useropts.edittype;
      if (edittype.startsWith("radiogroup") || edittype.startsWith("checkgroup")) {
        ts.beginReq();
        const fieldName = targetNm;
        pz.columnModel = pz.columnModel.filter(v => !v.index.startsWith(fieldName + '_'));
        cm.map((v, i) => v.index.startsWith(fieldName + '_') ? i : -1).filter(v => v !== -1).reverse()
          .forEach(v => cm.splice(v, 1));
        Object.keys(ts.p.data[0]).forEach(k => {
          if (k.startsWith(fieldName + '_')) {
            delete ts.p.data[0][k];
          }
        });
        const _formatter = edittype.indexOf("radiogroup") !== -1 ? "rdDMGroupWithLabel" : "chkDMGroupWithLabel";
        $.jdm.getDictData(fld.useropts.dctName, fld.useropts.dctValueField, fld.useropts.dctParentName,
          fld.useropts.dctParentValueField, (parValue || '').replaceAll(';', ','), function (data) {
            const arrValues = data.map(v => v.value);
            const ul = $('[field="' + fieldName + '"] ul');
            $("li", ul).remove();
            let fieldValue = '';
            data.forEach((v, i) => {
              const newNm = fieldName + "_" + i;
              const col = {
                index: newNm,
                name: newNm,
                label: v.label,
                editable: fld.editable,
                edittype: "checkbox",
                formatter: _formatter,
                tabn: 0,
                __is_editors: true,
                editoptions: {
                  value: "1:0"
                },
                useropts: {
                  parent: {
                    name: fieldName,
                    value: v.value
                  },
                  afterSave: $.jdm.radiogroup__generateAfterSaveFunc((_formatter === "rdDMGroupWithLabel" || _formatter === "rdDMGroup") ? "radiogroup" : "checkgroup", fieldName, i + '', arrValues)
                }
              };
              const colpos = cm.length;
              pz.columnModel.push(col);
              cm.push(col);
              const li = document.createElement("li");
              li.innerHTML = "<div class='fc' p='" + newNm + "'></div>";
              ul.append($(li));
              ts.chkUIEditor.call($(ts), $($("div.fc[p='" + newNm + "']")[0]), col, colpos);
              ts.p.data[0][newNm] = (ts.p.data[0][fieldName] + ';').indexOf(v.value + ';') !== -1 ? 1 : '';
              const v1 = (ts.p.data[0][fieldName] + ';').indexOf(v.value + ';') !== -1 ? 1 : 0;
              if (v1 === 1) {
                fieldValue += (fieldValue === '' ? '' : ';') + v.value;
              }
              ts.setFieldValue.call($(ts), $(".dm-field[p=" + newNm + "]", $(ts)), col, v1, ts.formatter.call($(ts), newNm, v1, colpos, ts.p.data[0], 'edit'));
            });
            if (pz.mode === false) {
              $('div.dm-field', $(ts)).addClass('dm-field-readonly');
              $('input[type="checkbox"]', $('div.dm-field', $(ts))).attr("disabled", "disabled");
            }
            ts.p.data[0][fieldName] = fieldValue;
            ts.endReq();
          });

      } else {
        const cc = $("#" + targetNm);
        $.jdm.getDictData(fld.useropts.dctName, fld.useropts.dctValueField, fld.useropts.dctParentName,
          fld.useropts.dctParentValueField, parValue, function (data) {
            let needClear = true;
            if (cc.children().length) {
              $("option", cc).remove();
            }
            if (data.length > 0) {
              for (const v of data) {
                const ov = document.createElement("option");
                ov.setAttribute("role", "option");
                if (fldVal === v.value) {
                  needClear = false;
                  ov.setAttribute('selected', 'selected');
                  cc.removeClass("errorSelectObject");
                }
                ov.value = v.value;
                ov.innerHTML = v.label;
                cc.append($(ov));
              }
            }
            if (needClear) {
              _clearInputValue(inst, targetNm);
            } else {
              pz.$inst.jqDm("setCellValue", 0, targetNm, fldVal);
            }
            $(cc).selectpicker('refresh');
          });
      }
    }
  },
  PaintChart = function (targetNm) {
    const inst = pz.$inst || [], ts = inst[0], cm = ts.p.colModel,
      pos = ts.getColIndex(targetNm), fld = pos !== -1 ? cm[pos] : null;
    const chartType = fld?.useropts?.chart?.type;
    if (chartType?.length && pz.charts[fld.useropts.chart.index]) {
      const chart = pz.charts[fld.useropts.chart.index];
      const chartOptions = fld.useropts.chart.options;
      const backgroundColors = chartOptions?.backgroundColor;
      const borderColors = chartOptions?.borderColor;
      const legend = chartOptions?.legend;
      const label = chartOptions?.label;
      const tableVertical = chartOptions?.tableVertical;
      let datasets = [];
      const dataFields = (fld.useropts.chart?.data || []);
      const tabn = Math.max(...[].concat(...dataFields).map(fieldName => (cm.find(v => v.index === fieldName) || {tab: 0}).tabn));
      const tabLen = tabn === 0 ? 1 : inst.getTabLen(tabn);
      let showLegend = tabLen > 1;
      const currLabels = [];
      if (tabn > 0 && (tableVertical || tableVertical === undefined)) {
        if (label && label[0] && label[0].startsWith('#')) {
          let labelName = label[0].substring(1);
          const li = ts.getColIndex(labelName);
          if (li !== -1) {
            const field = cm[li];
            for (let ind = 0; ind < tabLen; ind++) {
              currLabels.push(inst.getCell(ind, field.index));
            }
          }
        } else {
          for (let ind = 0; ind < tabLen; ind++) {
            currLabels.push('Row ' + (ind + 1));
          }
        }
        const curDataFields = dataFields[0] || [];
        const currBackgroundColors = backgroundColors?.length && backgroundColors[0].length && backgroundColors[0];
        const currBorderColors = borderColors?.length && borderColors[0].length && borderColors[0];
        Array.from(Array(curDataFields.length).keys()).forEach(ncol => {
          const currLegend = legend?.length && legend[ncol].length && legend[ncol];
          if (!curDataFields[ncol]) {
            return;
          }
          let options;
          if (chartType === 'line') {
            const borderColor =
              (currBorderColors?.length && currBorderColors[ncol]) ||
              (backgroundColors?.length && backgroundColors[ncol])
              || CHART_BORDER_COLORS;
            options = {
              fill: false,
              tension: 0.1,
              borderColor: borderColor
            };
          } else if (chartType === 'radar') {
            const borderColor =
              (currBorderColors?.length && currBorderColors[ncol]) ||
              (currBackgroundColors?.length && currBackgroundColors[ncol])
              || CHART_BORDER_COLORS[ncol];
            const backgroundColor = (currBackgroundColors?.length && currBackgroundColors[ncol]) || 'rgba(0, 0, 0, 0)';
            options = {
              borderColor: borderColor,
              backgroundColor: backgroundColor
            };
          } else {
            const borderColor =
              (currBorderColors?.length && currBorderColors[ncol]) ||
              (backgroundColors?.length && backgroundColors[ncol])
              || CHART_BORDER_COLORS;
            const backgroundColor = (currBackgroundColors?.length && currBackgroundColors[ncol]) || 'rgba(0, 0, 0, 0)';
            options = {
              borderWidth: 1,
              borderColor: borderColor,
              backgroundColor: backgroundColor
            };
          }
          showLegend = true;
          options.label = currLegend;
          const data = [];
          for (let ind = 0; ind < tabLen; ind++) {
            const fieldVal = inst.getCell(ind, curDataFields[ncol]);
            data.push(isNaN(fieldVal) ? null : fieldVal);
          }
          datasets.push({
            ...options,
            data: data
          });
        });
      } else {
        if (label?.length) {
          for (let labelName of label) {
            if (labelName.startsWith('#')) {
              labelName = label[0].substring(1);
              const li = ts.getColIndex(labelName);
              if (li !== -1) {
                const field = cm[li];
                currLabels.push(inst.getCell(0, field.index));
              } else {
                currLabels.push(labelName);
              }
            } else {
              currLabels.push(labelName);
            }
          }
        }
        Array.from(Array(tabLen).keys()).forEach(nrow => {
          dataFields.forEach((curDataFields, currInd) => {
            const colorInd = tabLen > 1 ? nrow : currInd;
            const currBackgroundColors = backgroundColors?.length && backgroundColors;
            const currBorderColors = borderColors?.length && borderColors;
            const currLegend = legend?.length && legend[colorInd];
            let options;
            if (chartType === 'line') {
              const borderColor =
                (currBorderColors?.length && currBorderColors[colorInd]) ||
                (backgroundColors?.length && backgroundColors[colorInd])
                || CHART_BORDER_COLORS;
              options = {
                fill: false,
                tension: 0.1,
                borderColor: borderColor
              };
            } else if (chartType === 'radar') {
              const borderColor =
                (currBorderColors?.length && currBorderColors[colorInd]) ||
                (currBackgroundColors?.length && currBackgroundColors[colorInd])
                || CHART_BORDER_COLORS[colorInd];
              const backgroundColor = (currBackgroundColors?.length && currBackgroundColors[colorInd]) || 'rgba(0, 0, 0, 0)';
              options = {
                borderColor: borderColor,
                backgroundColor: backgroundColor
              };
            } else {
              const borderColor =
                (currBorderColors?.length && currBorderColors[colorInd]) ||
                (backgroundColors?.length && backgroundColors[currInd])
                || CHART_BORDER_COLORS;
              const backgroundColor = (currBackgroundColors?.length && currBackgroundColors[colorInd]) || 'rgba(0, 0, 0, 0)';
              options = {
                borderWidth: 1,
                borderColor: borderColor,
                backgroundColor: backgroundColor
              };
            }
            if (tabLen > 1) {
              options.label = 'Row ' + (nrow + 1);
            } else if (currLegend) {
              showLegend = true;
              options.label = currLegend;
            }
            datasets.push({
              ...options,
              data:
                curDataFields.map(fieldName => {
                  const fieldVal = inst.getCell(nrow, fieldName);
                  return isNaN(fieldVal) ? null : fieldVal;
                })
            });
          })
        });
      }
      if (showLegend) {
        chart.options.plugins.legend.display = true;
      }
      if (currLabels.length) {
        chart.data.labels = currLabels;
      }
      chart.data.datasets = datasets;
      chart.update();
    }
  },
  setRangeValue = function (range) {
    const newValue = (range.value - range.min) * 100 / (range.max - range.min);
    const velEl = range.previousElementSibling;
    velEl.innerHTML = `<span>${range.value}</span>`;
    velEl.firstElementChild.style.left = `calc(${newValue}% - (${newValue * 0.15 + 8}px))`;
  },
  FillFields = function (fields, checkErrors, checkErrorsMessage, nrow) {
    if (checkErrors && checkErrorsMessage?.length) {
      const res = pz.$inst.jqDm('check');
      if (!res.valid) {
        $.jdm.info_dialog("Warning!", checkErrorsMessage, "error");
        return;
      }
    }
    if (fields?.length) {
      const list = fields.split(',');
      list.forEach(attr => {
        const arr = attr.split('=');
        if (arr.length === 2) {
          const field = arr[0].trim();
          const valTmpl = arr[1].trim();
          let val = '';
          switch (valTmpl) {
            case '#currdate':
              val = $.jdm.getCurrentDate();
              break;
            case '#userName':
              val = pz?.authuser?.username;
              break;
            case '#userFullName':
              val = pz?.authuser?.fullname;
              break;
            case '#userEmail':
              val = pz?.authuser?.email;
              break;
          }
          pz.$inst.jqDm("setCellValue", nrow, field, val);
        }
      });
    }
  },
  ShowHelpWindow = function (targetNm) {
    const inst = pz.$inst || [], ts = inst[0], cm = ts.p.colModel,
      pos = ts.getColIndex(targetNm), fld = pos !== -1 ? cm[pos] : null;
    $.jdm.open_dialog(fld.shortName || fld.label || 'Help text', fld?.useropts?.helpText, 'help');
  }
;

/**------------------------------------------------------------------------------------
 * Event "EvtAfterSaveCell"
 **-----------------------------------------------------------------------------------*/
var funcAfterSaveCell_RTA = async function ($inst, name, val) {
  if (name.startsWith("NumberOfReactors")) {
    val = $inst.jqDm("getCell", 0, "NumberOfReactors");
    pz.dynamicVars.cols = parseInt(val, 10);
    await $.jdm.saveWithoutValidating();
    DocumentDestroy(pz);
    DocumentInit(pz, false, pz.callbackGetData);
  }
};

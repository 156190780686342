var setTemplateVars = function($c, pz) {
  var colModel = [], _edittype, _editable, _edrules;
  var  _paintGroup = function(inp, ind, userOpts) {
    var _htmlList = userOpts && userOpts.listOpts ? userOpts.listOpts : "",
        _arr = (""+_htmlList).split(";"), $ptr = $("div.fc[p=" + inp.index + "]", $c),
        _formatter = _edittype.indexOf("radiogroup") !== -1 ? "rdDMGroupWithLabel" : "chkDMGroupWithLabel",
        _res = "", _r, $tbl = "", _newNm, _arrValues = [];
    for(var i in _arr) {
      _r = _arr[i];
      var _recItems = (""+_r).split(":"),
          _vl = _recItems[0], _lbl = _recItems.length > 1 ? _recItems[1] : _vl;
      _res += (_res.length > 0 ? ";" : "") + (_vl + ":" + _lbl);
      _arrValues.push(_vl);
    }
    for(var i in _arr) {
      _newNm = inp.index + "_" + i;

      _r = _arr[i];
      var _recItems = (""+_r).split(":"),
        _vl = _recItems[0],
        _lbl = _recItems.length > 1 ? _recItems[1] : _vl;
      colModel.push({
        index: _newNm,
        name: _newNm,
        label: _lbl,
        editable: _editable,
        edittype: "checkbox",
        formatter: _formatter,
        tabn:0,
        __is_editors:true,
        editoptions: {
          value: "1:0"
        },
        useropts: {
          parent: {
            name: inp.index,
            value: _arrValues[i]
          },
          afterSave: $.jdm.radiogroup__generateAfterSaveFunc((_formatter === "rdDMGroupWithLabel" || _formatter === "rdDMGroup") ? "radiogroup" : "checkgroup", inp.index, i, _arrValues)
        }
      });

      $tbl += "<li>" +
        "<div class='fc' p='" + (inp.index + "_" + i) + "'></div>" +
        "</li>";
    }

    var _cssGroupAnswers = "field-group-answers";
    if (_edittype.indexOf(":vertical") !== -1) _cssGroupAnswers = "field-group-answers-vertical";
    if (_edittype.indexOf(":horizontal") !== -1) _cssGroupAnswers = "field-group-answers-horizontal";
    if (_cssGroupAnswers === "field-group-answers") _cssGroupAnswers = "field-group-answers-horizontal";
    var __styleCols="";
    if (_edittype.indexOf(":vertical") !== -1)  {
      var pos_col=_edittype.indexOf(":cols")
      if   (pos_col!== -1) {
        var cols = parseInt(_edittype.substr(pos_col+5,1), 10);
        if (!isNaN(cols) && (cols>1) && (cols<=12)) {
          __styleCols=' style="columns:'+cols+';" ';
        }
      }
    }
    $tbl = "<ul class='" + _cssGroupAnswers + "'"+__styleCols+">" + $tbl + "</ul>";


    $ptr.replaceWith($tbl);
  };
    // Processing inputs received from the backend
  $(".fc", $c).each(function() {
    var $fld = $(this), classList = $(this).attr('class').split(/\s+/);
    $(classList).each(function() {
      var cls = this, id; // class item
      if (cls.startsWith("field:")) {
        id = cls.substr(6);
        $fld
          .attr("p", id)
          .removeClass(cls);
      }
    });
  });
  // Processing dynamic tables
  $(".table-template", $c).each(function () {
    var $tbl = $(this), classList = $(this).attr('class').split(/\s+/);
    $(classList).each(function () {
      var cls = this, tabn; // class
      if (cls.startsWith("tab:")) {
        tabn = cls.substr(4);
        $tbl
          .attr("p", "template:TAB" + tabn)
          .removeClass(cls);
      }
    });
  });

  // Processing dynamic tables
  $(".table-template-row", $c).each(function () {
    var $row = $(this), classList = $(this).attr('class').split(/\s+/);
    $(classList).each(function() {
      var cls = this, tabn; // class
      if (cls.startsWith("tab:")) {
        tabn = cls.substr(4);
        $row
          .attr("p", "template:TAB" + tabn)
          .removeClass(cls);
        $(".fc", $row).each(function() {
          var $inp = $(this), id = $inp.attr("p");
          $inp
            .attr("p", "TAB" + tabn + "(?)." + id)
           // .attr("tabno", tabn)
        });
      }
    });
  });
  // Processing the form's column model
  $(pz.columnModel).each(function(ind) {
    var inp = this, _userOpts = inp.useropts || {};
    _edittype = _userOpts.edittype || "text";
    _editable = inp.editable || true;
    _edrules = inp.editrules;
    if (_edittype !== "") {
      if (_edittype.indexOf("radiogroup") !== -1 || _edittype.indexOf("checkgroup") !== -1) {
        inp.__is_editors = false;
        _userOpts["childGroup"] = true;
        _paintGroup(inp, ind, _userOpts);
      } else if (_edittype.indexOf("file") !== -1) {
        inp.edittype = "checkbox";
        inp.formatter = "file";
      } else if (_edittype.indexOf("orderlist") !== -1) {

      }
    }
    colModel.push(inp);
  });
  pz.columnModel = colModel;
  return pz;
};

var checkDocumentVars = function ($c, pz) {
  var _getValueName = function (num, enShort) {
    enShort = enShort === undefined ? false : enShort;
    return (enShort ? "R#" : "Reactor#") + (1 * num + 1);
  };
  if (pz.dataVars) {
    if (pz.dataVars.length > 0 && pz.dataVars !== "null") {
      try {
        pz.dataVars = JSON.parse(pz.dataVars);
        if (pz.dataVars.dynamicVars && pz.dataVars.dynamicVars.cols) {
          if (pz.dataVars.dynamicForm && pz.dataVars.dynamicVars.cols !== 0) {
            var dynamicForm = false, dynamicVars = {cols: 0};
            $(pz.inputs).each(function (i) {
              var inp = this,
                _edittype = typeof (inp.editType) !== 'undefined' ? (inp.editType !== "" ? inp.editType.toLowerCase() : "text") : "text";
              // dynamic-group
              if (_edittype === "dynamic-group") {
                if (typeof (inp.__values) === 'undefined') {
                  dynamicForm = true;
                  var _valuesArr = [];
                  for (var iii = 0; iii < pz.dataVars.dynamicVars.cols; iii++) {
                    _valuesArr.push(_getValueName(iii, true));
                  }
                  dynamicVars.cols = pz.dataVars.dynamicVars.cols;

                  inp.__values = _valuesArr;
                  inp.__is_dynamic = true;

                  pz.inputs[i] = inp;
                }
              }
              // dynamic-group-key
              if (_edittype === "dynamic-group-key") {
                if (typeof (inp.__values) === 'undefined') {
                  dynamicForm = true;
                  inp.__values = [];
                  inp.__is_dynamic = true;

                  pz.inputs[i] = inp;
                }
              }
              // dynamic-choice
              if (_edittype === "dynamic-choice") {
                if (typeof (inp.__values) === 'undefined') {
                  dynamicForm = true;
                  var _valuesArr = [];
                  for (var iii = 0; iii < pz.dataVars.dynamicVars.cols; iii++) {
                    _valuesArr.push(_getValueName(iii));
                  }
                  inp.__values = _valuesArr;
                  inp.__is_dynamic = true;

                  pz.inputs[i] = inp;
                }
              }
            });
            pz.dynamicForm = dynamicForm;
            pz.dynamicVars = dynamicVars;
          }
        }
      } catch (_) {
      }
    }
    delete pz.dataVars;
  }
  // preparing for rules & column model
  var inputs = pz.inputs || [], rules = pz.rules || [];
  dynamicForm = false, dynamicVars = {cols: 0},
    _dynamicForm = typeof (pz.dynamicForm) !== 'undefined' ? pz.dynamicForm : false,
    _groups = [];
  if (_dynamicForm) {
    inputs = inputs.filter(function (value, index, arr) {
      var isGenerate = typeof (value.__is_generate) !== 'undefined' ? value.__is_generate : false;
      return isGenerate == false;
    });
    rules = rules.filter(function (value, index, arr) {
      var isGenerate = typeof (value.__is_generate) !== 'undefined' ? value.__is_generate : false;
      return isGenerate == false;
    });
  }

  $(inputs).each(function (i) {
    var inp = this,
      _edittype = typeof (inp.editType) !== 'undefined' ? (inp.editType !== "" ? inp.editType.toLowerCase() : "text") : "text",
      _isDynamic = typeof (inp.__is_dynamic) !== 'undefined' ? inp.__is_dynamic : false;
    // Dynamic-group
    if (_edittype === "dynamic-group") {
      if (typeof (inp.__values) === 'undefined' || _isDynamic) {
        dynamicForm = true;
        var _valuesArr = [];

        if (_isDynamic) {
          var _valuesArr1 = inp.__values;
          _valuesArr1.splice(1); // 1
          _valuesArr1.splice(_valuesArr.length - 1); // 1
          if (pz.dynamicVars.cols < (_valuesArr.length - 1)) {
            _valuesArr1.splice(pz.dynamicVars.cols);
          } else {
            for (var iii = _valuesArr.length; iii < pz.dynamicVars.cols; iii++) {
              _valuesArr1.push(_getValueName(iii, true));
            }
          }
          _valuesArr.push("%");
          _valuesArr.push("Rationale for percentage");
          for (var iii in _valuesArr1) {
            _valuesArr.push(_valuesArr1[iii]);
          }
          _valuesArr.push("Rationale for score");

          dynamicVars.cols = pz.dynamicVars.cols;
        } else {
          inp.__is_dynamic = true;
          _valuesArr.push("%");
          _valuesArr.push("Rationale for percentage");
          for (var iii = 0; iii < 3; iii++) {
            _valuesArr.push(_getValueName(iii, true));
          }
          _valuesArr.push("Rationale for score");
          dynamicVars.cols = 3;
        }
        inp.__values = _valuesArr;

        _groups.push(inp.field);
        inputs[i] = inp;
        inputs[i + 1].__is_group = true;
      }
    }
    // Dynamic-group
    if (_edittype === "dynamic-choice") {
      if (typeof (inp.__values) === 'undefined' || _isDynamic) {
        dynamicForm = true;
        var _valuesArr = [];

        if (_isDynamic) {
          _valuesArr = inp.__values;
          _valuesArr.splice(_valuesArr.length - 1);
          if (pz.dynamicVars.cols < _valuesArr.length) {
            _valuesArr.splice(pz.dynamicVars.cols);
          } else {
            for (var iii = _valuesArr.length; iii < pz.dynamicVars.cols; iii++) {
              _valuesArr.push(_getValueName(iii));
            }
          }
        } else {
          inp.__is_dynamic = true;
          for (var iii = 0; iii < 4; iii++) {
            _valuesArr.push(_getValueName(iii));
          }
        }
        inp.__values = _valuesArr;

        _groups.push(inp.field);
        inputs[i] = inp;
        inputs[i + 1].__is_group = true;
      }
    }
    // Dynamic-group-key
    if (_edittype === "dynamic-group-key") {
      // if (typeof(inp.__values) === 'undefined' || _isDynamic) {
      dynamicForm = true;
      inp.__values = ["%", "Rationale for Weights (Percentage)"];

      _groups.push(inp.field);
      inputs[i] = inp;
      inputs[i + 1].__is_group = true;
      // }
    }
  });
  var _rls = {}, _rlsKoef = {},
    _formRule = function (ind, expr) {
      var s = _rls[ind];
      _rls[ind] = (s != "" ? (s + ",") : "") + expr;
    };
  {
    var _getSummaryInp = function () {
        var ret = null;
        $(inputs).each(function (i) {
          var inp = inputs[i],
            edittype = typeof (inp.editType) !== 'undefined' ? (inp.editType !== "" ? inp.editType.toLowerCase() : "text") : "text";
          if (edittype === "dynamic-summary") {
            ret = inp;
            return false;
          }
        });
        return ret;
      },
      items = [],
      _summaryInp = _getSummaryInp(), _indSummary = _summaryInp && _summaryInp.grpType ? _summaryInp.grpType : -1;
    for (var indCol = 0; indCol < dynamicVars.cols; indCol++) {
      items.push({
        field: 'SM_' + indCol,
        label: _getValueName(indCol),
        type: "xs:decimal",
        dataType: "NUMBER",
        cntGroup: 0,
        base: "xs:decimal",
        editType: "text",
        editable: 0,
        isConsolidate: 1,
        linePlace: 0,
        required: 0,
        visible: _indSummary === -1 ? 0 : 1,
        grpType: _indSummary === -1 ? 1 : _summaryInp.grpType,
        decimalPlaces: 2,
        decimalSeparator: ".",
        __is_generate: true,
        __is_summary: true
      });
      _rls["SM_" + indCol] = "";
    }
    insertArrayAt(inputs, _indSummary !== -1 ? _indSummary : 0, items);
  }

  for (var iii in _groups) {
    var nm = _groups[iii];

    $(inputs).each(function (jjj) {
      var inp = inputs[jjj], _edittype, items;
      if (inp.field === nm) {
        _edittype = typeof (inp.editType) !== 'undefined' ? (inp.editType !== "" ? inp.editType.toLowerCase() : "text") : "text";
        if (_edittype === "dynamic-choice") {
        } else if (_edittype === "dynamic-group-key") {
        } else if (_edittype === "dynamic-group") {
        }
      }
    });
  }

  for (var k in _rls) {
    rules.push({
      rownum: "N",
      c_doc_rowc: "^" + k,
      sign: "\u003d",
      expression: k.indexOf("_KOEF") !== -1 ? _rls[k] : ("Math.SUM(" + _rls[k] + ")" + (typeof (_rlsKoef[k]) !== 'undefined' ? ('*^' + _rlsKoef[k] + '/100') : '')),
      description: "??",
      dodatok: false,
      checkonly: false,
      fillonly: true,
      __is_generate: true
    });
  }

  pz.dynamicForm = dynamicForm;
  pz.dynamicVars = dynamicVars;
  pz.inputs = inputs;
  pz.rules = rules;

  return pz;
};

/* Format functions **/
$.extend($.fn.fmatter, {
  DMOrderLetter: function (cellvalue, options, rowObject) {
    cellvalue = !isNaN(parseInt(cellvalue)) ? ("&#" + (1039 + parseInt(cellvalue, 10)) + ";") : cellvalue;
    return cellvalue;
  },
  /*
    chkDMGroup: function (cellvalue, options, rowObject) {
        cellvalue = (cellvalue + "").toLowerCase();
        if (cellvalue === "") { cellvalue = "0"; }

        return (cellvalue.search(/(false|0|no|off|n)/i) < 0 ? "X" : "&nbsp;");
    },
    rdDMGroup: function (cellvalue, options, rowObject) {
        cellvalue = (cellvalue + "").toLowerCase();
        if (cellvalue === "") { cellvalue = "0"; }

        return (cellvalue.search(/(false|0|no|off|n)/i) < 0 ? "X" : "&nbsp;");
    },*/
  //
  chkDMGroup: function (cellvalue, options, rowObject) {
    cellvalue = (cellvalue + "").toLowerCase();
    if (cellvalue === "") {
      cellvalue = "0";
    }
    return "<div class=\"custom-formatter custom-dm-checkbox\">" +
      "<input type=\"checkbox\"" + (options.colModel.editable === false ? " disabled='disabled'" : "") + " class='magic-checkbox' id=\"" + options.rowId + "\"  name=\"" + options.rowId + "\" " + (cellvalue.search(/(false|0|no|off|n)/i) < 0 ? "checked" : "") + ">" +
      "<label class=\"custom-formatter-element\" for=\"" + options.rowId + "\"></label>" +
      "</div>";
  },
  chkDMGroupWithLabel: function (cellvalue, options, rowObject) {
    cellvalue = (cellvalue + "").toLowerCase();
    if (cellvalue === "") {
      cellvalue = "0";
    }
    return "<div class=\"custom-formatter custom-dm-checkbox\">" +
      "<input type=\"checkbox\"" + (options.colModel.editable === false ? " disabled='disabled'" : "") + " class='magic-checkbox' id=\"" + options.rowId + "\"  name=\"" + options.rowId + "\" " + (cellvalue.search(/(false|0|no|off|n)/i) < 0 ? "checked" : "") + ">" +
      "<label class=\"custom-formatter-element\" for=\"" + options.rowId + "\">" + options.colModel.label + "&nbsp;&nbsp;</label>" +
      "</div>";
  },
  //
  rdDMGroup: function (cellvalue, options, rowObject) {
    cellvalue = (cellvalue + "").toLowerCase();
    if (cellvalue === "") {
      cellvalue = "0";
    }
    return "<div class=\"custom-formatter custom-dm-radiobtn\">" +
      "<input type=\"checkbox\"" + (options.colModel.editable === false ? " disabled='disabled'" : "") + " class='magic-radio' id=\"" + options.rowId + "\"  name=\"" + options.rowId + "\" " + (cellvalue.search(/(false|0|no|off|n)/i) < 0 ? "checked" : "") + ">" +
      "<label class=\"custom-formatter-element\" for=\"" + options.rowId + "\"></label>" +
      "</div>";
  },
  rdDMGroupWithLabel: function (cellvalue, options, rowObject) {
    cellvalue = (cellvalue + "").toLowerCase();
    if (cellvalue === "") {
      cellvalue = "0";
    }
    return "<div class=\"custom-formatter custom-dm-radiobtn\">" +
      "<input type=\"checkbox\"" + (options.colModel.editable === false ? " disabled='disabled'" : "") + " class='magic-radio' id=\"" + options.rowId + "\"  name=\"" + options.rowId + "\" " + (cellvalue.search(/(false|0|no|off|n)/i) < 0 ? "checked" : "") + ">" +
      "<label class=\"custom-formatter-element\" for=\"" + options.rowId + "\">" + options.colModel.label + "&nbsp;&nbsp;</label>" +
      "</div>";
  },
  file: function (cellvalue, options, rowObject, a) {
    cellvalue = (cellvalue + "").toLowerCase();
    const tableNumber = options.colModel.tabn;
    const fieldName = options.colModel.index;
    let rowId = options.rowId;
    let rowNumber = 0;
    if (tableNumber > 0) {
      rowId = rowId.replace('_det_', '').replace('_' + fieldName, '');
      rowNumber = pz.$inst[0].getRowNum(tableNumber, rowId);
    }
    if (cellvalue === "") {
      return "<div class=\"custom-formatter\" draggable='true' ondragenter='$.jdm.draggableHighlight(event, this)' ondragover='$.jdm.draggableHighlight(event, this)' " +
        "ondragleave='$.jdm.draggableUnhighlight(this)' ondrop='$.jdm.draggableDrop(event, this)'>" +
        (options.colModel.editable === true ?
          "<a class='magic-file' href='javascript:void(0)' onclick='$.jdm.showUploadForm(\"" + fieldName + "\"," + rowNumber + ")'>Upload</a>"
          : "") +
        "</div>";
    } else {
      return "<div class=\"custom-formatter\">" +
        "<div><a href='javascript:void(0)' onclick='$.jdm.downloadFileData(\"" + rowObject[fieldName + 'Id'] + "\")'>" + cellvalue + "</a>" +
        (options.colModel.editable === true ? "&nbsp;&nbsp;" +
          "<a class='magic-file-clear' href='javascript:void(0)' onclick='$.jdm.clearFileData(\"" + fieldName + "\"," + rowNumber + ")'>" +
          "<i class=\"fas fa-times-circle\" aria-hidden=\"true\"></i>\n</a></div>" : "") +
        "</div>";
    }
  },
  orderlist: function (cellvalue, options, rowObject) {
    let _items = options.colModel.editoptions.value.split(';');
    let _vals0 = cellvalue.split(';');
    let _valHeaderClick = " onclick='$.jdm.showOrderListForm(\"" + options.rowId + "\")' ";
    let _prompt = $.jdm.isMobileDevice() ? 'Please change and arrange items' : 'Please change and arrange items using the following option';
    let _valHeader = '<div class="orderlist-header"><div class="orderlist-header-left"><span>' + _prompt + '&nbsp</span></div>' +
      '<div class="orderlist-header-right"><span  title="Choose and arrange" class="fa fa-bars" aria-hidden="true" ' +
      _valHeaderClick + "></span></div></div>";
    let _valLabels = '';
    let cnt = 0;
    for (let i = 0; i < _items.length; i++) {
      let _item = _items[i], _name = _items[i], _value = _items[i];
      if (_item.indexOf(':') !== -1) {
        let _vals = _item.split(':');
        _value = _vals[0];
        _name = _vals[1];
      }
      let found = false;
      for (let j = 0; j < _vals0.length; j++) {
        if (_vals0[j] === _value) {
          found = true;
          if (cnt > 0) _valLabels += '<br/>';
          _valLabels += '&nbsp;<span class="custom-order-list-item">' +
            '<i class="custom-order-list-item-icon-pre pi pi-info-circle"></i>' +
            '<span class="custom-order-list-item-value">' +
            _name + '</span><i class="custom-order-list-item-icon-post pi pi-times" ' +
            " onclick='$.jdm.removeItemOrderListForm(\"" + options.rowId + '","' + _value + "\")' >" +
            '</i></span>';
          cnt++;
          break;
        }
      }
    }
    _valLabels = "<div class='orderlist-boxed' >" + (_valLabels !== "" ? _valLabels : "<br/>") + "</div>";
    return "<div class='custom-formatter' >" + _valHeader + _valLabels + "</div>";
  },
  actionButton: function (cellvalue, options, rowObject) {
    const action = options.colModel.useropts.action;
    if (!action) {
      return '';
    }
    const label = options.colModel.label;
    const style = options.colModel.useropts.style || {};
    const icon = style.icon || (action === 'submit' ? 'fa-share-square' : 'fa-save')
    const size = style.size === '2' ? 2 : 1;
    const styleText = 'style="' + (style.backgroundColor ? 'background-color:' + style.backgroundColor + ';' : '') +
      (style.color ? 'color:' + style.color : '') + '"';
    return '<button class="p-button p-component p-button-rounded p-button-raised' +
      ' btn-inline-' + action +
      (size === 2 ? ' p-button-lg' : '') + '" ' + styleText + ' type="button"' + (options.colModel.editable === false ? ' disabled' : '') + '>' +
      '<span aria-hidden="true" class="p-button-icon-left fa ' + icon + '"></span>' +
      '<span class="p-button-label">' + label + '</span>' +
      '</button>';
  },
  range: function (cellvalue, options, rowObject) {
    let value = (cellvalue + "").toLowerCase();
    const {min, max, step} = options.colModel.useropts.range;
    let valueLeftPos;
    if (value === "") {
      value = "0";
    } else {
      const newValue = (+value - min) * 100 / (max - min);
      valueLeftPos = `calc(${newValue}% - (${newValue * 0.15 + 8}px))`;
    }
    return "<div class=\"custom-formatter custom-range-wrapper-global\">"
      + "<output class='custom-range-output'>" + min + "</output>"
      + "<div class='custom-range-wrapper'>"
      + "<div class='custom-range-value'>" + (cellvalue !== '' ? `<span style="left:${valueLeftPos}">${value}</span>` : '') + "</div>"
      + "<input type=\"range\"" + (options.colModel.editable === false ? " disabled='disabled'" : "") + " class='custom-range' id=\""
      + options.rowId + "\"  name=\"" + options.rowId + "\" oninput='setRangeValue(this)'"
      + " value='" + value + "' min='" + min + "' max='" + max + "' step='" + step + "'>"
      + "</div>"
      + "<output class='custom-range-output'>" + max + "</output>"
      + "</div>";
  },
  richtextarea: function (cellvalue, options, rowObject) {
    const editable = options.colModel.editable === true;
    cellvalue = cellvalue + "";
    const headerClick = " onclick='$.jdm.showRichTextEditor(\"" + options.rowId + "\")' ";
    const prompt = 'Text editor';
    const header = '<div class="richtextearea-header"><div class="richtextearea-header-left"><span>' + prompt + '</span></div>' +
      '<div class="richtextearea-header-right"><span  title="Edit" class="fa fa-pen" aria-hidden="true" ' +
      headerClick + "></span></div></div>";
    const value = "<div class='richtextearea-boxed ql-editor'>" + (cellvalue !== "" ? cellvalue : "") + "</div>";
    return "<div class='custom-formatter h-100' >" + (editable ? header : "") + value + "</div>";
  }
});
/* Unformat functions **/
$.extend($.fn.fmatter.chkDMGroup, {
  unformat: function (cellvalue, options, rowObject) {
    var $e = $('input', $(rowObject));
    return $e.is(":checked") ? '1' : '0';
  }
});
$.extend($.fn.fmatter.chkDMGroupWithLabel, {
  unformat: function (cellvalue, options, rowObject) {
    var $e = $('input', $(rowObject));
    return $e.is(":checked") ? '1' : '0';
  }
});
$.extend($.fn.fmatter.rdDMGroup, {
  unformat: function (cellvalue, options, rowObject) {
    var $e = $('input', $(rowObject));
    return $e.is(":checked") ? '1' : '0';
  }
});
$.extend($.fn.fmatter.rdDMGroupWithLabel, {
  unformat: function (cellvalue, options, rowObject) {
    var $e = $('input', $(rowObject));
    return $e.is(":checked") ? '1' : '0';
  }
});

// $.extend($.fn.fmatter.chkDMGroup, {
//     unformat: function (cellvalue, options, rowObject) {
//         return cellvalue === 'X' ? 1 : 0;
//     }
// });
// $.extend($.fn.fmatter.rdDMGroup, {
//     unformat: function (cellvalue, options, rowObject) {
//         return cellvalue === 'X' ? 1 : 0;
//     }
// });

$.extend($.fn.fmatter.DMOrderLetter, {
  unformat: function (cellvalue, options, rowObject) {
    var code = ("" + cellvalue).charCodeAt(0);
    return isNaN(code) ? "" : code - 1039;
  }
});


(function ($) {
  "use strict";
  $.jdm.extend({
    //
    getGroupBy: function (tabn, col, value, mathopr) {
      var ret = [];
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        ret = $.jdm.groupBy($t.p.data[tabn], col, value, mathopr);
      });
      return ret;
    },
    // Returns a grid parameter
    getGridParam: function (pName) {
      var $t = this[0];
      if (!$t || !$t.grid) {
        return;
      }
      if (!pName) {
        return $t.p;
      }
      return $t.p[pName] !== undefined ? $t.p[pName] : null;
    },
    // Sets a parameter with new value
    setGridParam: function (newParams) {
      return this.each(function () {
        if (this.grid && typeof newParams === 'object') {
          $.extend(true, this.p, newParams);
        }
      });
    },
    // Fills a document data as JSON
    getDocumentData: function () {
      var ret = [];

      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return true;
        }
        var tabCnt = $t.p.cmTabCnt;
        for (var ntab = 0; ntab <= tabCnt; ntab++) {
          var tabData = $t.p.data[ntab], v;
          if (ntab === 0) {
            for (var i in tabData) {
              v = tabData[i];
              if ((("") + v).length !== 0) {
                ret.push({field: i, tabn: 0, rn: 0, value: v});
              }
            }
          } else {
            for (var nrow in tabData) {
              var rdata = tabData[nrow];
              for (var i in rdata) {
                v = rdata[i];
                if ((("") + v).length !== 0) {
                  ret.push({field: i, tabn: ntab, rn: nrow, value: v});
                }
              }
            }
          }
        }
      });

      return ret;
    },
    // get readonly status: false - readonly, true - editable
    getDocStatus: function () {
      var ret = false;
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        ret = $t.p.cellEdit;
      });
      return ret;
    },
    // set readonly flag according to the variable "v" .
    // 1 - the document will be readonly
    // another value - the document will be editable
    setDocStatus: function (v) {
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        if (v === true) {
          $t.p.cellEdit = false;
          $('input[type="checkbox"]', $('div.dm-field', $($t))).attr("disabled", "disabled");
          $('div.dm-field', $($t))
            .addClass('dm-field-readonly')
            .closest('td').addClass('not-editable-cell');
          $('input.dm-field', $($t)).attr("disabled", "disabled");
          $('textarea.dm-field', $($t)).attr("disabled", "disabled");
          $('select.dm-field', $($t)).attr("disabled", "disabled");
          $('select.dm-field.selectpicker', $($t)).selectpicker("refresh");
          $('.dm-row-action', $($t)).hide();
          $('button.ui-button', $($t)).attr("disabled", "disabled");
          var files = $('a.magic-file', $('div.dm-field', $($t)));
          $(files).each(function () {
            var $a = $(this);
            $a.attr({'href-tmp': $a.attr('href')}).removeAttr('href');
            $a.attr({'onclick-tmp': $a.attr('onclick')}).removeAttr('onclick');
          });
          $('a.magic-file-clear', $('div.dm-field', $($t))).css('visibility', 'hidden');
        } else {
          $t.p.cellEdit = true;
          $('input[type="checkbox"]', $('div.dm-field', $($t))).removeAttr("disabled");
          $("div.dm-field", $($t))
            .removeClass('dm-field-readonly')
            .closest('td').removeClass('not-editable-cell');
          $('input.dm-field', $($t)).removeAttr("disabled");
          $('textarea.dm-field', $($t)).removeAttr("disabled");
          $('select.dm-field', $($t)).removeAttr("disabled");
          $('select.dm-field.selectpicker', $($t)).selectpicker("refresh");
          $('.dm-row-action', $($t)).show();
          $('button.ui-button', $($t)).removeAttr("disabled");
          var files = $('a.magic-file', $('div.dm-field', $($t)));
          $(files).each(function () {
            var $a = $(this);
            $a.attr({'href': $a.attr('href-tmp')}).removeAttr('href-tmp');
            $a.attr({'onclick': $a.attr('onclick-tmp')}).removeAttr('onclick-tmp');
          });
          $('a.magic-file-clear', $('div.dm-field', $($t))).css('visibility', 'visible');
        }
      });
    },
    // Is Transaction committed?
    // Result: true | false
    isCommitted: function () {
      var ret = true;
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        ret = !$t.p._dataChanged;
      });
      return ret;
    },
    // commits opened transaction
    commit: function () {
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        $t.p._dataChanged = false;

        $($t).jqDm("resetCellsErr");
      });
    },
    // Resets a document cells errors
    resetCellsErr: function () {
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        $.jdm.removeAllPrompts($t);
        $('.dm-value-error', $t)
          .removeClass('dm-value-error').removeAttr('error');
        $($t.p.colModel).each(function (i) {
          if ($t.p.colModel[i].errors && $t.p.colModel[i].errors.length && $t.p.colModel[i].errors.length > 0) $t.p.colModel[i].errors = [];
        });
      });
    },
    /* nrow - row number / row id
		 * col - column id / column name
		 * status - error status: error | clear | default
		 * message - error message *
         *  ig added error type to params */
    setCellStatus: function (nrow, col, tp, message, errorType, grpNumHelper) {
      var pos = -1, status = (tp === undefined) ? "default" : tp !== 0 ? "error" : "default";

      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        pos = $t.getColIndex(col);
        if (pos !== -1) {
          var fld = $t.p.colModel[pos] || {},
            rowid = $t.getRowId(fld.tabn ? fld.tabn : 0, nrow),
            cellid = $.jdm.getTbCellIdUI(rowid, col, fld.tabn ? fld.tabn : 0),
            $inp = $('#' + cellid, $($t));

          //ig added
          if ($inp?.length === 0) {
            $inp = $("#" + cellid, $('#dynTabRowEdit'));
          }
          var cellIdDet, $inpDet, $inpTab, tabNum = 0, tabNumDT = 0;
          tabNum = (grpNumHelper !== undefined && !isNaN(grpNumHelper) && grpNumHelper >= 0) ? grpNumHelper : fld.useropts?.grpType;
          if (fld.tabn !== 0) {
            cellIdDet = "_det_" + cellid;
            $inpDet = $("#" + cellIdDet, $('#dynTabRowEdit'));
            $inpTab = $('[name="' + col + '"]', '.table-template[p="template:TAB' + fld.tabn + '"] tr[id="' + rowid + '"]')

            var opt_DT = fld?.useropts?.dynTabForm
            tabNumDT = (opt_DT?.tab && !isNaN(opt_DT?.tab)) ? opt_DT?.tab : 0;
          }


          var tcellDet = null;
          //ig added  for #432 ticket to hide error higligting just opened tab

          var onlyHLrequired = false;
          if ($t.p.tabsViewed.length > 0 && tabNum > 0
            && (typeof (fld.tabn) === "undefined" || fld.tabn === 0)
            && $t.p?.iddoc === '-1'
          ) {
            onlyHLrequired = true;
            for (var iT in $t.p.tabsViewed) {
              if ($t.p.tabsViewed[iT] === tabNum) {
                onlyHLrequired = false;
              }
            }
          }
          if ($t.p.tabsViewedDt.length > 0 && tabNumDT > 0 && (typeof (fld.tabn) !== "undefined" && fld.tabn >= 0)
            && $t.p?.iddoc === '-1'
          ) {
            onlyHLrequired = true;
            if (!isNaN(nrow) && nrow >= 0 && $t.p.data[fld.tabn].length === nrow + 1) { // not show popup only for current and next dyntab and for last row
              for (var iT in $t.p.tabsViewedDt) {
                if ($t.p.tabsViewedDt[iT] === tabNumDT) {
                  onlyHLrequired = false;
                }
              }
            }
          }
          if (($inp === undefined || $inp.length === 0) && ($inpDet === undefined || $inpDet.length === 0) && ($inpTab === undefined || $inpTab.length === 0)) {
            pos = -2;
          } else {
            if (status === 'error') {
              if (typeof (message) !== "undefined") {
                if (!fld.errors) {
                  $t.p.colModel[pos].errors = [];
                  $t.p.colModel[pos].errors[nrow] = [];
                } else {
                  if (typeof (fld.errors[nrow]) === 'undefined') {
                    $t.p.colModel[pos].errors[nrow] = [];
                  }
                }
                //ig added errorType and check for doubles
                if ($t.p.colModel[pos].errors[nrow].length > 0 && $t.p.colModel[pos].errors[nrow].some(v => v?.status === tp && v?.errorType === errorType && v?.message === message)) {
                  console.log('setCellStatus found double error message for #', fld.name, errorType, nrow, tp, message)
                } else {
                  $t.p.colModel[pos].errors[nrow].push({status: tp, message: message, errorType: errorType});
                }
              }
              if (typeof ($t.p.colModel[pos].errors) !== 'undefined') {
                if (typeof ($t.p.colModel[pos].errors[nrow]) !== 'undefined') {
                  var _errors = $t.p.colModel[pos].errors[nrow], _status = "";
                  if (($inp && $inp.length > 0)) {
                    $.jdm.removePrompt($inp);
                    $.jdm.buildPrompt($inp, _errors, onlyHLrequired);
                  }
                  if (($inpDet && $inpDet.length > 0)) {
                    $.jdm.removePrompt($inpDet);
                    $.jdm.buildPrompt($inpDet, _errors, onlyHLrequired);
                  }
                  if (($inpTab && $inpTab.length > 0)) {
                    $.jdm.removePrompt($inpTab);
                    $.jdm.buildPrompt($inpTab, _errors, onlyHLrequired);
                  }
                  for (var i in _errors) {
                    var _error = _errors[i];
                    if (_error.status === 1 /* common */) {
                      if (_status === 'math') _status = "common";
                    } else if (_error.status === 2 /* math */) {
                      if (_status === '') _status = "math";
                    } else if (_error.status === 3 /* xsd */) {
                      if (_status !== 'xsd') _status = "xsd";
                    }
                  }
                  if (!onlyHLrequired && $inp && $inp.length > 0) {
                    $inp.addClass('dm-value-error').attr('error', _status);
                  }
                  if (!onlyHLrequired && $inpDet && $inpDet.length > 0) {
                    $inpDet.addClass('dm-value-error').attr('error', _status);
                  }
                  if ($inpTab && $inpTab.length > 0) {
                    $inpTab.addClass('dm-value-error').attr('error', _status);
                  }

                }
              }
            } else {
              if (($inp && $inp.length > 0)) {
                $inp.removeClass('dm-value-error').removeAttr('error');
              }
              if (($inpDet && $inpDet.length > 0)) {
                $inpDet.removeClass('dm-value-error').removeAttr('error');
              }
              if (($inpTab && $inpTab.length > 0)) {
                $inpTab.removeClass('dm-value-error').removeAttr('error');
              }


              if ($t.p.colModel[pos].errors) $t.p.colModel[pos].errors = [];
              if (($inp && $inp.length > 0)) {
                $.jdm.removePrompt($inp);
              }
              if (($inpDet && $inpDet.length > 0)) {
                $.jdm.removePrompt($inpDet);
              }
              if (($inpTab && $inpTab.length > 0)) {
                $.jdm.removePrompt($inpTab);
              }
            }
            if (fld.hidden && !fld.__is_editors) pos = -3;
          }
        }
      });
      return pos;
    },
    resetTableEditStatus: function (iTab, iRow) {
      if (typeof (iTab) !== 'undefined' && typeof (iRow) !== 'undefined') {
        var $row1 = $('.table-template[p="template:TAB' + iTab + '"] tr[id="' + iRow + '"]')
        if (($row1 && $row1.length > 0)) {
          $row1.css('color', 'unset')
          var $els = $('.dm-value-error', $row1)
          if (($els && $els.length > 0)) {
            $els.removeClass('dm-value-error').removeAttr('error')
          }
          $('div.jdm-row-edit i', $row1).css('color', 'unset');
        }

      } else {
        $('.table-template div.jdm-row-edit i').css('color', 'unset');
      }
    },
    setTableEditStatus: function (tabn, rn, status) {
      $('.table-template[p="template:TAB' + tabn + '"] tr[rn="' + rn + '"] div.jdm-row-edit i')
        .css('color', status === 'error' ? '#E35855' : 'unset');
    },
    // clears a document data
    clear: function () {
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        for (i = 0; i < $t.p.colModel.length; i++) {
          var field = $t.p.colModel[i],
            name = field.name || field.index,
            tabn = field.tabn || 0;
          if (tabn === 0) {
            $($t).jqDm("setCell", null, 0, name, "", true);
          }
        }
        for (var i = 1; i <= $t.p.cmTabCnt; i++) {
          $t.emptyRows(i);
          $($t).jqDm("addRowData", i, {}, "first", 0);
        }
      });
    },
    // Returns the number of tables in a document
    /* iTab - table number ( 0 - Main, >= 1 - any dynamic table **/
    getTabLen: function (iTab) {
      if (iTab === undefined) {
        iTab = 0;
      }
      if (iTab === 0) {
        return -1;
      }
      var ret = 0;

      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        ret = $t.p.data[iTab].length;
      });

      return ret;
    },
    // Removes all data from the specified table
    /* iTab - Table number ( 0 - Main, > 0 - Any dymanic table)
		 * nrow - Row number | Row Id **/
    emptyTab: function (iTab, enAddRow) {
      if (iTab === undefined) {
        iTab = 0;
      }
      enAddRow = enAddRow || false;
      if (iTab === 0) {
        return;
      }

      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        $t.emptyRows(iTab);
        if (enAddRow) {
          var rdata = $t.dataReader(iTab);
          $($t).jqDm("addRowData", iTab, rdata, "first");
        }
      });

      return;
    },
    // Returns a row of the specified table
    /* iTab - Table number (0 - Main, > 0 - Dynamic table)
		 * nrow - Row number | Row ID **/
    getRowData: function (iTab, nrow) {
      var ret;

      if (iTab === undefined) {
        iTab = 0;
        nrow = 0;
      }
      if (nrow === undefined) {
        nrow = 0;
      }

      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        ret = (iTab === 0) ? $t.p.data[0] : $t.p.data[iTab][$t.getRowNum(iTab, nrow)];
      });

      return ret;
    },
    // Removes a table row
    /* iTab - Table number (0 - Main, > 0 - Dynamic table number)
		 * nrow - Row number | row id **/
    delRowData: function (iTab, nrow) {
      var success = false;

      this.each(function () {
        if (!this.grid || iTab === 0) {
          return;
        }

        var $t = this, rowid = $t.getRowId(iTab, nrow), ind = $("#" + rowid),
          air = $.isFunction($t.p.afterDeleteRow) ? true : false;
        if (ind) {
          ind.remove();
          success = true;

          var pos = $t.p._index[iTab][rowid],
            data = (iTab === 0) ? $t.p.data[0] : $t.p.data[iTab][$t.getRowNum(iTab, nrow)];
          if (pos !== undefined) {
            $t.p.data[iTab].splice(pos, 1);
            $t.refreshIndex(iTab);
          }
          if (air) {
            $t.p.afterDeleteRow.call($t, rowid, iTab, data);
          }
        }
      });

      return success;
    },
    // Sets row with data
    /* iTab - Table number
		 * nrow - Row number | Row ID
		 * data - User data
		 * cssp - Reserved */
    setRowData: function (iTab, nrow, data, cssp) {
      var success = false;
      this.each(function () {
        if (!this.grid || iTab === 0) {
          return;
        }

        var $t = this, rowid = $t.getRowId(iTab, nrow), ind = $("#" + rowid), cp = typeof cssp, lcdata = {};
        if (ind) {
          if (data) {
            try {
              $($t.p.colModel).each(function (i) {
                var nm = this.index, dval = $.jdm.getAccessor(data, nm);
                if (dval !== undefined) {
                  lcdata[nm] = this.formatter && typeof this.formatter === 'string' && this.formatter === 'date' ? $.unformat.date.call(t, dval, this) : dval;
                  var vl = $t.formatter($.jdm.getTbCellIdUI(rowid, nm), dval, i, data, 'edit'),
                    title = this.title ? {"title": $.jdm.stripHtml(vl)} : {};
                  var $els = $("[p=" + nm + "]", ind);
                  $els.attr(title);
                  $t.setFieldValue.call($($t), $els, this, dval, vl);
                }
              });
              var pos = $t.p._index[iTab][rowid];
              if (pos !== undefined) {
                $t.p.data[iTab][pos] = $.extend(true, $t.p.data[iTab][pos], lcdata);
              }
              lcdata = null;
              success = true;
            } catch (e) {
            }
          }
          if (success) {
            if (cp === 'string') {
              $(ind).addClass(cssp);
            } else if (cp === 'object') {
              $(ind).css(cssp);
            }
          }
        }
      });

      return success;
    },
    // Adds new row to a dynamic table
    /* iTab - Table number (0 - Main, > 0 - a dynamic table)
		 * rdata - data or {}
		 * pos - position in a dynamic table (first, last, before, after)
		 * src - according to a specific row (number or id) */
    addRowData: function (iTab, rdata, pos, src) {
      if (!pos) {
        pos = "last";
      }
      pos = pos.toLowerCase();
      if (pos !== 'first' && pos !== 'before' && pos !== 'after' && pos !== 'last') {
        pos = 'last';
      }
      if (!iTab) {
        iTab = 0;
      }
      var success = false;

      if (rdata && iTab !== 0) {
        if ($.isArray(rdata)) {
          pos = "last";
        } else {
          rdata = [rdata];
        }

        this.each(function () {
          if (!this.grid) {
            return;
          }
          var $t = this, lpos = $t.p.data[iTab].length - 1, datalen = rdata.length, k = 0, lcdata = {},
            air = $.isFunction($t.p.afterInsertRow), asrc;
          // Корректировка номера позиции
          if (!src) {
            src = (pos === "last") ? lpos : 0;
          } else {
            switch (pos) {
              case 'first':
                src = 0;
                break;
              case 'last':
                src = lpos;
                break;
            }
          }
          src = (isNaN(src)) ? $t.p._index[iTab][src] : parseInt(src, 10);
          // корректировка индекса в массиве данных
          switch (pos) {
            case 'first':
              asrc = 0;
              break;
            case 'before':
              asrc = src;
              break;
            default:
              asrc = src + 1;
              break;
          }
          while (k < datalen) {
            var rowid = $.jdm.randId(), data = rdata[k];
            // add new row to the dynamic table
            var newRow = $.jdm.addTbRowUI(rowid, iTab, $t.p.data[iTab].length, $("#" + $.jdm.getTbIdTmplUI(iTab), $($t)), $("#" + $t.p._indexR[iTab][src]), pos);
            $(newRow).on("click", function (e) {
              var $e = $(e.currentTarget);
              $("tr.selected", $($t)).removeClass("selected");
              $e.addClass("selected");
            });
            // attach editors to new row & bind events to it
            $(".dm-field", $(newRow)).each(function () {
              var p = $(this).attr("p"), ncol = $t.getColIndex(p), field = $t.p.colModel[ncol] || {};
              $t.attachFieldEditor($(this), field, k);
            });
            // attach row action to every row
            $(".dm-row-action", $(newRow)).each(function () {
              $t.attachFieldAction($(this), rowid, iTab);
            });
            lcdata[$.jdm.getTbIdKey(iTab)] = rowid;

            for (var i = 0; i < $t.p.colModel.length; i++) {
              var cm = $t.p.colModel[i], nm = cm.index, cellid = $.jdm.getTbCellIdUI(rowid, nm), v;
              if (cm.tabn === iTab) {
                lcdata[nm] = (typeof (data[nm]) !== 'undefined') ? data[nm] : '';
                try {
                  v = $t.formatter(cellid, typeof (data[nm]) !== 'undefined' ? $.jdm.getAccessor(data, nm) : '', i, data);
                } catch (_) {
                }
                var $els = $(".dm-field[p=" + nm + "]", $(newRow));
                $els.attr("id", cellid);

                $t.setFieldValue.call($($t), $els, cm, typeof (data[nm]) !== 'undefined' ? $.jdm.getAccessor(data, nm) : '', v);
              }
            }
            if (air) {
              $t.p.afterInsertRow.call($t, "add", rowid, iTab, data, data);
            }
            k++;
            $t.p.data[iTab].splice(asrc, 0, lcdata);
            lcdata = {};

            if (air) {
              $t.p.afterInsertRow.call($t, "commit", rowid, iTab, data, data);
            }
          }
          success = true;
          $t.refreshIndex(iTab);
        });
      }
      return success;
    },
    // Set new value in a document cell
    /* cellId - Cell ID
		 * iRow - Row number or Row ID
		 * iCol - Column Name or Column index
		 * nData - Data
		 * forceupd - ... **/
    setCell: function (cellId, iRow, iCol, nData, forceupd) {
      return this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        var pos = $t.getColIndex(iCol);
        if (pos >= 0) {
          var fld = $t.p.colModel[pos], iTab = fld.tabn, nm = fld.index,
            rqfield = fld.editrules && fld.editrules.required, val = nData;
          if (fld && fld.editrules && fld.editrules.number) { // DataType == NUMBER
            if (isNaN(val) === false) {
              if (val === '') {
                val = rqfield ? 0 : "";
              }
              var _decimalPlaces = (fld.formatoptions && fld.formatoptions.decimalPlaces) ? parseInt(fld.formatoptions.decimalPlaces, 10) : 2;
              if (!$.jdm.isEmpty(val)) val = $.jdm.round(1 * val, _decimalPlaces);
            } else {
              val = rqfield ? 0 : "";
            }
          } else if (fld && fld.editrules && fld.editrules.integer) { // DataType == INTEGER
            if (isNaN(val) === false) {
              if (val === '') {
                val = rqfield ? 0 : "";
              }
              if (!$.jdm.isEmpty(val)) val = parseInt(val, 10);
            } else {
              val = rqfield ? 0 : "";
            }
          }
          nData = val;
          const woCellId = (cellId === null);
          var cellIdDet = null;
          if (woCellId) {
            cellId = $.jdm.getTbCellIdUI($t.getRowId(iTab, iRow), nm, iTab);
            if (fld.tabn !== 0) {
              cellIdDet = "_det_" + $.jdm.getTbCellIdUI($t.getRowId(iTab, iRow), nm, iTab);
            }
          }
          var tcell = $("#" + cellId, $($t));
          if (tcell?.length === 0) {
            tcell = $("#" + cellId, $('#dynTabRowEdit'));
          }
          var tcellDet = null;
          if (cellIdDet != null) {
            tcellDet = $("#" + cellIdDet, $('#dynTabRowEdit'));
          }
          if ((tcell && tcell.length > 0) || (tcellDet && tcellDet.length > 0)) {
            if (nData !== "" || forceupd === true) {

              var v = null;
              if (tcell && tcell.length > 0) {
                v = $t.formatter(cellId, nData, pos, ((iTab === 0) ? $t.p.data[0] : $t.p.data[iTab][iRow]), 'edit');
                $t.setFieldValue.call($($t), $(tcell), fld, nData, v);
              }

              if (tcellDet && tcellDet.length > 0) {
                v = $t.formatter(cellIdDet, nData, pos, ((iTab === 0) ? $t.p.data[0] : $t.p.data[iTab][iRow]), 'edit');
                $t.setFieldValue.call($('#dynTabRowEdit'), $(tcellDet), fld, nData, v);
              }


              nData = fld.formatter && typeof fld.formatter === 'string' && fld.formatter === 'date' ? $.unformat.date.call($t, nData, fld) : nData;
              if (isNaN(iTab) || ($t.p.data.length < 1) || ($t.p.data.length < (iTab + 1))
                || ($t.p.data[iTab] === undefined)) { //ig added
                // console.log("setCell: broken data, not set value[4]. ,nm, nData, len, $t.p", nm, nData, $t.p.data.length, $t.p)
                console.log("setCell: broken data, not set value[4].");
              } else {
                if (iTab === 0) {
                  $t.p.data[iTab][nm] = nData;
                } else {
                  $t.p.data[iTab][iRow][nm] = nData;
                }
              }
              if (fld.tabn !== 0) {
                if ($.isFunction($t.p.afterSaveCell)) {
                  $t.p.afterSaveCell.call($t, cellId, nm, nData, iTab, iRow, iCol);
                }
              }
            } else {
              nData = fld.formatter && typeof fld.formatter === 'string' && fld.formatter === 'date' ? $.unformat.date.call($t, nData, fld) : nData;
              if (isNaN(iTab) || ($t.p.data.length < 1) || ($t.p.data.length < (iTab + 1))
                || ($t.p.data[iTab] === undefined)) { //ig added
                // console.log("setCell: broken data, not set value[5]. ,nm, nData, len, $t.p", nm, nData, $t.p.data.length, $t.p)
                console.log("setCell: broken data, not set value[5].");
              } else {
                if (iTab === 0) {
                  $t.p.data[iTab][nm] = nData;
                } else {
                  $t.p.data[iTab][iRow][nm] = nData;
                }
              }
            }
          } else {
            nData = fld.formatter && typeof fld.formatter === 'string' && fld.formatter === 'date' ? $.unformat.date.call($t, nData, cm) : nData;
            if (isNaN(iTab) || ($t.p.data.length < 1) || ($t.p.data.length < (iTab + 1))
              || ($t.p.data[iTab] === undefined)) { //ig added
              // console.log("setCell: broken data, not set value[6]. ,nm, nData, len, $t.p", nm, nData, $t.p.data.length, $t.p)
              console.log("setCell: broken data, not set value[6].");
            } else {
              if (iTab === 0) {
                $t.p.data[iTab][nm] = nData;
              } else {
                $t.p.data[iTab][iRow][nm] = nData;
              }
            }
          }
        }
      });
    },
    // Sets value
    setCellValue: function (nrow, ncol, val) {
      return this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        $($t).setCell(null, nrow, ncol, val, true);
      });
    },
    // Gets a cell value
    /* nrow - Row number or row id
		 * col - Column name or column index */
    getCell: function (nrow, col) {
      var ret = false;
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        var pos = $t.getColIndex(col);
        if (pos >= 0) {
          var fld = $t.p.colModel[pos],
            cellId = $.jdm.getTbCellIdUI($t.getRowId(fld.tabn, nrow), fld.index, fld.tabn),
            cc = $("#" + cellId, $($t));
          if ((fld.tabn > 0) && isNaN(nrow)) {
            return false;
          }

          cellId = $.jdm.getTbCellIdUI($t.getRowId(fld.tabn, nrow), fld.index, fld.tabn);
          var cellIdDet = null;
          if (fld.tabn !== 0) {
            cellIdDet = "_det_" + $.jdm.getTbCellIdUI($t.getRowId(fld.tabn, nrow), fld.index, fld.tabn);
          }
          var ccDet = null;
          if (cellIdDet != null) {
            ccDet = $("#" + cellIdDet, $('#dynTabRowEdit'));
          }
          if (fld.edittype === "checkbox") {
            if (cc && cc.length > 0) {
              try {
                ret = $.unformat.call($t, cc, {rowId: cellId, colModel: fld}, pos);
              } catch (e) {
                ret = $.jdm.htmlDecode(cc.html());
              }
            } else {
              if ($t.p.data.length < 1) {
                return;
              }
              if ((fld.tabn > 0) && nrow === undefined) {
                console.log("getCell: broken model, not get value[2]. ,fld.tabn, $t.p ", fld.tabn, $t.p)
                return;
              }
              if ((fld.tabn === 0) && $t.p.data[0] === undefined) {
                console.log("getCell: broken data, not get value[1]. ,fld.tabn, $t.p ", fld.tabn, $t.p)
                return;
              }
              ret = (fld.tabn === 0) ? $t.p.data[0][fld.index] : $t.p.data[fld.tabn][nrow][fld.index];
            }
          } else {
            var rval = $t.getFieldValue(cc, fld);
            //ig added
            if ($t.p.data.length < 1) {
              return;
            }
            if ((fld.tabn > 0) && (nrow === undefined || nrow === null)) {
              return;
            }
            if ((fld.tabn === 0) && $t.p.data[0] === undefined) {
              return;
            }
            ret = (fld.tabn === 0) ? $t.p.data[0][fld.index] : $t.p.data[fld.tabn][nrow][fld.index];

            if (cc && cc.length > 0) {
              if (ret !== rval["v"]) {
                ret = rval["v"];
              }
            }
          }

          if (fld && fld.editrules && fld.editrules.number) {
            if (isNaN(ret) === false) {
              ret = parseFloat(ret);
            }
          } else if (fld && fld.editrules && fld.editrules.integer) {
            if (isNaN(ret) === false) {
              ret = parseInt(ret, 10);
            }
          }
        }
      });
      return ret;
    },
    // getCellValue
    getCellValue: function (nrow, col) {
      var ret = false;
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        ret = $($t).getCell(nrow, col);
      });
      return ret;
    },
    // Function getCol
    getCol: function (col, obj, mathopr) {
      var ret = [], val, sum = 0, min, max, v;
      obj = typeof obj !== 'boolean' ? false : obj;
      if (mathopr === undefined) {
        mathopr = false;
      }
      this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        var pos = $t.getColIndex(col);
        if (pos >= 0) {
          var cm = $t.p.colModel[pos], ln = $t.p.data[cm.tabn].length, i = 0;
          if (ln && ln > 0) {
            while (i < ln) {
              var cellId = $.jdm.getTbCellIdUI($t.getRowId(cm.tabn, i), cm.index, cm.tabn),
                cc = $("#" + cellId);
              try {
                val = $.unformat.call($t, cc, {rowId: cellId, colModel: cm}, pos);
              } catch (e) {
                val = $.jdm.htmlDecode(cc.val());
              }
              if (mathopr) {
                v = parseFloat(val);
                v = !isNaN(v) ? v : 0;
                sum += v;
                if (max === undefined) {
                  max = min = v;
                }
                min = Math.min(min, v);
                max = Math.max(max, v);
              } else if (obj) {
                ret.push({id: cellId, value: val});
              } else {
                ret.push(val);
              }

              i++;
            }
            if (mathopr) {
              switch (mathopr.toLowerCase()) {
                case 'sum':
                  ret = sum;
                  break;
                case 'avg':
                  ret = sum / ln;
                  break;
                case 'count':
                  ret = ln;
                  break;
                case 'min':
                  ret = min;
                  break;
                case 'max':
                  ret = max;
                  break;
              }
            }
          }
        }
      });
      return ret;
    },
    // Function getColProp
    getColProp: function (colname) {
      var ret = {}, $t = this[0];
      if (!$t.grid) {
        return false;
      }
      var cM = $t.p.colModel, i;
      for (i = 0; i < cM.length; i++) {
        if (cM[i].name === colname) {
          ret = cM[i];
          break;
        }
      }
      return ret;
    },

    // Function setColStyle
    //ig added iTab, iRow, iCol
    setColStyle: function (colname, style, iTab, iRow, iCol) {
      //do not set width will not work
      return this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }

        if (this.grid) {
          if (style) {
            var cM = this.p.colModel, i;
            for (i = 0; i < cM.length; i++) {
              if (cM[i].name === colname) {
                var $t = this, cm = this.p.colModel[i],
                  cellId = $.jdm.getTbCellIdUI($t.getRowId(cm.tabn, i), cm.index, cm.tabn),
                  cc = $("#" + cellId);
                if (cc.length > 0) {
                  $(cc).css(style);
                }
                break;
              }
            }
          }
        }
      });
    },
    // Function setColProp
    // ig added iTab, iRow, iCol
    setColProp: function (colname, obj, enProcessChildElements, iTab, iRow, iCol) {
      enProcessChildElements = typeof (enProcessChildElements) === 'undefined' ? true : enProcessChildElements;
      //do not set width will not work
      return this.each(function () {
        var $t = this;
        if (!$t.grid) {
          return;
        }
        //added iTab, iRow, iCol
        var _setChildColProp = function (colname, obj, iTab, iRow, iCol) {
          var cm = $t.p.colModel;
          $(cm).each(function () {
            var _fld = this,
              _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
            if (_parent) {
              if (_parent.name === colname) {
                $($t).jqDm("setColProp", _fld.index, obj, false, iTab, iRow, iCol);
              }
            }
          });
        }
        if (this.grid) {
          if (obj) {
            var cM = this.p.colModel, i;
            for (i = 0; i < cM.length; i++) {
              if (cM[i].name === colname) {
                var _cm = this.p.colModel[i],
                  oldEditable = _cm.editable, oldHidden = _cm.hidden,
                  oldRequired = _cm.editrules && _cm.editrules.required ? _cm.editrules.required : false,
                  oldHtmlList = _cm.editoptions && _cm.editoptions.value ? _cm.editoptions.value : "";
                $.extend(true, this.p.colModel[i], obj);
                var $t = this, cm = this.p.colModel[i],
                  newEditable = cm.editable, newHidden = cm.hidden,
                  newRequired = cm.editrules && cm.editrules.required ? cm.editrules.required : false,
                  newHtmlList = cm.editoptions && cm.editoptions.value ? cm.editoptions.value : "";
                if (newEditable !== oldEditable) {
                  //ig added
                  var cellId = null, cc = null;
                  if (iTab > 0 && iRow !== undefined) {
                    cellId = $.jdm.getTbCellIdUI($t.getRowId(iTab, iRow), cm.index, cm.tabn);
                    const dynTabCmp = $('#dynTabRowEdit');
                    if (!!dynTabCmp) {
                      cellId = "_det_" + cellId;
                    }
                  } else {
                    cellId = $.jdm.getTbCellIdUI($t.getRowId(cm.tabn, i), cm.index, cm.tabn);
                  }

                  cc = $("#" + cellId);
                  if (newEditable === true) {
                    cc.removeAttr("ro");
                    if (cc.length > 0) {
                      cc.removeAttr("ro");
                      if (cc.get(0).tagName.toLowerCase() === "div") {
                        $('input[type="checkbox"]', $(cc)).removeAttr("disabled");
                      } else {
                        cc.removeAttr("disabled");
                        if (cc.get(0).tagName.toLowerCase().toLowerCase() === "select" && cc.hasClass("selectpicker")) {
                          cc.selectpicker("refresh");
                        }
                      }
                    }
                  } else {
                    cc.attr("ro", "true");
                    if (cc.length > 0) {
                      if (cc.get(0).tagName.toLowerCase() === "div") {
                        $('input[type="checkbox"]', $(cc)).attr("disabled", "disabled");
                      } else {
                        cc.attr("disabled", "disabled");
                        if (cc.get(0).tagName.toLowerCase().toLowerCase() === "select" && cc.hasClass("selectpicker")) {
                          cc.selectpicker("refresh");
                        }
                      }
                    }
                  }
                }

                if (oldHidden !== newHidden) {
                  var cellId = $.jdm.getTbCellIdUI($t.getRowId(cm.tabn, i), cm.index, cm.tabn),
                    cc = $("#" + cellId),
                    mainElement = cm.useropts && cm.useropts.parent ? cm.useropts.parent : null;
                  if (newHidden) {
                    $('div.field-group[field="' + (mainElement ? mainElement.name : cm.index) + '"]').hide();
                    if (cc.length > 0) {
                      if (cc.get(0).tagName.toLowerCase() === "div") {
                        $('div.custom-formatter', $(cc)).hide();
                      } else {
                        cc.hide();
                      }
                    }
                  } else {
                    $('div.field-group[field="' + (mainElement ? mainElement.name : cm.index) + '"]').show();
                    if (cc.length > 0) {
                      if (cc.get(0).tagName.toLowerCase() === "div") {
                        $('div.custom-formatter', $(cc)).show();
                      } else {
                        cc.show();
                      }
                    }
                  }
                }

                if (oldRequired !== newRequired) {
//                  var cellId = $.jdm.getTbCellIdUI($t.getRowId(cm.tabn, i), cm.index, cm.tabn);
                  if (newRequired) {
                    $("span.jdm-fld-" + cm.index, $t).addClass("required");
                  } else {
                    $("span.jdm-fld-" + cm.index, $t).removeClass("required");
                  }
                }

                if (cm.edittype === "select" && oldHtmlList != newHtmlList) {
                  var cellId = $.jdm.getTbCellIdUI($t.getRowId(cm.tabn, i), cm.index, cm.tabn), cc = $("#" + cellId),
                    v = cc.val();
                  $("option", cc).remove();

                  var delim = ";", sep = ":",
                    so = newHtmlList.split(delim), sv, so, ov;
                  for (i = 0; i < so.length; i++) {
                    sv = so[i].split(sep);
                    if (sv.length > 2) {
                      sv[1] = $.map(sv, function (n, ii) {
                        if (ii > 0) {
                          return n;
                        }
                      }).join(sep);
                    }
                    ov = document.createElement("option");
                    ov.setAttribute("role", "option");
                    ov.value = sv[0];
                    ov.innerHTML = sv[1];
                    cc.append($(ov));
                  }
                  cc.val(v);
                }
                if (!cm.__is_editors && enProcessChildElements) {
                  _setChildColProp(colname, obj);
                }
                break;
              }
            }
          }
        }
      });
    },
    // call process of document check
    check: function (num, iTab, iRow) {
      num = ((typeof num) === 'undefined') || isNaN(num) || (num === null) ? -1 : parseInt(num, 10);
      iTab = ((typeof iTab) === 'undefined') || isNaN(iTab) || (iTab === null) ? -1 : parseInt(iTab, 10);
      var rows = [];
      for (let iThis = 0; iThis < this.length; iThis++) {
        const $t = this[iThis];
        if (!$t.grid) {
          return;
        }
        var cm = $t.p.colModel, data = $t.p.data;
        //  ig added error type to params
        var setLog = function (t, j, fld, fldName, grpType, val, err, errName, dynTab, errorType) {
            errName = (t === 0 ? "" : "[Row #" + j + " TabNo#" + t + "]:") + errName;
            rows.push({
              level: 1,
              field: fld,
              fieldName: fldName,
              tabn: t,
              pos: j,
              grpType: grpType,
              err: err,
              msg: errName,
              val: val,
              dynTab: dynTab,
              errorType: errorType
            });
          },
          DataRow = function ($t, row, t, j) {
            var re = /_id_\d{1,}/i;
            var hiddentabs = []
            if (t > 0) {
              if ($t.p.DTTabulatesHidden[t] !== undefined) {
                if ($t.p.DTTabulatesHidden[t][j] !== undefined) {
                  hiddentabs = $t.p.DTTabulatesHidden[t][j];
                }
              }
            }
            for (var k in row) {
              if (!re.test(k)) {
                DataScalar($t, k, row[k], t, j, row, hiddentabs);
              }
            }
          },
          DataScalar = function ($t, nm, val, t, j, rowdata, hiddentabs) {
            var valref = $t.getColIndex(nm), col = $t.p.colModel[valref], edtrul = col.editrules, dft,
              useropts = col.useropts || {}, grpType = useropts.grpType || 0;
            //skip hidden tabs
            if ((pz.groups || []).filter(v => v.grpType === grpType && (v.tabn || 0) === 0 && v.hidden).length) {
              return;
            }
            if (num !== -1 && grpType !== num) return;

            if (edtrul) {
              if (edtrul.required === true && (col.hidden === false || col.__forceValidate === true)) {
                let skipHiddenTab = false;
                if (t > 0 && hiddentabs !== undefined && hiddentabs.length > 0 && col?.useropts?.dynTabForm?.tab > 0) {
                  if (hiddentabs.length >= col.useropts.dynTabForm.tab) {
                    skipHiddenTab = (hiddentabs[col.useropts.dynTabForm.tab - 1] > 0)
                  }
                }
                if ($.jdm.isEmpty(val)) {
                  if (!skipHiddenTab) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.required, col?.useropts?.dynTabForm?.tab, 'required');
                  } else {
                    // console.log("point5/7 skip for check empty", col)
                  }
                }
              }
              // force required
              var rqfield = edtrul.required !== false;
              if (edtrul.number === true) {
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  if (isNaN(val)) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.number, col?.useropts?.dynTabForm?.tab, 'number');
                  }
                }
              }
              if (edtrul.minValue !== undefined && !isNaN(edtrul.minValue)) {
                if (parseFloat(val) < parseFloat(edtrul.minValue)) {
                  setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.minValue + " " + edtrul.minValue, col?.useropts?.dynTabForm?.tab, 'min');
                }
              }
              if (edtrul.maxValue !== undefined && !isNaN(edtrul.maxValue)) {
                if (parseFloat(val) > parseFloat(edtrul.maxValue)) {
                  setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.maxValue + " " + edtrul.maxValue, col?.useropts?.dynTabForm?.tab, 'max');
                }
              }
              var filter;
              if (edtrul.email === true) {
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  // taken from $ Validate plugin
                  filter = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
                  if (!filter.test(val)) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.email, col?.useropts?.dynTabForm?.tab, 'mask');
                  }
                }
              }
              if (edtrul.integer === true) {
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  if (isNaN(val)) {
                    return [false, nm + ": " + $.jdm.edit.msg.integer, ""];
                  }
                  if ((val % 1 !== 0) || (("" + val).indexOf('.') !== -1)) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.integer, col?.useropts?.dynTabForm?.tab, 'integer');
                  }
                }
              }
              if (edtrul.date === true) {
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  if (cm[valref].formatoptions && cm[valref].formatoptions.newformat) {
                    dft = cm[valref].formatoptions.newformat;
                    if ($.jdm.formatter.date.masks.hasOwnProperty(dft)) {
                      dft = $.jdm.formatter.date.masks[dft];
                    }
                  } else {
                    dft = cm[valref].datefmt || "Y-m-d";
                  }
                  if (!$.jdm.checkDate(dft, val)) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.date + " - " + dft, col?.useropts?.dynTabForm?.tab, 'date');
                  }
                }
              }
              if (edtrul.time === true) {
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  if (!$.jdm.checkTime(val)) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.date + " - hh:mm (am/pm)", col?.useropts?.dynTabForm?.tab, 'time');
                  }
                }
              }
              if (edtrul.maxSelected && useropts?.edittype?.startsWith('checkgroup')) {
                // Controlling max selected options in the checkgroup
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  if (val.split(';').length > edtrul.maxSelected) {
                    setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.maxSelected + " " + edtrul.maxSelected, col?.useropts?.dynTabForm?.tab, 'max');
                  }
                }
              }
              if (edtrul.custom === true) {
                if (!(rqfield === false && $.jdm.isEmpty(val))) {
                  if ($.isFunction(edtrul.custom_func)) {
                    var ret = edtrul.custom_func.call($t, val, nm, valref);
                    return $.isArray(ret) ? ret : [false, $.jdm.edit.msg.customarray, ""];
                  }
                  setLog(t, j, nm, col.label, col?.useropts?.grpType, val, -1, $.jdm.edit.msg.customfcheck, col?.useropts?.dynTabForm?.tab, 'customfcheck');
                }
              }
            }
          };
        // here check for requirements
        for (var t = 0; t < data.length; t++) {
          if (iTab >= 0 && iTab !== t) {
            continue;
          }
          var tab = data[t];
          if (tab) {
            if (t === 0) {
              for (var k in tab) {
                DataScalar($t/*_doc*/, k/*nm*/, tab[k]/*val*/, t/*tabn*/, 0/*nrow*/, [] /*skipHiddenTab*/);
              }
              // here check for requirements loop by rows of dynamic tables
            } else {
              for (var j = 0; j < tab.length; j++) {
                if (((typeof iRow) === 'string' && !!tab[j] && (iRow !== tab[j]['_id_' + iTab + '_']))
                  || ((typeof iRow) === 'number' && !!tab[j] && iRow !== tab[j]['rn_' + iTab + '_'])) {
                  continue
                }
                DataRow($t/*_doc*/, tab[j]/*row*/, t/*tabn*/, j/*nrow*/);
              }
            }
          }
        }
      }
      return {valid: rows.length === 0, rows: rows};
    },
    wizardHighlight: function (errorsSteps, iTab, iRow) {
      const ts = pz.$inst[0];
      iTab = ((typeof iTab) === 'undefined') || isNaN(iTab) || (iTab === null) ? -1 : parseInt(iTab, 10);
      if (iTab > 0) {
        const dynTabCmp = $('#dynTabRowEdit');
        if (!dynTabCmp) {
          return;
        }

        const steps = [...Array($(".dm-wizard .nav-link", $(dynTabCmp)).length).keys()];
        $(".dm-wizard", $(dynTabCmp)).smartWizard("stepState", steps, "error-off");
        if (errorsSteps?.length) {
          $(".dm-wizard", $(dynTabCmp)).smartWizard("stepState", errorsSteps, "error-on");
        }

      } else {
        const steps = [...Array($(".dm-wizard .nav-link", $(ts)).length).keys()];
        $(".dm-wizard", $(ts)).smartWizard("stepState", steps, "error-off");
        if (errorsSteps?.length) {
          $(".dm-wizard", $(ts)).smartWizard("stepState", errorsSteps, "error-on");
        }
      }
    },
    // GridDestroy
    GridDestroy: function () {
      return this.each(function () {
        if (this.grid) {
          try {
            $(this).jqDm('clearBeforeUnload');
          } catch (x) {
            console.log(x);
          }
        }
      });
    },
    // clearBeforeUnload
    clearBeforeUnload: function () {
      return this.each(function () {
        var grid = this.grid;
        grid.emptyRows.call(this, true, true); // this work quick enough and reduce the size of memory leaks if we have someone

        $(document).unbind("mouseup.jqDm" + this.p.id);
        $(this).unbind();

        grid.emptyRows = null;
        grid.populate = null;

        this.refreshIndex = null;
        this.formatter = null;
        this.addJSONData = null;

        this.emptyRows = null;
        this.refreshIndex = null;
        this.getRowId = null;
        this.getRowNum = null;
        this.getColIndex = null;
        this.setFieldValue = null;
        this.getFieldValue = null;
        this.attachFieldEditor = null;
        this.attachFieldAction = null;
        this.reader = null;
      });
    },
    initActionButtons($cont) {
      $(".btn-submit, .btn-inline-submit", $cont).on("click", function () {
        const $inst = pz.$inst, cellEdit = $inst.jqDm("getDocStatus");
        if (cellEdit) {
          $.jdm.submit();
        } else {
          $.jdm.info_dialog("Warning", "This document has already submitted.", "alert");
        }
        return true;
      });
      $(".btn-save, .btn-inline-save", $cont).on("click", function () {
        const $inst = pz.$inst, cellEdit = $inst.jqDm("getDocStatus");
        if (cellEdit) {
          $.jdm.saveWitValidating();
        } else {
          $.jdm.info_dialog("Warning", "This document has already submitted.", "alert");
        }
        return true;
      });
      $(".btn-inline-sign", $cont).on("click", function () {
        const $inst = pz.$inst, cellEdit = $inst.jqDm("getDocStatus");
        if (cellEdit) {
          const el = $(this).parent(), p = $(el).attr('p');
          const ts = $inst[0];
          const iCol = ts.getColIndex(p), cm = ts.p.colModel[iCol];
          let nrow = 0;
          if (cm.tabn !== 0) {
            const id = ('' + $(el).attr("id")).replace('_' + p, '').replace('_det_', '');
            nrow = ts.p._index[cm.tabn][id];
          }
          FillFields(cm.useropts.onclick, cm.useropts.checkErrors, cm.useropts.checkErrorsMessage, nrow);
        } else {
          $.jdm.info_dialog("Warning", "This document has already submitted.", "alert");
        }
        return true;
      });
    }
  });
})(jQuery);

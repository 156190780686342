function EScriptEnvRecursive(msg) {
  this.name = "ExceptionScriptEnvRecursive";
  this.message = msg;
}

EScriptEnvRecursive.prototype = Error;

function ScriptEnv($t, nrow, col) {
  this.$t = $t;
  this.nrow = nrow;
  this.col = col;

  return this;
}

ScriptEnv.constructor = ScriptEnv;
// Print the execution output to the console
ScriptEnv.prototype.debug = function (msg, tab) {
  var ts = (this.$t)[0];
  if (ts.p.debug) {
    console.log("[" + ts.p.cdoc + "] ScriptEnv", (tab !== undefined ? $.jdm.rpad("\t", tab, "\t") : "") + msg);
  }
};
// Set the parameter specified ny "field" and using "expr"
ScriptEnv.prototype.setValue = function (obj, field, expr) {
  var v = null;
  try {
    eval("v='" + expr + "'");
  } catch (_) {
    v = expr;
  }

  if (!v) v = ""; // v == null
  else if (typeof (v) === 'undefined') v = ""; // v === undefined
  else v = (v === "Infinity") ? "" : v;

  (obj.$t).setCellValue(this.nrow, field, v);
};
//
ScriptEnv.prototype.getValue = function (obj, field) {
  field = field.replace(/\^/, "");
  var mtc = /^(\w*)\.(\w*)/.exec(field), ret;
  if (mtc !== null) {
    try {
      throw new Error("Function not developed");
      //ret = obj.getDocValue(mtc[1], mtc[2]);
      //obj.debug("getValue[" + field + "]: " + ret, 3);
      //return ret;

    } catch (ex) {
      console.warn(ex.message);
    }
    return null;
  }
  ret = (obj.$t).getCellValue(obj.nrow, field);
  // obj.debug(" * getValue[" + field + "]: " + ret, 3);
  return ret;
};
//
ScriptEnv.prototype.getTbValue = function (obj, nm, rdata) {
  var ts = (obj.$t)[0], cm = ts.p.colModel, pos = ts.getColIndex(nm), fld = pos !== 1 ? cm[pos] : null,
    ret = rdata[nm];
  if (isNaN(ret) === false) {
    if (fld && fld.editrules && fld.editrules.number) {
      ret = parseFloat(1 * ret, 10);
    } else if (fld && fld.editrules && fld.editrules.integer) {
      ret = parseInt(1 * ret, 10);
    }
  }
  //obj.debug("getTbValue[" + nm + "]: " + ret, 4);
  return ret;
};
//
ScriptEnv.prototype.SUM = function (obj, field) {
  var ret = (obj.$t).getCol(field, false, 'sum');
  //obj.debug("SUM[" + field + "]: " + ret, 3);
  return ret;
};
//
ScriptEnv.prototype.SUM_1 = function (obj, expr, targetId) {
  var ts = (obj.$t)[0], ret = 0, mtc = expr.match(/'(\w+)'/gi);
  if (mtc) {
    var tabn = 0, nm = mtc[0],
      cm = ts.p.colModel, data = ts.p.data;
    nm = nm.replace(/'/g, "");
    for (var i = 0; i < cm.length; i++) {
      if (cm[i].index === nm) {
        tabn = cm[i].tabn;
        break;
      }
    }
    var ln = data[tabn].length;
    for (var nrow = 0; nrow < ln; nrow++) {
      var rdata = data[tabn][nrow];
      ret += eval(expr);
    }
  }
  //obj.debug("SUM_1[" + expr + "]: " + ret, 3);
  return ret;
};
//
ScriptEnv.prototype.SUMF = function (obj, expr) {
  var ts = (obj.$t)[0], ret = 0, mtc = expr.match(/'(\w+)'/gi);
  if (mtc) {
    var tabn = 0, nm = mtc[0],
      cm = ts.p.colModel, data = ts.p.data;
    nm = nm.replace(/'/g, "");
    for (var i = 0; i < cm.length; i++) {
      if (cm[i].index === nm) {
        tabn = cm[i].tabn;
        break;
      }
    }
    var ln = data[tabn].length;
    for (var nrow = 0; nrow < ln; nrow++) {
      var rdata = data[tabn][nrow];
      ret += eval("isNaN(" + expr + ")?0:1*(" + expr + ")");
    }
  }
  //obj.debug("SUMF[" + expr + "]: " + ret, 3);
  return ret;
};
// Using to calculate the records count by a field
ScriptEnv.prototype.GetCount = function (obj, unique, field) {
  var $t = obj.$t;
  var ret = unique ? ($t.getCol(field, true)) : $t.getCol(field, false, 'count');
  if (unique) {
    var getUniqueArr = function (ar) {
      var a = [], l = ar.length;
      for (var i = 0; i < l; i++) {
        for (var j = i + 1; j < l; j++) {
          if (ar[i].value === ar[j].value) {
            j = ++i;
          }
        }
        a.push(ar[i]);
      }
      return a;
    };
    ret = (getUniqueArr(ret)).length;
  }
  //obj.debug("Getcount["+unique+", "+field+"]: "+ret, 3);
  return ret;
},
  // ParseRule
  ScriptEnv.prototype.parseRule = function (expr, lnkObj) {
    expr = expr
      .replace(/SUM\(\\"(.+)\\"[ ]*,[ ]*\\"(.+)\\"[ ]*\)/g, "SUM_1(\"$1\",\"$2\")");
    var mtc = /SUM_1\((.+),"(\w*)"\)/.exec(expr);
    if (mtc) {
      var _expr = mtc[1];
      _expr = _expr.replace(/\^(\w+)/g, lnkObj + ".getTbValue(" + lnkObj + ",'$1',rdata)"),
        expr = expr.replace(/SUM_1\((.+),"(\w*)"\)/g, lnkObj + ".SUM_1(" + lnkObj + ",\"" + _expr + "\",\"$2\")");
    }
    expr = expr.replace(/OSUM\((\w+)[ ]*,[ ]*\^(\w+)[ ]*\)/g, lnkObj + ".OSUM(\"$1\",\"$2\")");
    mtc = /OSUMF\((\w+)[ ]*,[ ]*\$(.*)\$[ ]*\)/g.exec(expr);//, "$.rptutl.OSUMF(\"$1\",\"$2\")");
    if (mtc) {
      var _expr = mtc[2];
      _expr = _expr.replace(/\^(\w+)/g, lnkObj + ".getTbValue('$1',rdata)"),
        expr = expr.replace(/OSUMF\((\w+)[ ]*,[ ]*\$(.*)\$[ ]*\)/g, lnkObj + ".OSUMF(\"" + mtc[1] + "\", \"" + _expr + "\")");
    }
    mtc = /SUMF\(\$(.*)\$\)/g.exec(expr);
    if (mtc) {
      var _expr = mtc[1];
      _expr = _expr.replace(/\^(\w+)/g, lnkObj + ".getTbValue(" + lnkObj + ",'$1',rdata)"),
        expr = expr.replace(/SUMF\(\$(.*)\$\)/g, lnkObj + ".SUMF(" + lnkObj + ", \"" + _expr + "\")");
    }
    expr = expr
      .replace(/SUM\(\^([A-Z]\w*)\)/g, lnkObj + ".SUM(" + lnkObj + ",'$1')")
      .replace(new RegExp(/WorkCounts\(/, 'g'), lnkObj + ".GetCount(" + lnkObj + ",")
      .replace(/\^(\w*\.{0,1}\w*)/g, lnkObj + ".getValue(" + lnkObj + ",'$1')")
      .replace(/ABS/gi, "1*Math.abs");
    return expr;
  };
// exec all items from collection of rules
//ig added iTab, iRow, act
ScriptEnv.prototype.execRules = function (App, rules, iTab, iRow, act) {
  var rls, obj = this, ts = (obj.$t)[0];
  for (var k in rules) {
    rls = rules[k];
    if (!rls.diff) {
      try {
        //ig
        // var pos = ts.getColIndex(rls.field), // get index of the column for exec of rule
        //   fld = pos !== -1 ? ts.p.colModel[pos] : {}, // get field from the document columns model
        //   tabn = pos !== -1 ? fld.tabn : 0, // get the tab number
        //   expr = "var ntab=iTab=" + tabn + ", iRow=" + (tabn === 0 ? 0 : 1) + ";"; // form the expression for exec

        var pos = ts.getColIndex(rls.field), // get index of the column for exec of rule
          fld = pos !== -1 ? ts.p.colModel[pos] : {}, // get field from the document columns model
          tabn = pos !== -1 ? fld.tabn : (iTab > 0 ? iTab : 0)
          // expr = "var ntab=iTab=" + tabn + ", iRow=" + (tabn === 0 ? 0 : (iTab > 0 ? iRow : 1)) + ";"
        ; // form the expression for exec


        expr = "var ntab=iTab=" + tabn + ", iRow=" + (typeof iRow == 'string' ? '"' + iRow + '"' : iRow) + ";"; // form the expression for exec


        // expr += (rls.fld !== "*" ? "var v=" : "") + obj.parseRule(rls.expression, 'obj') + ";";
        var po2 = -1;
        var add2 = '';
        if (rls.field === "*") {
          if (rls.expression.indexOf('ValidateInput(') !== -1) {
            po2 = rls.expression.indexOf(')');
            add2 = ',undefined,' + iTab + ',' + (typeof iRow == 'string' ? '"' + iRow + '"' : iRow);
          } else if (rls.expression.indexOf('DTValidateTab(') !== -1) {
            po2 = rls.expression.indexOf(')');
            add2 = ',' + iTab + ',' + (typeof iRow == 'string' ? '"' + iRow + '"' : iRow);
          }
        }

        expr += (((rls.fld !== "*") && (rls.fld !== "#")) ?
          "var v=" :
          "") + obj.parseRule(
          (po2 === -1 ?
              rls.expression : (rls.expression.substring(0, po2) + add2 + ')')
          ), 'obj') + ";";
        // except rls.field == "*"
        // if (rls.field !== "*") expr += " obj.setValue(obj,'" + rls.field + "', (v==null?'':v));";
        //autocalculate
        if ((rls.field !== "*") && (rls.field !== "#")) expr += " obj.setValue(obj,'" + rls.field + "', (v==null?'':v));";
        if (rls.expression.indexOf('DTValidateTab(') !== -1
          && act !== 'tbrecalc' && act !== 'runtime') {
          // console.log("execRules: scip rule fld= ", fld, act, rls, tabn);
        } else {
          eval(expr);
        }
      } catch (e) {
        console.warn("ERROR: " + expr);
        console.warn(e);

        throw new Error("Rule failed [" + expr + "]: " + (typeof (e.message) === 'undefined' ? e : e.message));
      }
    } else {
      if (rls.tabn) {
        var data = ts.p.data[rls.tabn]||[]; //ig added || []
        for (var nrow = 0; nrow < data.length; nrow++) {
          var env = new ScriptEnv(obj.$t, nrow, rls.field), stack = [];
          stack.push({field: rls.field, expression: rls.expression, lvl: rls.lvl, diff: false});
          //ig env.execRules(App, stack);
          env.execRules(App, stack, rls.tabn, nrow,'rlstbn');
          env = null;
        }
      }
    }
  }
};
// Setting the parameter named "name" according to another document specified by code
ScriptEnv.prototype.changeDocField = function (cdoc, name) {
  var obj = this, ts = (obj.$t)[0];
  if (ts.p.autoFill) {
    try {
      var isrun = false, rules = ts.p.controls || [];
      for (var key in rules) {
        var rule = rules[key];
        if (rule.sign === '=' && rule.fillonly === true && (rule.expression.indexOf("^" + cdoc + "." + name) !== -1 || rule.expression.indexOf(cdoc) !== -1)) {
          isrun = true;
          break;
        }
      }
      if (isrun) {
        obj.autoFillParams("all");
      }
    } catch (e) {
      alert("[changeDocField] ERROR:" + (typeof (e.message) !== 'undefined' ? e.message : e));
      console.warn(e);
    }
  } else {
    console.warn('AUTO-RECALCULATE is blocked by user.');
  }
};
// Auto-recalculating
// ig added iTab, iRow, iCol
ScriptEnv.prototype.autoFillParams = function (App, name, enInDepth, iTab, iRow, act) {
  var evt = App.evt;
  enInDepth = typeof (enInDepth) === 'undefined' ? true : enInDepth;
  if (evt !== "commit") {
    enInDepth = false;
  }

  var obj = this, ts = (obj.$t)[0];
  let charts = [];
  if (ts.p.autoFill) {
    var rules = ts.p.controls || [], stack = [];
    var getRules = function (srcId, lvl, act) {
      if (lvl > 20) {
        throw new EScriptEnvRecursive("Too large recursive stack");
      }
      var pos1 = ts.getColIndex(srcId), fld1 = ts.p.colModel[pos1] || {};
      for (var key in rules) {
        var rule = rules[key];
        if (
          rule.sign === '=' &&
          rule.fillonly === true &&
          (srcId === 'all' ||
            rule.expression.indexOf("^" + srcId) !== -1 ||
            (act==='tbrecalc' &&  rule.c_doc_rowc ===("^" + srcId) && fld1.tabn>0) ||
            rule.expression.indexOf("\"" + srcId + "\"") !== -1
          )
        ) {
          if (
            srcId !== "all" &&
            rule.expression.indexOf("\"" + srcId + "\"") !== -1 &&
            (
              (rule.expression.indexOf("ValidateInput(") !== -1 && rule.expression.indexOf("ValidateInput(\"" + srcId + "\"") === -1) ||
              (rule.expression.indexOf("VisibleInput(") !== -1 && rule.expression.indexOf("VisibleInput(\"" + srcId + "\"") === -1)
            )) {
            continue;
          }
          if (act==='tbrecalc' &&  rule.c_doc_rowc ===("^" + srcId) && fld1.tabn>0) {
            stack.push({field: name, expression: rule.expression, lvl: lvl, diff: false});
          }

          var _nm = $.jdm.trim(rule.c_doc_rowc.replace(/\^/, "")), fnd = false;
          const posTmp = ts.getColIndex(_nm);
          if (posTmp) {
            const fldTmp = ts.p.colModel[posTmp] || {};
            if (fldTmp?.useropts?.chart?.target?.length) {
              fldTmp.useropts.chart.target.forEach(v => charts.push(v));
            }
          }
          for (var k in stack) {
            var rls = stack[k];
            if (rls.lvl < lvl && rls.expression === rule.expression && rls.field === _nm) {
              fnd = true;
              break;
            }
          }
          if (fnd === false) {
            if (_nm !== name) {
              if (_nm === "*") {
                stack.push({field: _nm, expression: rule.expression, lvl: lvl, diff: false});
                if (srcId !== "all" && _nm !== srcId && enInDepth) {
                  getRules(_nm, lvl + 1, act);
                }
              } else {
                var pos2 = ts.getColIndex(_nm), fld2 = ts.p.colModel[pos2] || {};
                if (fld2.tabn !== fld1.tabn && fld2.tabn !== 0) {
                  stack.push({
                    field: _nm,
                    expression: rule.expression,
                    lvl: lvl,
                    diff: true,
                    tabn: fld2.tabn
                  });
                } else {
                  stack.push({field: _nm, expression: rule.expression, lvl: lvl, diff: false});
                  if (srcId !== "all" && _nm !== srcId && enInDepth) {
                    getRules(_nm, lvl + 1, act);
                  }
                }
              }
            }
          }
        }
      }
    };
    try {
      getRules(name, 1, act);
      //ig added iTab, iRow, iCol
      obj.execRules(App, stack, iTab, iRow, act);
      [...new Set([...charts])].forEach(v => PaintChart(v));
    } catch (e) {
      if (e instanceof EScriptEnvRecursive) {
        alert(e.message);
      } else {
        console.error(e);
      }
    } finally {
      rules = null;
      stack = null;
    }
  } else {
    console.warn('AUTO-RECALCULATE is blocked by user.');
  }
};
// Auto-recalculating table
ScriptEnv.prototype.autoFillTbParams = function (App, tabn, data, act) {
  var obj = this, ts = (obj.$t)[0];
  if (ts.p.autoFill) {
    var rules = ts.p.controls || [], stack = [], rdr = ts.reader(ts.p.datatype, tabn);
    var checkRule = function (nm, expression, lvl) {
        var fnd = false;
        for (var k in stack) {
          var rls = stack[k];
          if (rls.lvl < lvl && rls.field === nm && rls.expression === expression) {
            fnd = true;
            break;
          }
        }
        return fnd;
      },
      getRules = function (srcId, lvl) {
        if (lvl > 20) {
          throw new EScriptEnvRecursive("Too large the recursive stack");
        }
        var pos1 = ts.getColIndex(srcId), fld1 = ts.p.colModel[pos1] || {};
        for (var key in rules) {
          var rule = rules[key];
          if (rule.sign === '=' && rule.fillonly === true && (srcId === 'all' || rule.expression.indexOf("^" + srcId) !== -1)) {
            var _nm = $.jdm.trim(rule.c_doc_rowc.replace(/\^/, ""));
            if (checkRule(_nm, rule.expression, lvl) === false) {
              var pos2 = ts.getColIndex(_nm), fld2 = ts.p.colModel[pos2] || {};
              if (fld2.tabn !== fld1.tabn && fld2.tabn !== 0) {
                stack.push({field: _nm, expression: rule.expression, lvl: lvl, diff: true, tabn: fld2.tabn});
              } else {
                stack.push({field: _nm, expression: rule.expression, lvl: lvl, diff: false});
                if (srcId !== "all" && _nm !== srcId) {
                  getRules(_nm, lvl + 1);
                }
              }
            }
          }
        }
      };

    try {
      for (var k in rdr) {
        var srcId = rdr[k];
        for (var key in rules) {
          var rule = rules[key], expression = rule.expression.toLowerCase();
          if (rule.sign === '=' &&
            rule.fillonly === true &&
            (rule.expression.indexOf(srcId) !== -1 &&
              (expression.indexOf('sumf') !== 1 || expression.indexOf('sum(') !== -1 || expression.indexOf('workcounts(') !== -1)
            )
          ) {
            var _nm = $.jdm.trim(rule.c_doc_rowc.replace(/\^/, ""));
            if (checkRule(_nm, rule.expression, 1) === false) {
              stack.push({field: _nm, expression: rule.expression, lvl: 1, diff: false});
              if (srcId !== "all" && _nm !== srcId) {
                getRules(_nm, 2);
              }
            }
          }
        }
      }
      // obj.execRules(App, stack);
      //ig added iTab, iRow, iCol
      obj.execRules(App, stack, tabn, undefined, act);
    } catch (e) {
      if (e instanceof EScriptEnvRecursive) {
        alert(e.message);
      } else {
        console.error(e);
      }
    } finally {
      rules = null;
      stack = null;
    }
  }
};
// Validating a document
ScriptEnv.prototype.validate = function (App, rows) {
  var obj = this, $t = obj.$t, ts = $t[0], controls = ts.p.controls;

  var _isNotEmptyRow = function (rdata) {
      var ret = false, pos, fld, val;
      for (var k in rdata) {
        pos = ts.getColIndex(k);
        fld = ts.p.colModel[pos];
        if (!/_id_\d{1,}/i.test(k) && fld.rownum === false) {
          val = rdata[k];
          val = val !== undefined ? ("" + val) : "";
          ret = $.jdm.isEmpty(val) === false;
          if (ret) break;
        }
      }
      return ret;
    },
    _parseCheckRule = function (nm, rule, fld) {
      var _sign = rule.sign, _expression = rule.expression, _isNumType = false;
      _sign = _sign.replace(/^=$/, "==");
      _expression = obj.parseRule(_expression, "obj");

      var decimalPlaces = 0;
      if (fld.editrules && fld.editrules.number) { // number
        _isNumType = true;
        decimalPlaces = (fld.formatoptions && fld.formatoptions.decimalPlaces) ? parseInt(fld.formatoptions.decimalPlaces, 10) : 2;
      } else if (fld.editrules && fld.editrules.integer) {//integer
        _isNumType = true;
        decimalPlaces = 0;
      }
      _expression = (
          (_sign === '==') ?
            (_isNumType ? ("$.jdm.round(obj.getValue(obj,'" + nm + "'), " + decimalPlaces + ")") : "obj.getValue(obj,'" + nm + "')")
            :
            "obj.getValue(obj,'" + nm + "')"
        ) +
        _sign +
        (
          (_sign === '==') ?
            (_isNumType ? ("$.jdm.round(" + _expression + ", " + decimalPlaces + ")") : "(" + _expression + ")") : "(" + _expression + ")"
        );
      return _expression;
    },
    _exec = function (obj, expr, desc, cnxt) {
      var iRow = cnxt.nrow || 0, iTab = cnxt.tabn || 0, iCol = cnxt.col;

      obj.nrow = iRow;
      obj.col = iCol;

      try {
        if (!eval(expr)) {
          console.warn("Failed: " + expr);
          return {
            res: false,
            msg: (
              (
                (iRow !== 0) ? '[Row #' + (iRow + 1) + '] ' : ((iTab !== 0) ? '[Row #1]' : '')
              )
              + desc
            )
          };
        }
      } catch (e) {
        console.warn("[Check] ERROR : " + expr);
        console.warn(e);

        throw new Error("Rule failed [" + expr + "]: " + (typeof (e.message) === 'undefined' ? e : e.message));
      }
      return {res: true, msg: ''};
    };

  for (var key in controls) {
    var rule = controls[key];
    if (rule.checkonly === true) {
      // if (("" + rule.c_doc_rowc).trim() === "" || ("" + rule.c_doc_rowc).trim() === "*") continue;
      if (("" + rule.c_doc_rowc).trim() === "" || ("" + rule.c_doc_rowc).trim() === "*" || ("" + rule.c_doc_rowc).trim() === "#") continue;
      var _commonCase = rule.c_doc_rowc.startsWith("^"),
        _nm = _commonCase ? $.jdm.trim(rule.c_doc_rowc.substr(1)) : $.jdm.trim(rule.c_doc_rowc),
        pos = ts.getColIndex(_nm), fld, _expression;
      if (pos !== -1) {
        fld = ts.p.colModel[pos];
        _expression = _commonCase ? _parseCheckRule(_nm, rule, fld) : _expression = obj.parseRule(rule.expression, "obj");
        try {
          obj.debug("validate[" + _nm + "]:" + _expression, 1);
          if (fld.tabn === 0) {
            var ret = _exec(obj, _expression, rule.description, {nrow: 0, col: fld.index, tabn: fld.tabn});
            if (!ret.res) {
              rows.push({level: 2, field: _nm, fieldName: fld.index, tabn: 0, pos: 0, msg: ret.msg, val: ""});
            }
          } else {
            var ln = ts.p.data[fld.tabn].length;
            for (var i = 0; i < ln; i++) {
              var rdata = ts.p.data[fld.tabn][i];
              if (_isNotEmptyRow(rdata)) {
                var ret = _exec(obj, _expression, rule.description, {nrow: i, col: fld.index, tabn: fld.tabn});
                if (!ret.res) {
                  rows.push({
                    level: 2,
                    field: _nm,
                    fieldName: _nm,
                    tabn: fld.tabn,
                    pos: i,
                    msg: ret.msg,
                    val: ""
                  });
                }
              }
            }
          }
        } catch (e) {
          console.warn(e);
          alert("[validate, " + ts.p.cdoc + ", " + pos + ", " + _nm + "]: " + (typeof (e.message) !== 'undefined' ? e.message : e));
        }
      } else {
        console.warn("Parameter \"" + _nm + "\" not found", rule);
      }
    }
  }
  return rows;
};

var DocumentDestroy = function(pz) {
  pz.$inst.jqDm("GridDestroy");
  pz.$inst = null;
  $('#DocContent')
    .html("")
    .html("<table class=\"tmplPageDoc content-empty\" form=\"true\">" +
          "<tbody>" +
            "<tr>" +
              "<td></td>" +
            "</tr>" +
          "</tbody>" +
          "</table>")
};

var DocumentClear = function(pz) {
  $('#DocContent')
    .html("")
    .html("<table class=\"tmplPageDoc content-empty\" form=\"true\">" +
      "<tbody>" +
      "<tr>" +
      "<td></td>" +
      "</tr>" +
      "</tbody>" +
      "</table>")
};
